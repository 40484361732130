import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSuperSelect
} from "@elastic/eui";

import { PlanViewComponent } from "src/interfaces";
import { savePlan, updateCurrentPlan } from "src/store/planBuild/actions";
import { getCurrentPlan } from "src/store/planBuild/selector";

import { StyledSpacer } from "src/components/Global/StyledComponents";

export const ShortTermGoals = {
  marriage: "Save for a wedding",
  house: "Save to purchase a house",
  vehicle: "Save to purchase an investment",
  property: "Save to purchase a vehicle",
};

const InvestVSPayDebt: PlanViewComponent = ({ onSave, render }) => {
  const dispatch = useDispatch();
  const plan = useSelector(getCurrentPlan);
  const [priority, setPriority] = useState(plan.profile.priority || "debt");

  const handleChange = (value: string) => {
    setPriority(value);
  };

  const save = () => {
    // create a new plan where the priority is updated to the new value
    const newPlan = {
      ...plan,
      profile: {
        ...plan.profile,
        priority,
      },
    };
    dispatch(updateCurrentPlan(newPlan));
    dispatch(savePlan(null));
    onSave();
  };

  return render({
    component: (
      <>
        <EuiFlexGroup className="ai-flex-content">
          <EuiFlexItem>
            <div className="ai-content-title">
              <EuiFlexGroup style={{ gap: 0 }}>
                <EuiFlexItem grow={false}>
                  <h1>Invest vs Paying Off Debt</h1>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="32px" />
        <EuiFormRow
          label="Do you want to prioritize investing or paying off debt?"
        >
          <EuiSuperSelect
            options={[
              { value: "debt", inputDisplay: "Pay Off Debt" },
              { value: "asset", inputDisplay: "Invest" },
            ]}
            valueOfSelected={priority}
            onChange={handleChange}
            hasDividers
          />
        </EuiFormRow>
      </>
    ),
    nextLabel: "Save",
    onNext: save,
  });
};

export default InvestVSPayDebt;
