import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from "@material-ui/core";

import { Global, css } from "@emotion/react";
import {
  EuiButtonIcon,
  EuiCallOut,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiRadioGroup,
  EuiText,
  EuiSuperSelect,
} from "@elastic/eui";

import { LabelStyle, LabelAsterisk } from "src/components/Global/StyledComponents";

import { generateOptions, YES_NO_OPTIONS } from "src/constants/formOptions";
import CenterContainer from "../../../Components/CenterContainer";
import Button from "src/components/Button";
import Radio from "src/components/Radio";
import { MAX_401K_ANNUAL_CONTRIBUTION } from "src/constants";
import {
  savePlan,
  updateCurrentPlan,
  estimateCurrentPlanTaxes,
} from "src/store/planBuild/actions";
import { EMPTY_RETIREMENT } from "src/store/planBuild/constants";
import {
  currentPlanIncomeTotal,
  getCurrentPlan,
  getMy401kEligibleIncome,
  getSpouse401kEligibleIncome,
} from "src/store/planBuild/selector";
import { Plan } from "src/interfaces/plan.interface";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import { SidebarContent } from "src/interfaces";
import {
  formatAnnually,
  formatMonthly,
  formatPercent,
  formatWholeDollars,
  PercentTextField,
} from "src/utils";
import { useStyles } from "../styles";
import { CURATED_PLAN_BUILD_STEPS } from "../common";
import ToolTipIcon from "src/components/ToolTipIcon";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const catStyles = css`
  .close-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

// TODO: clean up this file and consolidate with ../AddEdit401k

const employerBlowout: SidebarContent[] = [
  {
    header: "Retirement Savings Matching",
    body: [
      "FitBUX highly recommends taking advantage of your employer retirement match, if available.",
      "Employer matching is a contribution amount your employer makes to your retirement.",
      "For example, if you make $70,000 per year and your employer has a 3% match, if you contribute $2,100 per year to your retirement, your employer will also contribute $2,100.",
      "In short, your match is 'free' money.  That is why we recommend contributing at least the minimum amount to your retirement to trigger any company match available to you.",
    ],
  },
  {
    header: "Eligible Accounts",
    body: [
      "Below is a list of accounts and which type of employees qualify for them:",
      "1) 401k: Employees of for profit companies",
      "2) 403b: Employees of non-profit companies",
      "3) 457b: Employees of state and local governments",
      "4) TSP: Employees of the Federal government",
    ],
  },
  {
    header: "Should I do a Roth 401k?",
    body: [
      "We recommend that  you save both in a 401k and Roth 401k, if offered as they each provide different benefits. ",
    ],
  },
];

const AddOrEdit401kSpouse: PlanViewComponent = ({
  // initialValues,
  onClose,
  onSave,
  render,
  type,
  curatedIndex,
}) => {
  const curatedFlow =
    curatedIndex === CURATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT_SPOUSE;
  const dispatch = useDispatch();
  const styles = useStyles();
  const plan: Plan = useSelector(getCurrentPlan);
  const myEligibleIncome = useSelector(getMy401kEligibleIncome);
  const eligibleIncome = useSelector(getSpouse401kEligibleIncome);
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const initialFormValues: any = {
    additional_match: "n",
    additional_amount_match: "",
    additional_match_percent: 50,
    base_amount_matched: "",
    base_match_percent: 100,
    contribution: "",
    who: "spouse",
    employer_offered: curatedFlow ? "n" : "y",
    "401k": "n",
    "401k_percent": "",
    auto_question: "n",
    auto_match_percent: "",
  };
  const retirement = plan.retirement[1];
  const soloAllocation = plan.allocations[0].solo[1];
  initialFormValues.contribution =
    soloAllocation[type as "401k_value" | "roth_401k_value"];
  initialFormValues.base_amount_matched = retirement.match_limit;
  initialFormValues.base_match_percent = retirement.match_percent;
  initialFormValues.additional_match = retirement.additional_limit ? "y" : "n";
  initialFormValues.additional_amount_match = retirement.additional_limit;
  initialFormValues.additional_match_percent = retirement.additional_percent;
  const [formValues, setFormValues] = useState<any>(initialFormValues);

  const totalMaxPercent = (MAX_401K_ANNUAL_CONTRIBUTION / eligibleIncome) * 100;
  const maxPercent = totalMaxPercent - (formValues["401k_percent"] || 0);
  const rothMaxPercent = totalMaxPercent - (formValues.contribution || 0);

  const setFormValue = (e: React.ChangeEvent<any> | string, fieldName?: string) => {
    let field: string;
    let value: any;

    if (typeof e === 'string') {
      field = fieldName || 'has_401k';
      value = e.replace(`${field}_`, "");
    } else {
      field = e.target.name;
      value = e.target.value;
    }

    const newFormValues = { ...formValues, [field]: value };

    // If has_401k is updated, also update employer_offered
    if (field === 'has_401k') {
      newFormValues.employer_offered = value;
    }

    if (field === "who") {
      const itemIndex = value === "spouse" ? 1 : 0;
      const retirement = plan.retirement[itemIndex];
      newFormValues.contribution = undefined;
      newFormValues.base_amount_matched = retirement.match_limit;
      newFormValues.base_match_percent = retirement.match_percent;
      if (retirement.additional_limit) {
        newFormValues.additional_match = "y";
        newFormValues.additional_amount_match = retirement.additional_limit;
        newFormValues.additional_match_percent = retirement.additional_percent;
      }
    }
    setFormValues(newFormValues);
  };

  const spouses = formValues.who === "spouse" ? "spouse's " : "";

  const dollarContribution =
    ((formValues.contribution + formValues["401k_percent"]) / 100) *
    eligibleIncome;

  const totalContribution = (dollarContribution / totalIncome || 0) * 100;

  const save = () => {
    const newRetirement = [...plan.retirement];
    const whoIndex = formValues.who === "applicant" ? 0 : 1;
    const retirementItem = {
      ...EMPTY_RETIREMENT,
      match_limit: formValues.base_amount_matched,
      match_percent: formValues.base_match_percent,
    };
    if (formValues.additional_match === "y") {
      retirementItem.additional_limit =
        +formValues.additional_amount_match || 0;
      retirementItem.additional_percent =
        +formValues.additional_match_percent || 0;
    }
    if (formValues.auto_question === "y") {
      retirementItem.nonelective_limit = +formValues.auto_match_percent || 0;
    }
    newRetirement[whoIndex] = retirementItem;
    const soloAllocations = plan.allocations[0].solo;
    const newSoloAllocations = [...soloAllocations];
    newSoloAllocations[1] = {
      ...soloAllocations[1],
      "401k_value": formValues.contribution || 0,
      roth_401k_value: formValues["401k_percent"] || 0,
    };
    const newAllocations = [...plan.allocations];
    const combinedContribution401k =
      ((newSoloAllocations[0]["401k_value"] || 0) / 100) * myEligibleIncome +
      ((newSoloAllocations[1]["401k_value"] || 0) / 100) * eligibleIncome;
    const combinedContributionRoth =
      ((newSoloAllocations[0]["roth_401k_value"] || 0) / 100) *
      myEligibleIncome +
      ((newSoloAllocations[1]["roth_401k_value"] || 0) / 100) * eligibleIncome;
    newAllocations[0] = {
      ...newAllocations[0],
      solo: newSoloAllocations,
      "401k_value": (combinedContribution401k / totalIncome) * 100,
      roth_401k_value: (combinedContributionRoth / totalIncome) * 100,
    };
    const newPlan = {
      allocations: newAllocations,
      retirement: newRetirement,
    };
    // dont save if employer offer select field is changed to no
    // this only effects the curated flow
    if (formValues.employer_offered === "y") {
      dispatch(updateCurrentPlan(newPlan));
      // Assume Traditional 401k contributions have been changed, update the taxes
      dispatch(estimateCurrentPlanTaxes());
    }
    dispatch(savePlan(null));
    onSave();
  };

  return render({
    component: (
      <>
        <Global styles={catStyles} />
        <EuiFlexGroup className="ai-flex-content">
          <EuiFlexItem>
            <div className="ai-content-title">
              <EuiFlexGroup style={{ gap: 0 }}>
                <EuiFlexItem grow={false}>
                  <h1>Employer Retirement</h1>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    // onClick={() => toggleFlyout("life-goals")}
                    iconType="questionInCircle"
                    aria-label="Help"
                    className="help-icon"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiFlexItem>
          {!curatedIndex && (
            <EuiFlexItem grow={false} style={{ alignItems: 'flex-end' }}>
              <EuiButtonIcon
                color="text"
                iconType="cross"
                aria-label="Close"
                onClick={onClose}
                className="close-icon"
              />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
        {curatedFlow && (
          <>
            <StyledSpacer size="32px" />
            <EuiFormRow
              label={
                <LabelStyle>
                  Does your spouse's employer offer and do you want to
                  contribute to their 401k/403b/457b/TSP?
                </LabelStyle>
              }
            >
              <EuiRadioGroup
                options={generateOptions('employer_offered', YES_NO_OPTIONS)}
                aria-label="employer_offered"
                name="employer_offered"
                onChange={(id) => setFormValue(id, 'employer_offered')}
                idSelected={`employer_offered_${formValues.employer_offered}`}
              />
            </EuiFormRow>
          </>
        )}
        {formValues.employer_offered !== "n" && (
          <>
            {!!formValues.who && (
              <>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <LabelStyle>
                      My {spouses} eligible income for retirement
                    </LabelStyle>
                  }
                >
                  <EuiText>
                    <h3>
                      {formatWholeDollars(eligibleIncome)}
                    </h3>
                  </EuiText>
                </EuiFormRow>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <LabelStyle>
                      What {spouses}percentage of your income do you contribute each month?<LabelAsterisk />
                    </LabelStyle>
                  }
                  helpText={`Max: ${(Math.floor(maxPercent * 100) / 100).toFixed(2)}%`}
                >
                  <PercentTextField
                    variant="outlined"
                    fullWidth
                    name="contribution"
                    value={formValues.contribution}
                    placeholder="0"
                    max={maxPercent}
                    onChange={setFormValue}
                    eui
                  />
                </EuiFormRow>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <LabelStyle>
                      What percentage of your {spouses}contributions does your {spouses}employer match?<LabelAsterisk />
                    </LabelStyle>
                  }
                  helpText="Most employers match 100%. You can edit if needed."
                >
                  <PercentTextField
                    variant="outlined"
                    fullWidth
                    name="base_amount_matched"
                    value={formValues.base_amount_matched}
                    placeholder="0"
                    onChange={setFormValue}
                    eui
                  />
                </EuiFormRow>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <LabelStyle>
                      What is the maximum percentage of your {spouses}salary that your {spouses}employer will match?<LabelAsterisk />
                    </LabelStyle>
                  }
                >
                  <PercentTextField
                    variant="outlined"
                    fullWidth
                    name="base_match_percent"
                    value={formValues.base_match_percent}
                    placeholder="6%"
                    onChange={setFormValue}
                    eui
                  />
                </EuiFormRow>
                <StyledSpacer size="16px" />
                <EuiCallOut
                  title="Get the full employer match"
                  iconType="iInCircle"
                  style={{ maxWidth: "600px" }}
                >
                  <p>
                    Employer contributions to your 401k is free money that can increase your retirement savings. Try to contribute enough to get the full match.
                  </p>
                </EuiCallOut>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <LabelStyle>
                      Does your {spouses}employer offer an additional match?
                    </LabelStyle>
                  }
                >
                  <EuiRadioGroup
                    options={generateOptions('additional_match', YES_NO_OPTIONS)}
                    aria-label="additional_match"
                    name="additional_match"
                    onChange={(id) => setFormValue(id, 'additional_match')}
                    idSelected={`additional_match_${formValues.additional_match}`}
                  />
                </EuiFormRow>
                {formValues.additional_match === "y" && (
                  <>
                    <StyledSpacer size="32px" />
                    <EuiFormRow
                      label={
                        <LabelStyle>
                          What additional percentage of your {spouses}contributions does your employer match<LabelAsterisk />
                        </LabelStyle>
                      }
                      helpText="Most employers match 50%. You can edit if needed."
                    >
                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="additional_amount_match"
                        value={formValues.additional_amount_match}
                        placeholder="0"
                        onChange={setFormValue}
                        eui
                      />
                    </EuiFormRow>
                    <StyledSpacer size="32px" />
                    <EuiFormRow
                      label={
                        <LabelStyle>
                          What is the maximum additional percentage of your salary that your employer will match?<LabelAsterisk />
                        </LabelStyle>
                      }
                    >
                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="additional_match_percent"
                        value={formValues.additional_match_percent}
                        placeholder="0"
                        onChange={setFormValue}
                        eui
                      />
                    </EuiFormRow>
                  </>
                )}
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <LabelStyle>
                      Does your employer offer and do you want to contribute to a Roth 401k/403b/457b/TSP?
                    </LabelStyle>
                  }
                >
                  <EuiRadioGroup
                    options={generateOptions('401k', YES_NO_OPTIONS)}
                    aria-label="401k"
                    name="401k"
                    onChange={(id) => setFormValue(id, '401k')}
                    idSelected={`401k_${formValues['401k']}`}
                  />
                </EuiFormRow>
                {formValues["401k"] === "y" && (
                  <>
                    <StyledSpacer size="32px" />
                    <EuiFormRow
                      label={
                        <LabelStyle>
                          What percentage of {spouses}income does {spouses}contribute each month?<LabelAsterisk />
                        </LabelStyle>
                      }
                      helpText={`Max: ${(Math.floor(rothMaxPercent * 100) / 100).toFixed(2)}%`}
                    >
                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="401k_percent"
                        value={formValues["401k_percent"]}
                        max={rothMaxPercent}
                        onChange={setFormValue}
                        eui
                      />
                    </EuiFormRow>
                    <StyledSpacer size="32px" />
                    <EuiFormRow
                      label={
                        <LabelStyle>
                          Does {spouses}employer offer contributions to his retirement without them having to contribute?
                        </LabelStyle>
                      }
                    >
                      <EuiRadioGroup
                        options={generateOptions('auto_question', YES_NO_OPTIONS)}
                        aria-label="auto_question"
                        name="auto_question"
                        onChange={(id) => setFormValue(id, 'auto_question')}
                        idSelected={`auto_question_${formValues.auto_question}`}
                      />
                    </EuiFormRow>
                    {formValues["auto_question"] === "y" && (
                      <>
                        <StyledSpacer size="32px" />
                        <EuiFormRow
                          label={
                            <LabelStyle>
                              What percentage of {spouses}employer contributions does he want to put in his 401k/403b/457b/TSP?<LabelAsterisk />
                            </LabelStyle>
                          }
                          helpText={`Max: 6%`}
                        >
                          <PercentTextField
                            variant="outlined"
                            fullWidth
                            name="auto_match_percent"
                            value={formValues["auto_match_percent"]}
                            onChange={setFormValue}
                            eui
                          />
                        </EuiFormRow>
                      </>
                    )}
                  </>
                )}
                <StyledSpacer size="32px" />
                <EuiPanel paddingSize="l" className="billing-summary" style={{ maxWidth: '100%' }}>
                  <EuiFlexGroup justifyContent="spaceBetween" alignItems="baseline">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        &nbsp;
                      </EuiText>
                      <EuiText>
                        <strong>Retirement Contribution Summary</strong>
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiText><strong>% of Total Income</strong></EuiText>
                      <EuiText>
                        {formatPercent(totalContribution)}
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiText><strong>Monthly</strong></EuiText>
                      <EuiText>
                        {formatMonthly(Math.round(dollarContribution / 12))}
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiText><strong>Annually</strong></EuiText>
                      <EuiText>
                        {formatAnnually(dollarContribution)}
                      </EuiText>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
                <StyledSpacer size="16px" />
              </>
            )}
          </>
        )
        }
      </>
    ),
    nextLabel: "Save",
    onNext: save,
  });
};

export default AddOrEdit401kSpouse;
