import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiPanel,
    EuiButtonGroup,
    EuiText,
} from "@elastic/eui";

import {
    StyledEuiButton,
    StyledEuiButtonIcon,
} from "src/components/Global/StyledComponents";

import { StyledSpacer } from "src/components/Global/StyledComponents";

import {
    currentPlanAllocationTotals,
    currentPlanIncomeTotal,
    currentPlanExpenseTotal,
    currentPlanRiskManagementTotal,
    getCurrentPlanSurplus,
    getIsMonthly,
    isCurrentPlanImplemented,
    livePlanAllocationTotals,
    livePlanExpenseTotal,
    livePlanIncomeTotal,
    livePlanRiskManagementTotal,
} from "src/store/planBuild/selector";
import { setMonthlyOverview } from "src/store/planBuild/actions";
import { formatWholeDollars } from "src/utils";
import { colors } from "src/theme";
import LeftSidebar from "./GradLeftSidebar";
// import ConfirmCancelModal from "./ConfirmCancelModal";
// import EditOptions from "./EditOptions";
import useWindowSize from "src/hooks/useWindowSize";
import { Global, css } from "@emotion/react";
import CircularProgressBar from "src/components/CircleProgressChart/CircleProgressChartV2";

const chartStyles = css`
    tspan:nth-of-type(1) {
        font-size: 18px;
    }
    .rm-tab-group {
      .euiButtonGroupButton {
        background-color: #FBFCFD;
      }
      .euiButtonGroupButton-isSelected {
        background-color: #69707D;
      }
      span {
        font-size: 14px;
        font-family: "Inter", sans-serif;
      }
    }
    .dd-header {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      font-family: "Poppins", sans-serif;
    }
    .bo-header {
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 22px !important;
        font-family: "Inter", sans-serif;
        margin-bottom: 8px !important;
    }
    .bo-subtext {
        font-size: 16px !important;
        line-height: 24px !important;
        font-family: "Inter", sans-serif;
    }
    .bo-amount {
        font-size: 16px !important;
        line-height: 24px !important;
        font-family: "Inter", sans-serif;
    }
    .grad-sidebar {
        max-width: 315px;
        max-height: 615px;
        margin: 16px 16px 0 0;
    }
`;

const AiPlanBuilderLayoutStyles = css`
  .actt-main {
    z-index: 1 !important;
  }
`;

const GradPlanBuilderLayout = ({
    onPrev,
    onNext,
    children,
}: any) => {
    const windowSize = useWindowSize();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const isMonthly = useSelector(getIsMonthly);
    const dispatch = useDispatch();
    const planIncomeTotal = useSelector(currentPlanIncomeTotal);
    const planExpenseTotal = useSelector(currentPlanExpenseTotal);
    const liveIncomeTotal = useSelector(livePlanIncomeTotal);
    const liveExpenseTotal = useSelector(livePlanExpenseTotal);
    const planRiskTotal = useSelector(currentPlanRiskManagementTotal);
    const liveRiskTotal = useSelector(livePlanRiskManagementTotal);
    const planRemainingMoney = useSelector(getCurrentPlanSurplus);
    const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
    const {
        assetsTotal: planAssetsTotal,
        debtsTotal: planDebtsTotal,
    } = useSelector(currentPlanAllocationTotals);
    const {
        assetsTotal: liveAssetsTotal,
        debtsTotal: liveDebtsTotal,
    } = useSelector(livePlanAllocationTotals);

    const handleReviewSectionEdit = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const {
        incomeTotal,
        expenseTotal,
        riskTotal,
        assetsTotal,
        debtsTotal,
    } = useMemo(() => {
        if (!currentPlanIsImplemented) {
            return {
                incomeTotal: planIncomeTotal,
                expenseTotal: planExpenseTotal,
                riskTotal: planRiskTotal,
                assetsTotal: planAssetsTotal,
                debtsTotal: planDebtsTotal,
            };
        }
        return {
            incomeTotal: liveIncomeTotal,
            expenseTotal: liveExpenseTotal,
            riskTotal: liveRiskTotal,
            assetsTotal: planAssetsTotal,
            debtsTotal: planDebtsTotal,
        };
    }, [
        currentPlanIsImplemented,
        planIncomeTotal,
        planExpenseTotal,
        planAssetsTotal,
        planDebtsTotal,
        planRiskTotal,
        liveIncomeTotal,
        liveExpenseTotal,
        liveRiskTotal,
        liveAssetsTotal,
        liveDebtsTotal,
    ]);

    const remainingMoney = !currentPlanIsImplemented
        ? planRemainingMoney
        : incomeTotal - expenseTotal - riskTotal - assetsTotal - debtsTotal;

    const setIsMonthly = (optionId: string) =>
        dispatch(setMonthlyOverview(optionId === "monthly"));

    const formatValue = (value: number) => {
        const formatted = isMonthly ? Math.floor(value / 12) : Math.floor(value);
        return formatWholeDollars(formatted);
    };

    const chartData = [
        { key: "Household Expenses", value: expenseTotal },
        { key: "Asset Contributions", value: assetsTotal },
        { key: "Debt Payments", value: debtsTotal },
        { key: "Premium Payments", value: riskTotal },
        { key: "Available Funds", value: remainingMoney },
    ];

    const chartColors = [
        colors.vizDefaultGreen,   // Household Expenses
        colors.vizDefaultBlue,    // Asset Contributions
        colors.vizDefaultBlue,    // Debt Payments
        colors.vizDefaultRed,     // Premium Payments
        colors.CoreWarning,       // Available Funds
    ];

    const buttonGroupOptions = [
        {
            id: 'monthly',
            label: 'Monthly',
        },
        {
            id: 'annually',
            label: 'Annually',
        },
    ];


    return (
        <>
            <Global styles={AiPlanBuilderLayoutStyles} />
            <div className="ai-side-nav-mobile">
                <EuiFlexGroup className="ai-mobile-nav-header">
                    <EuiFlexItem className="ai-mobile-nav-item">
                        <StyledEuiButtonIcon
                            aria-label="Back"
                            iconType="arrowLeft"
                        // onClick={handleBackClick}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem
                        className="section-label"
                        onClick={() => setIsMobileNavVisible(!isMobileNavVisible)}
                    >
                        <span className="section-label-title">
                            Plan Setup
                        </span>
                        <span className="secondary-label-text">
                            <EuiIcon type={isMobileNavVisible ? "arrowUp" : "arrowDown"} />
                        </span>
                    </EuiFlexItem>
                    <EuiFlexItem
                        style={{ alignItems: "flex-end" }}
                        className="ai-mobile-nav-item"
                    >
                        <StyledEuiButtonIcon
                            aria-label="Cancel"
                            iconType="cross"
                            onClick={() => setShowCancelModal(true)}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
                {isMobileNavVisible && (
                    <EuiPanel className="mobile-nav-container">
                        <LeftSidebar />
                        {/* {stepIndex >= STEP_INDICES.Transition && (
              <ReviewSidebar
                handleClick={setReviewStep}
                handleEdit={handleReviewSectionEdit}
                reviewStep={reviewStep}
              />
            )} */}
                    </EuiPanel>
                )}
            </div>
            <div className="ai-content-container">
                <div className="ai-side-nav-desk">
                    <LeftSidebar />
                </div>
                <div className="ai-content">{children}</div>
                <EuiPanel className="grad-sidebar">
                    <Global styles={chartStyles} />
                    <EuiText>
                        <h2 className="dd-header">Budget Overview</h2>
                    </EuiText>
                    <StyledSpacer size="24px" />
                    <EuiButtonGroup
                        legend="Select period type"
                        options={buttonGroupOptions}
                        idSelected={isMonthly ? "monthly" : "annually"}
                        onChange={setIsMonthly}
                        buttonSize="compressed"
                        isFullWidth
                        className="rm-tab-group"
                    />
                    <StyledSpacer size="24px" />
                    <CircularProgressBar
                        data={chartData}
                        value={incomeTotal}
                        chartLabel="Total Income"
                        format="currency"
                        colors={chartColors}
                    />
                    <StyledSpacer size="24px" />
                    <div className="bo-header">
                        <EuiIcon type="dot" color={colors.vizDefaultGreen} />
                        Day-To-Day Money
                    </div>
                    <EuiFlexGroup justifyContent="spaceBetween">
                        <EuiFlexItem grow={false} className="bo-subtext">
                            Household Expenses
                        </EuiFlexItem>
                        <EuiFlexItem className="bo-amount" grow={false} style={{ justifyContent: 'flex-end' }}>
                            {formatValue(expenseTotal)}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <StyledSpacer size="16px" />
                    <div className="bo-header">
                        <EuiIcon type="dot" color={colors.vizDefaultBlue} />
                        Money For Future Goals
                    </div>
                    <EuiFlexGroup justifyContent="spaceBetween">
                        <EuiFlexItem grow={false} className="bo-subtext">
                            Asset Contributions
                        </EuiFlexItem>
                        <EuiFlexItem className="bo-amount" grow={false} style={{ justifyContent: 'flex-end' }}>
                            {formatValue(assetsTotal)}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup justifyContent="spaceBetween">
                        <EuiFlexItem grow={false} className="bo-subtext">
                            Debt Payments
                        </EuiFlexItem>
                        <EuiFlexItem className="bo-amount" grow={false} style={{ justifyContent: 'flex-end' }}>
                            {formatValue(debtsTotal)}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <StyledSpacer size="16px" />
                    <div className="bo-header">
                        <EuiIcon type="dot" color={colors.vizDefaultRed} />
                        Risk Management
                    </div>
                    <EuiFlexGroup justifyContent="spaceBetween">
                        <EuiFlexItem grow={false} className="bo-subtext">
                            Premium Payments
                        </EuiFlexItem>
                        <EuiFlexItem className="bo-amount" grow={false} style={{ justifyContent: 'flex-end' }}>
                            {formatValue(riskTotal)}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <StyledSpacer size="16px" />
                    <EuiFlexGroup justifyContent="spaceBetween">
                        <EuiFlexItem grow={false}>
                            <div className="bo-header">
                                <EuiIcon type="dot" color={colors.CoreWarning} />
                                Available Funds
                            </div>
                        </EuiFlexItem>
                        <EuiFlexItem className="bo-amount" grow={false} style={{ justifyContent: 'flex-end' }}>
                            {formatValue(remainingMoney)}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPanel >
            </div>
            <div className="ai-footer-nav">
                <EuiFlexGroup justifyContent="spaceBetween">
                    {windowSize.width >= 768 && (
                        <>
                            <EuiFlexItem>
                                <EuiFlexGroup>
                                    <EuiFlexItem>
                                        <StyledEuiButton
                                            color="text"
                                            className="danger-sec-btn"
                                            onClick={() => setShowCancelModal(true)}
                                        >
                                            Cancel
                                        </StyledEuiButton>
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <StyledEuiButton
                                            color="text"
                                            onClick={onPrev}
                                        >
                                            Back
                                        </StyledEuiButton>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        </>
                    )}
                    <EuiFlexItem>
                        <EuiFlexGroup
                            justifyContent={
                                windowSize.width < 768 ? "spaceBetween" : "flexEnd"
                            }
                        >
                            <StyledEuiButton
                                color="text"
                            // onClick={saveAndClose}
                            // disabled={!!errors?.size}
                            >
                                Save &amp; Exit
                            </StyledEuiButton>
                            <StyledEuiButton
                                fill
                                color="primary"
                                onClick={onNext}
                                style={{ border: "none" }}
                            // disabled={nextDisabled}
                            >
                                Next
                            </StyledEuiButton>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                </EuiFlexGroup>
                {/* <EditOptions
                    open={dialogOpen}
                    handleClose={handleDialogClose}
                    // handleClick={(optimized) => {
                    //     handleEditRoute(optimized);
                    //     handleDialogClose();
                    // }}
                /> */}
            </div>
            {/* {showCancelModal && (
                <ConfirmCancelModal
                    confirm={exit}
                    onClose={() => setShowCancelModal(false)}
                />
            )} */}
        </>
    );
};

export default GradPlanBuilderLayout;