import React, { useState, useEffect } from 'react';
import {
    EuiBasicTable,
    EuiFieldText,
    EuiButtonIcon,
    EuiButton,
    EuiSuperSelect,
    EuiFlexGroup,
    EuiFlexItem,
    EuiBasicTableColumn,
} from '@elastic/eui';
import { MappedCashflow, RISK_MANAGEMENT_TYPES } from 'src/interfaces';
import { DollarTextField } from 'src/utils';
import { StyledSpacer } from 'src/components/Global/StyledComponents';
import { Global, css } from '@emotion/react';

const rmStyles = css`
    .euiSuperSelect {
        width: 100%;
    }
    .euiTableCellContent__text {
        font-size: 16px !important;
    }
    .euiTableCellContent {
        font-size: 16px !important;
    }
`;

interface Column {
    field: keyof MappedCashflow;
    label: string;
    type?: string;
    formatter?: (value: any) => string;
}

interface InsuranceTableProps {
    title: string;
    columns: Column[];
    data: MappedCashflow[];
    onSave: (item: MappedCashflow, update: Partial<MappedCashflow>, index: number) => void;
    onDelete: (item: MappedCashflow, index: number) => void;
    addLabel?: string;
    onAdd: () => void;
    totalIncome: number;
}

const RiskManagementTable: React.FC<InsuranceTableProps> = ({
    title,
    columns,
    data,
    onSave,
    onDelete,
    addLabel,
    onAdd,
    totalIncome,
}) => {
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [editableData, setEditableData] = useState<MappedCashflow[]>([]);

    useEffect(() => {
        setEditableData(data);
    }, [data]);

    // Update fields when editing the percent value
    const handlePercentChange = (index: number, value: string) => {
        const newPercent = value.trim() === '' ? null : parseFloat(value);
        const newAnnual = newPercent !== null && totalIncome ? (newPercent / 100) * totalIncome : 0;
        const newMonthly = newAnnual ? Math.round(newAnnual / 12) : 0;

        setEditableData((prevData) =>
            prevData.map((item, idx) =>
                idx === index
                    ? {
                        ...item,
                        percent: newPercent,
                        annual: newPercent !== null ? newAnnual : null,
                        monthly: newPercent !== null ? newMonthly : null,
                    }
                    : item
            )
        );
    };

    const handleEdit = (index: number) => {
        setEditingIndex(index);
    };

    const handleSave = (index: number) => {
        const item = editableData[index];
        if (item) {
            const annualAmount = Number(item.annual) || 0;
            const updatedItem = {
                ...item,
                percent: totalIncome ? (annualAmount / totalIncome) * 100 : 0,
                typeLabel: RISK_MANAGEMENT_TYPES[item.type],
            };
            onSave(updatedItem, updatedItem, index);
        }
        setEditingIndex(null);
    };


    const handleDelete = (item: MappedCashflow, index: number) => {
        if (onDelete) {
            onDelete(item, index);
        }
    };

    const handleFieldChange = <K extends keyof MappedCashflow>(
        index: number,
        field: K,
        value: MappedCashflow[K]
    ) => {
        setEditableData((prevData) =>
            prevData.map((item, idx) => (idx === index ? { ...item, [field]: value } : item))
        );
    };

    const handleTypeChange = (index: number, value: keyof typeof RISK_MANAGEMENT_TYPES) => {
        setEditableData((prevData) =>
            prevData.map((item, idx) => (idx === index ? { ...item, type: value } : item))
        );
    };

    const typeOptions = Object.keys(RISK_MANAGEMENT_TYPES).map((key) => ({
        value: key,
        inputDisplay: RISK_MANAGEMENT_TYPES[key],
    }));

    const dataColumns: Array<EuiBasicTableColumn<MappedCashflow>> = columns.map((col) => ({
        field: col.field,
        name: col.label,
        render: (value: any, record: MappedCashflow) => {
            const index = editableData.indexOf(record);
            const isEditing = editingIndex === index;

            if (isEditing) {
                // Handle the "type" field with EuiSuperSelect
                if (col.field === 'type') {
                    return (
                        <EuiSuperSelect
                            style={{ width: '100%' }}
                            options={typeOptions}
                            valueOfSelected={String(editableData[index]?.type || '')}
                            onChange={(value) => handleTypeChange(index, value)}
                            itemLayoutAlign="top"
                            hasDividers
                            fullWidth
                        />
                    );
                }

                // Handle the "percent" field with EuiFieldText
                if (col.field === 'percent') {
                    return (
                        <EuiFieldText
                            value={editableData[index][col.field] !== null ? String(editableData[index][col.field]) : ''}
                            onChange={(e) => handlePercentChange(index, e.target.value)}
                            aria-label={`Edit ${col.label}`}
                        />
                    );
                }

                // Handle other fields with DollarTextField
                return (
                    <DollarTextField
                        value={String(editableData[index][col.field] || '')}
                        onChange={(e: any) =>
                            handleFieldChange(index, col.field, e.target.value)
                        }
                        aria-label={`Edit ${col.label}`}
                        eui
                    />
                );
            }

            // View mode
            if (col.field === 'type') {
                // Display the human-readable label for the type field
                return RISK_MANAGEMENT_TYPES[record.type] || '';
            }

            // Display other fields using the formatter if available
            return col.formatter ? col.formatter(Number(value) || 0) : value;
        },
    }));


    const actionsColumn: EuiBasicTableColumn<MappedCashflow> = {
        name: 'Actions',
        render: (record: MappedCashflow) => {
            const index = editableData.indexOf(record);
            const isEditing = editingIndex === index;
            return (
                <>
                    <EuiButtonIcon
                        iconType={isEditing ? 'check' : 'pencil'}
                        aria-label={isEditing ? 'Save' : 'Edit'}
                        onClick={() => (isEditing ? handleSave(index) : handleEdit(index))}
                    />
                    <EuiButtonIcon
                        iconType="trash"
                        color="danger"
                        aria-label="Delete"
                        onClick={() => handleDelete(record, index)}
                    />
                </>
            );
        },
    };

    const tableColumns = [...dataColumns, actionsColumn];

    return (
        <>
            <Global styles={rmStyles} />
            <EuiFlexGroup>
                <EuiFlexItem>
                    <h2>{title}</h2>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton onClick={onAdd} iconType="plusInCircle" color="text" style={{ border: '1px solid' }}>
                        {addLabel}
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
            <StyledSpacer size="24px" />
            <EuiBasicTable
                className="table-border"
                items={editableData}
                columns={tableColumns}
                rowHeader="type"
            />
        </>
    );
};

export default RiskManagementTable;