import React, { useState } from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel } from "@elastic/eui";

import {
  StyledEuiButton,
  StyledEuiButtonIcon,
} from "src/components/Global/StyledComponents";

import LeftSidebar from "./LeftSidebar";
import { STEP_INDICES } from "src/pages/OptimizedPlan/common";
import ConfirmCancelModal from "./ConfirmCancelModal";
import EditOptions from "./EditOptions";
import ReviewSidebar from "src/pages/PlanBuilder/Components/ReviewSidebar";
import useWindowSize from "src/hooks/useWindowSize";
import { Global, css } from "@emotion/react";

const AiPlanBuilderLayoutStyles = css`
  .actt-main {
    z-index: 1 !important;
  }
`;

const AiPlanBuilderLayout = ({
  handleBackClick,
  children,
  stepIndex,
  currentStepLabel,
  navigate,
  reviewStep,
  setReviewStep,
  reviewStepLabel,
  totalReviewSteps,
  exit,
  saveAndClose,
  implementPlan,
  errors,
  handleNextClick,
  handleEditRoute,
  nextDisabled,
  manual,
}: any) => {
  const windowSize = useWindowSize();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const handleReviewSectionEdit = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Global styles={AiPlanBuilderLayoutStyles} />
      <div className="ai-side-nav-mobile">
        <EuiFlexGroup className="ai-mobile-nav-header">
          <EuiFlexItem className="ai-mobile-nav-item">
            <StyledEuiButtonIcon
              aria-label="Back"
              iconType="arrowLeft"
              onClick={handleBackClick}
            />
          </EuiFlexItem>
          <EuiFlexItem
            className="section-label"
            onClick={() => setIsMobileNavVisible(!isMobileNavVisible)}
          >
            <span className="section-label-title">
              {stepIndex >= STEP_INDICES.Transition ? "Overview" : "Plan Setup"}
            </span>
            <span className="secondary-label-text">
              {stepIndex >= STEP_INDICES.Transition
                ? `${reviewStepLabel} (${
                    reviewStep + 1
                  } of ${totalReviewSteps})`
                : currentStepLabel}
              <EuiIcon type={isMobileNavVisible ? "arrowUp" : "arrowDown"} />
            </span>
          </EuiFlexItem>
          <EuiFlexItem
            style={{ alignItems: "flex-end" }}
            className="ai-mobile-nav-item"
          >
            <StyledEuiButtonIcon
              aria-label="Cancel"
              iconType="cross"
              onClick={() => setShowCancelModal(true)}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        {isMobileNavVisible && (
          <EuiPanel className="mobile-nav-container">
            {stepIndex < STEP_INDICES.Transition && (
              <LeftSidebar stepIndex={stepIndex} handleClick={navigate} />
            )}
            {stepIndex >= STEP_INDICES.Transition && (
              <ReviewSidebar
                handleClick={setReviewStep}
                handleEdit={handleReviewSectionEdit}
                reviewStep={reviewStep}
              />
            )}
          </EuiPanel>
        )}
      </div>
      <div className="ai-content-container">
        <div className="ai-side-nav-desk">
          {stepIndex < STEP_INDICES.Transition && (
            <LeftSidebar stepIndex={stepIndex} handleClick={navigate} />
          )}
          {stepIndex >= STEP_INDICES.Transition && (
            <ReviewSidebar
              manual={manual}
              handleClick={setReviewStep}
              handleEdit={handleReviewSectionEdit}
              reviewStep={reviewStep}
            />
          )}
        </div>
        <div className="ai-content">{children}</div>
      </div>
      <div className="ai-footer-nav">
        <EuiFlexGroup justifyContent="spaceBetween">
          {windowSize.width >= 768 && (
            <>
              <EuiFlexItem>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    {stepIndex < STEP_INDICES.Transition ? (
                      <StyledEuiButton
                        color="text"
                        className="danger-sec-btn"
                        onClick={() => setShowCancelModal(true)}
                      >
                        Cancel
                      </StyledEuiButton>
                    ) : (
                      <StyledEuiButton
                        color="text"
                        onClick={saveAndClose}
                        disabled={!!errors.size}
                      >
                        Exit
                      </StyledEuiButton>
                    )}
                  </EuiFlexItem>
                  {stepIndex >= 1 && (
                    <EuiFlexItem>
                      <StyledEuiButton color="text" onClick={handleBackClick}>
                        Back
                      </StyledEuiButton>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
              </EuiFlexItem>
            </>
          )}
          <EuiFlexItem>
            <EuiFlexGroup
              justifyContent={
                windowSize.width < 768 ? "spaceBetween" : "flexEnd"
              }
            >
              {stepIndex < STEP_INDICES.Transition ? (
                <StyledEuiButton
                  color="text"
                  onClick={saveAndClose}
                  disabled={!!errors?.size}
                >
                  Save &amp; Exit
                </StyledEuiButton>
              ) : (
                <StyledEuiButton
                  color="text"
                  onClick={implementPlan}
                  disabled={!!errors.size || !implementPlan}
                >
                  Implement Plan
                </StyledEuiButton>
              )}
              <StyledEuiButton
                fill
                color="primary"
                onClick={handleNextClick}
                style={{ border: "none" }}
                disabled={nextDisabled}
              >
                Next
              </StyledEuiButton>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EditOptions
          open={dialogOpen}
          handleClose={handleDialogClose}
          handleClick={(optimized) => {
            handleEditRoute(optimized);
            handleDialogClose();
          }}
        />
      </div>
      {showCancelModal && (
        <ConfirmCancelModal
          confirm={exit}
          onClose={() => setShowCancelModal(false)}
        />
      )}
    </>
  );
};

export default AiPlanBuilderLayout;
