import * as React from "react";
import { useSelector } from "react-redux";

import PlanBuilderLayout from "src/layouts/PlanBuilderLayout";
import StudentPlanBuilderLayout from "src/layouts/StudentPlanBuilderLayout";
import { Content } from "./Content";
import { getUserInSchool } from "src/store/profileBuild/selector";

const PlanBuild = () => {
  console.log("PlanBuild component is rendering");

  const userInSchool = useSelector(getUserInSchool);
  console.log("userInSchool value:", userInSchool);

  return (
    <>
      {userInSchool ? (
        <StudentPlanBuilderLayout>
          <Content />
        </StudentPlanBuilderLayout>
      ) : (
        <PlanBuilderLayout>
          <Content />
        </PlanBuilderLayout>
      )}
    </>
  );
};

export default PlanBuild;