import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Card, Link, makeStyles, Typography } from "@material-ui/core";

import {
  EuiButtonIcon,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText
} from "@elastic/eui";

import { getCashflows, getEstimatedTaxes } from "src/store/cashflow/selector";
import {
  currentPlanAllocationTotals,
  currentPlanExpenseTotal,
  currentPlanIncomeTotal,
  currentPlanRiskManagementTotal,
  getLiabilities,
  isCurrentPlanImplemented,
} from "src/store/planBuild/selector";
import { savePlan, savePlanAndCashflows } from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { getIsCurrentStudent } from "src/store/system/selector";
import { PlanViewComponent } from "src/interfaces";
import { notifications, RECOMMENDATION_CATEGORY } from "./notifications";
import CenterContainer from "../../Components/CenterContainer";
import HelpToggle from "src/components/HelpToggle";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const useStyle = makeStyles({
  messageContainer: {
    // height: "80px",
    display: "flex",
    alignItems: "center",
    borderRadius: "15px",
    padding: "10px 15px",
    margin: "10px 0px",
  },
  message: {
    width: "75%",
  },
  link: {
    width: "25%",
    display: "flex",
    justifyContent: "center",
  },
});

const Recommendations: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [recommendations, setRecommendations] = useState<any[]>([""]);
  const [disableNext, setDisableNext] = useState(false);

  const planIncomeTotal = useSelector(currentPlanIncomeTotal);
  const planExpenseTotal = useSelector(currentPlanExpenseTotal);
  const { assetsTotal, debtsTotal } = useSelector(currentPlanAllocationTotals);
  const risks = useSelector(currentPlanRiskManagementTotal);
  const liabilities = useSelector(getLiabilities).min;
  const { income, expenses } = useSelector(getCashflows);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
  const estimatedTaxes = useSelector(getEstimatedTaxes);

  const { incomeTotal, expenseTotal } = useMemo(() => {
    if (isCurrentStudent || !currentPlanIsImplemented) {
      return { incomeTotal: planIncomeTotal, expenseTotal: planExpenseTotal };
    }
    return {
      incomeTotal: income.reduce(
        (result, item) => result + (item.annual || 0),
        0
      ),
      expenseTotal: expenses.reduce(
        (result, item) => result + (item.annual || 0),
        estimatedTaxes
      ),
    };
  }, [
    currentPlanIsImplemented,
    isCurrentStudent,
    income,
    expenses,
    estimatedTaxes,
  ]);

  const cashAfterExpenses = incomeTotal - expenseTotal;
  const remainingMoney = cashAfterExpenses - (assetsTotal + debtsTotal + risks);

  useEffect(() => {
    // TODO: Once we are ready to enable notifications, change the below const to a let
    const userRecommendations: any[] = [];
    if (remainingMoney < 0) {
      userRecommendations.push(
        notifications[RECOMMENDATION_CATEGORY.VACATION_FUN_MONEY]
      );
      setDisableNext(true);
    } else {
      // TODO: hide recommendations- comment out below line to hide notifications
      // userRecommendations = getNotifications();
      setDisableNext(false);
    }
    setRecommendations(userRecommendations);
  }, [incomeTotal, expenseTotal, assetsTotal, debtsTotal, risks, liabilities]);

  useEffect(() => {
    if (!recommendations.length) {
      onNext();
    }
  }, [recommendations]);

  const onNext = () => {
    dispatch(savePlan(PLAN_BUILD_STEPS.GRADUATED_REVIEW));
  };

  //   if (!recommendations.length || bypassRecomendations) {
  //     return <ReviewPlan render={render} />;
  //   }

  const getToolTipMessage = () => {
    return disableNext
      ? "You have exceeded your budget. Adjust your plan so that there is some vacation/fun money left over."
      : "";
  };

  const handleClick = (planStep: number) => {
    dispatch(savePlanAndCashflows(planStep as PLAN_BUILD_STEPS));
  };

  return render({
    component: (
      <>
        <EuiFlexGroup className="ai-flex-content">
          <EuiFlexItem>
            <div className="ai-content-title">
              <EuiFlexGroup style={{ gap: 0 }}>
                <EuiFlexItem grow={false}>
                  <h1>Recommendations</h1>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="32px" />
        {recommendations.map((item: any, index: number) => {
          return (
            <EuiCard key={index} title>
              <>
                {item.message && (
                  <EuiText style={{ textAlign: 'left' }}>
                    {item.message}

                    {/* {item.help && (
                      <span style={{ position: "relative" }}>
                        <span style={{ position: "absolute" }}>
                          <HelpToggle content={item.help} />
                        </span>
                      </span>
                    )} */}
                  </EuiText>
                )}

                {item.planStep && (
                  <EuiLink
                    onClick={() => {
                      handleClick(item.planStep);
                    }}
                  >
                    <EuiText className="text-sm">
                      Click Here To Fix
                    </EuiText>
                  </EuiLink>
                )}
              </>
            </EuiCard>
          );
        })}
      </>
    ),
    onNext,
    nextDisabled: disableNext,
    nextTooltip: getToolTipMessage(),
  });
};

export default Recommendations;
