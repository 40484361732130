import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cloneDeep } from "lodash";

import {
  Box,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import {
  EuiButtonIcon,
  EuiFormRow,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSuperSelect,
  EuiText
} from "@elastic/eui";

import CenterContainer from "../../Components/CenterContainer";
import { updateCurrentPlan } from "src/store/planBuild/actions";
import { getStudentLoanData } from "src/store/planBuild/selector";
import { PlanViewComponent, SidebarContent } from "src/interfaces";
import ToolTipIcon from "src/components/ToolTipIcon";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const blowout: SidebarContent[] = [
  {
    header: "High Rate Vs Low Balance",
    body: [
      "The high interest rate method pays off your highest interest rate loan first and will always save you the most money.",
      "The low balance method pays off the loan with the lowest balance.  The benefits of this method are 1) behaviorally satisfying because you achieve a goal the fastest and 2) could help you qualify for another loan, such as a mortgage, faster because your DTI drops.",
      <Typography>
        For more information,{" "}
        <a
          target="blank"
          href={
            "https://www.fitbux.com/articles/dave-ramsey-student-loan-repayment/"
          }
        >
          {" "}
          check out this article.
        </a>
      </Typography>,
    ],
  },
];

const DebtPrepayment: PlanViewComponent = ({ onSave, render }) => {
  const dispatch = useDispatch();
  const studentloan = useSelector(getStudentLoanData);
  const myStudentLoanEntry = studentloan[0];
  const initialFormValues = {
    strategy: myStudentLoanEntry?.strategy || "high_interest_rate",
  };
  const [formValues, setFormValues] = useState<any>(initialFormValues);

  const save = () => {
    const updatedStudentloan = cloneDeep(studentloan);
    updatedStudentloan[0].strategy = formValues.strategy;
    updatedStudentloan[1].strategy = formValues.strategy;
    dispatch(
      updateCurrentPlan({
        studentloan: updatedStudentloan,
      })
    );
    onSave();
  };

  const setFormValue = (value: string) => {
    const newFormValues = { ...formValues, strategy: value };
    setFormValues(newFormValues);
  };

  return render({
    component: (
      <>
        <EuiFlexGroup className="ai-flex-content">
          <EuiFlexItem>
            <div className="ai-content-title">
              <EuiFlexGroup style={{ gap: 0 }}>
                <EuiFlexItem grow={false}>
                  <h1>Debt Prepayments</h1>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    // onClick={() => toggleFlyout("life-goals")}
                    iconType="questionInCircle"
                    aria-label="Help"
                    className="help-icon"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="32px" />
        <EuiText>
          Do you want to use the high interest rate or low balance method to prepay debt?
        </EuiText>
        <StyledSpacer size="32px" />
        <EuiFormRow
          label="Choose a repayment method:"
        >
          <EuiSuperSelect
            options={[
              { value: "high_interest_rate", inputDisplay: "High interest rate" },
              { value: "low_balance", inputDisplay: "Low balance" },
            ]}
            valueOfSelected={formValues.strategy}
            onChange={setFormValue}
            hasDividers
          />
        </EuiFormRow>
      </>
    ),
    nextLabel: "Save",
    onNext: save,
  });
};

export default DebtPrepayment;
