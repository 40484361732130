import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Container,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";

import { EuiFormRow, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from "@elastic/eui";

import { getScore } from "src/store/dashboard/selector";
import { getPortfolioPerformance } from "src/store/investments/selector";
import { savePlan, updateCurrentPlan } from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { getCurrentPlan, getPlans } from "src/store/planBuild/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import CenterContainer from "../../Components/CenterContainer";
import { scoreToAllocation } from "src/utils";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const EnterName: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const currentPlan = useSelector(getCurrentPlan);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const score = useSelector(getScore);
  const [name, setName] = useState("");
  const { expectedReturns } = useSelector(getPortfolioPerformance);
  const existingPlans: any[] = useSelector(getPlans);
  let nextStep = isCurrentStudent
    ? PLAN_BUILD_STEPS.EDUCATION_FUNDING
    : PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW;
  if (existingPlans && existingPlans.length) {
    nextStep = PLAN_BUILD_STEPS.REUSE_SELECTIONS;
  }

  const getInvestmentROR = () => {
    const portfolio = scoreToAllocation(score);
    return expectedReturns[portfolio];
  };

  const onNext = () => {
    const planName = name || "My Financial Plan";
    dispatch(updateCurrentPlan({
      name: planName,
      // Building a new plan, override assumptions based on current profile
      assumptions: {
        ...currentPlan.assumptions,
        investments: getInvestmentROR(),
        home_loan_rate: 5.4,
      }
    }));
    dispatch(savePlan(nextStep));
  };

  const onPrev = () => {
    // Check if there is a previous step to go back to
    if (nextStep > PLAN_BUILD_STEPS.NAME) {
      // Update the current plan to move back one step
      dispatch(updateCurrentPlan({
        assumptions: {
          ...currentPlan.assumptions,
          investments: getInvestmentROR(),
          home_loan_rate: 5.4,
        }
      }));
      // Save the plan and go to the previous step
      dispatch(savePlan(nextStep - 1));
    }
  };
  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);

  const renderNameInput = () => {
    if (isCurrentStudent) {
      return (
        <Paper className="w-full h-full">
          <Container maxWidth="sm">
            <Box className="w-full py-28">
              <Typography variant="h2" component="h2" className="mb-3">
                First, let's give your plan a name
              </Typography>
              <TextField
                className="w-full"
                variant="outlined"
                placeholder="Your plan name"
                onChange={handleChange}
                value={name}
              />
            </Box>
          </Container>
        </Paper>
      );
    } else {
      return (
        <>
          <EuiFlexGroup justifyContent="spaceBetween" className="ai-flex-content">
            <EuiFlexItem>
              <div className="ai-content-title">
                <EuiFlexGroup style={{ gap: 0 }}>
                  <EuiFlexItem grow={false}>
                    <h1>
                      Name My Plan
                    </h1>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </div>
            </EuiFlexItem>
          </EuiFlexGroup>
          <StyledSpacer size="32px" />
          <EuiFormRow
            label="Name your plan to get started.*"
            className="full-width-ai"
          >
            <EuiFieldText
              onChange={handleChange}
              value={name}
              fullWidth
              type="text"
            />
          </EuiFormRow>
        </>
      );
    }
  };

  return render({
    component: <>{renderNameInput()}</>,
    onNext,
    onPrev,
  });
};

export default EnterName;
