import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

import { colors } from "src/theme";
import fbColors from "src/theme/colors";
import {
  DollarTextField,
  formatWholeDollars,
} from "src/utils";
import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiText } from "@elastic/eui";

import { formatPercent } from "src/utils";
import { StyledSpacer } from "../Global/StyledComponents";

interface PercentBreakdownProps {
  min?: number;
  max?: number;
  income: number;
  onChange: (value: any) => void;
  values: any;
  label: string;
  isDebt?: boolean;
  who?: "applicant" | "spouse";
  incomeType?: string;
  unlimited?: boolean;
}

const whoStrings = {
  single: "",
  applicant: " My",
  spouse: " Spouse's",
};

const PercentBreakdown = ({
  min,
  onChange,
  income,
  values,
  label,
  isDebt,
  max,
  who,
  incomeType,
  unlimited,
}: PercentBreakdownProps) => {
  const styles = useStyles();
  const whoText = who ? whoStrings[who] : whoStrings["single"];
  const incomeText = incomeType ? `${incomeType} Income` : "Total Income";

  const handleContributionChange = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;

    let contribution;
    if (field === "annual") {
      contribution = value;
    } else if (field === "monthly") {
      contribution = value * 12;
    } else {
      contribution = Math.round((value * income) / 100);
    }

    const newFormValues = {
      ...values,
      contribution,
      percent: (contribution / income) * 100,
    };

    onChange(newFormValues);
  };

  return (
    <>
      <StyledSpacer size="16px" />
      <EuiFlexGroup>
        <EuiFlexItem grow={false} style={{justifyContent: 'center'}}>
          <EuiText>
            <strong>{label} {isDebt ? "Payment" : "Contribution"}</strong>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow
            label="Monthly"
            helpText={
              <>
                {typeof max === "number" && (
                  <EuiText>
                    Max: {formatWholeDollars(Math.floor(max / 12))}
                  </EuiText>
                )}
                {typeof min === "number" && (
                  <EuiText style={{ color: colors.error }}>
                    Min: {formatWholeDollars(Math.ceil(min / 12))}
                  </EuiText>
                )}
              </>
            }
          >
            <DollarTextField
              name="monthly"
              placeholder="$0"
              onChange={handleContributionChange}
              variant="outlined"
              max={typeof max === "number" ? Math.floor(max / 12) : undefined}
              value={Math.round((values.contribution || 0) / 12)}
              eui
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow
            label="Annual"
            helpText={
              <>
                {typeof max === "number" && (
                  <EuiText>
                    Max: {formatWholeDollars(Math.floor(max))}
                  </EuiText>
                )}
                {!!min && (
                  <EuiText style={{ color: colors.error }}>
                    Min: {formatWholeDollars(Math.ceil(min))}
                  </EuiText>
                )}
              </>
            }
          >
            <DollarTextField
              name="annual"
              placeholder="$0"
              onChange={handleContributionChange}
              variant="outlined"
              max={typeof max === "number" ? Math.floor(max) : undefined}
              value={values.contribution}
              eui
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow
            label="% of Total Income"
          >
            <EuiText>
              {formatPercent(values.percent || ((values.contribution || 0) / income) * 100 || 0)}
            </EuiText>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default PercentBreakdown;

const useStyles = makeStyles({
  cell: {
    width: "30%",
    marginLeft: 4,
    fontSize: 12,
  },
  headerCell: {
    fontWeight: "bolder",
    width: "30%",
    fontSize: 14,
    marginBottom: 15,
    color: fbColors.brandingBlue1,
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  typeLabel: {
    "text-transform": "capitalize",
    fontWeight: "bold",
    width: "35%",
    fontSize: 14,
  },
  headerContainer: {
    width: "35%",
  },
});
