import React, { useState } from "react";
import NumberFormat from "react-number-format";

import { EuiFieldText } from "@elastic/eui";
import { TextField } from "@material-ui/core";

export const DollarTextField = ({ eui, ...props }: any) => {
  const [isError, setIsError] = useState(false);
  const onChange = (e: any) => {
    const rawValue = e.target.value;
    const formattedValue =
      props.decimalScale === 0
        ? +rawValue.replace(/[^0-9]/g, "")
        : +rawValue.replace(/[^0-9.]/g, "");
    const max = props.max !== undefined ? props.max : Number.MAX_SAFE_INTEGER;
    setIsError(formattedValue > max);
    props.onChange({
      target: {
        name: e.target.name,
        value: formattedValue,
      },
    });
  };

  const handleFocus = () => {
    if (props.onFocus) {
      props.onFocus();
    }
    if (!props.value) {
      props.onChange({
        target: {
          name: props.name,
          value: "",
        },
      });
    }
  };

  const max = props.max !== undefined ? props.max : Number.MAX_SAFE_INTEGER;

  return (
    <NumberFormat
      {...props}
      decimalScale={props.decimalScale !== undefined ? props.decimalScale : 2}
      fixedDecimalScale
      onChange={onChange}
      onFocus={handleFocus}
      customInput={eui ? EuiFieldText : TextField}
      // getInputRef={inputRef}
      thousandSeparator
      prefix="$"
      inputMode="numeric"
      isAllowed={() => true}
      style={{ color: isError ? 'red' : 'inherit', borderColor: isError ? 'red' : 'inherit' }}
    />
  );
};

export const PercentTextField = ({ eui, ...props }: any) => {
  const max = Math.min(props.max >= 0 ? props.max : 100.0, 100.0);
  const unlimited = !!props.unlimited;
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const formattedValue = +rawValue.replace(/[^0-9.]/g, "");
    props.onChange({
      target: {
        name: e.target.name,
        value: formattedValue /*  FYI:  /100.0    */,
      },
    });
  };

  let suffix = "%";
  if (props.displayMin) {
    suffix += ` (min: ${props.displayMin}%)`;
  }

  let value = props.value;
  if (value === undefined || (!value && !props.allowZero)) {
    value = "";
  }

  return (
    <NumberFormat
      {...props}
      suffix={suffix}
      decimalScale={props.decimalScale ? props.decimalScale : 2}
      decimalSeparator="."
      customInput={eui ? EuiFieldText : TextField}
      value={value} /*  FYI:  *100.0    */
      isAllowed={({ floatValue }: any) => {
        if (unlimited) {
          return true;
        }
        return (!props.allowZero && !floatValue) || floatValue <= max;
      }}
      onChange={onChange}
    />
  );
};

export const PhoneNumberInput = (props: any) => (
  <NumberFormat
    format="###-###-####"
    mask="_"
    {...props}
    customInput={props.eui ? EuiFieldText : TextField}
  />
);