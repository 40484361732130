import React, { useRef, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    EuiText,
    EuiTimeline,
    EuiTimelineItem,
    EuiIcon,
    EuiBadge,
} from "@elastic/eui";
import styled from "@emotion/styled";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { savePlanAndCashflows } from "src/store/planBuild/actions";
import {
    getCurrentPlan,
    getCurrentStep,
    isCurrentPlanImplemented,
} from "src/store/planBuild/selector";
import { graduatedProgressSteps, PLAN_BUILD_STEPS } from "src/store/planBuild/constants";

const ProgressBarFilled = styled.div<{ height: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #0073e6;
  height: ${(props) => props.height}px;
  transition: height 0.3s ease;
  z-index: 2;
`;

interface Section {
    label: string;
    targetStep?: PLAN_BUILD_STEPS;
    steps?: { label: string; targets: PLAN_BUILD_STEPS[] }[];
}

export const sections: Section[] = [
    {
        label: "Plan Setup",
        steps: [
            { label: "Life Events/Goals", targets: [PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW] },
            { label: "Day-To-Day Money", targets: [PLAN_BUILD_STEPS.INCOME_AND_EXPENSES] },
            { label: "Money For Future Self", targets: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS] },
            { label: "Risk Management", targets: [PLAN_BUILD_STEPS.RISK_MANAGEMENT] },
            { label: "Emergency Fund", targets: [PLAN_BUILD_STEPS.SAVINGS] },
            { label: "Goal Ranking", targets: [PLAN_BUILD_STEPS.GOALS] },
        ],
    },
    {
        label: "Simulate & Review",
        targetStep: PLAN_BUILD_STEPS.GRADUATED_REVIEW,
    },
    {
        label: "Implement",
        targetStep: PLAN_BUILD_STEPS.IMPLEMENT,
    },
];

const GradLeftSidebar: React.FC = () => {
    const dispatch = useDispatch();
    const currentStep = useSelector(getCurrentStep);
    const plan = useSelector(getCurrentPlan);
    const isImplemented = useSelector(isCurrentPlanImplemented);
    const timelineContainerRef = useRef<HTMLDivElement>(null);
    const [progressHeight, setProgressHeight] = useState(0);

    const handleClick = (step: PLAN_BUILD_STEPS) => {
        dispatch(savePlanAndCashflows(step));
    };

    // Calculate the progress height based on visited steps
    useLayoutEffect(() => {
        if (timelineContainerRef.current) {
            const stepElements = Array.from(
                timelineContainerRef.current.querySelectorAll(".timeline-step")
            );

            let lastVisitedIndex = -1;

            stepElements.forEach((el, index) => {
                const targets = (el.getAttribute("data-target") || "")
                    .split(",")
                    .map(Number);

                const isVisited = targets.some(
                    (target) => graduatedProgressSteps[target as keyof typeof graduatedProgressSteps] <= (plan.progress || 0)
                );

                const isActive = targets.includes(currentStep);
                if (isVisited || isActive) {
                    lastVisitedIndex = index;
                }
            });

            // Calculate the height based on the last visited index
            if (lastVisitedIndex !== -1 && stepElements[lastVisitedIndex]) {
                const stepRect = stepElements[lastVisitedIndex].getBoundingClientRect();
                const timelineRect = timelineContainerRef.current.getBoundingClientRect();
                const newHeight = stepRect.top + stepRect.height / 2 - timelineRect.top;
                setProgressHeight(newHeight);
            }
        }
    }, [currentStep, plan.progress]);

    return (
        <div className="side-bar-wrapper">
            <EuiText className="side-bar-header">
                <h2>{plan?.name || "AI Financial Plan"}</h2>
                <EuiBadge>{isImplemented ? "Implemented" : "Not Implemented"}</EuiBadge>
            </EuiText>
            <StyledSpacer size="32px" />
            <div className="relative">
                <div className="progress-bar-container">
                    <ProgressBarFilled height={progressHeight} />
                </div>
                <div ref={timelineContainerRef}>
                    <EuiTimeline className="timeline">
                        {sections.map((section, sectionIndex) => (
                            <React.Fragment key={section.label}>
                                <EuiTimelineItem
                                    icon={<div className="step-number">{sectionIndex + 1}</div>}
                                    verticalAlign="center"
                                >
                                    <EuiText><b>{section.label}</b></EuiText>
                                </EuiTimelineItem>
                                {section.steps?.map((step) => {
                                    const isVisited =
                                        graduatedProgressSteps[step.targets[0] as keyof typeof graduatedProgressSteps] <=
                                        (plan.progress || 0);
                                    const isActive = step.targets.includes(currentStep);
                                    return (
                                        <EuiTimelineItem
                                            key={step.label}
                                            className={`timeline-step ${isVisited ? "visited-step" : ""} ${isActive ? "active-step" : ""}`}
                                            icon={
                                                <EuiIcon
                                                    type="dot"
                                                    color={isActive || isVisited ? "#0073e6" : "#98A2B3"}
                                                />
                                            }
                                            data-target={step.targets.join(",")}
                                            onClick={() => isVisited && handleClick(step.targets[0])}
                                        >
                                            <EuiText>{step.label}</EuiText>
                                        </EuiTimelineItem>
                                    );
                                })}
                            </React.Fragment>
                        ))}
                    </EuiTimeline>
                </div>
            </div>
        </div>
    );
};

export default GradLeftSidebar;