import React, { useState, useEffect } from 'react';
import {
    EuiBasicTable,
    EuiFieldText,
    EuiBasicTableColumn,
    EuiButtonIcon,
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
} from '@elastic/eui';
import { MappedCashflow } from 'src/interfaces';
import { DollarTextField } from 'src/utils';
import { StyledSpacer } from 'src/components/Global/StyledComponents';
import { useFlyout } from 'src/components/Global/FlyoutContext';

interface Column {
    field: string;
    label: string;
    type?: string;
    formatter?: (value: any) => string;
}

type Item = MappedCashflow;

interface BasicCardProps {
    title: string;
    columns: Column[];
    data: MappedCashflow[];
    onSave: (item: MappedCashflow, update: Partial<MappedCashflow>, index: number) => void;
    onDelete: (item: MappedCashflow, index: number) => void;
    addLabel?: string;
    addTooltip?: string;
    onAdd?: () => void;
}

const IncomeExpenseTable: React.FC<BasicCardProps> = ({
    title,
    columns,
    data,
    onSave,
    onDelete,
    addLabel,
    addTooltip,
    onAdd,
}) => {
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [editableData, setEditableData] = useState<Item[]>([]);
    const { toggleFlyout } = useFlyout();

    useEffect(() => {
        setEditableData(data);
    }, [data]);

    const totalMonthly = editableData.reduce((acc, item) => acc + (item.monthly || 0), 0);
    const totalAnnual = editableData.reduce((acc, item) => acc + (item.annual || 0), 0);

    const handleEdit = (index: number) => {
        setEditingIndex(index);
    };

    const handleSave = (index: number) => {
        if (onSave) {
            onSave(editableData[index], editableData[index], index);
        }
        setEditingIndex(null);
    };

    const handleDelete = (item: MappedCashflow, index: number) => {
        if (onDelete) {
            onDelete(item as MappedCashflow, index);
        }
    };

    const handleFieldChange = <K extends keyof MappedCashflow>(
        index: number,
        field: K,
        value: MappedCashflow[K]
    ) => {
        setEditableData(prevData =>
            prevData.map((item, idx) => {
                if (idx === index) {
                    const updatedItem = { ...item, [field]: value };
                    if (field === 'monthly' && typeof value === 'number') {
                        updatedItem.annual = Math.round(value * 12);
                    } else if (field === 'annual' && typeof value === 'number') {
                        updatedItem.monthly = Math.round(value / 12);
                    }
                    return updatedItem;
                }
                return item;
            })
        );
    };


    // Define columns with in-row editing logic
    const tableColumns: Array<EuiBasicTableColumn<Item>> = columns.map((col) => ({
        field: col.field,
        name: col.label,
        render: (value: any, record: Item) => {
            const index = editableData.indexOf(record);
            const isEditing = editingIndex === index;

            if (isEditing) {
                if (col.type === 'number') {
                    return (
                        <DollarTextField
                            value={String(editableData[index][col.field as keyof MappedCashflow] ?? '')}
                            onChange={(e: any) =>
                                handleFieldChange(index, col.field as keyof MappedCashflow, e.target.value)
                            }
                            aria-label={`Edit ${col.label}`}
                            eui
                        />
                    );
                }
                return (
                    <EuiFieldText
                        value={String(editableData[index][col.field as keyof MappedCashflow] ?? '')}
                        onChange={(e) =>
                            handleFieldChange(index, col.field as keyof MappedCashflow, e.target.value)
                        }
                        aria-label={`Edit ${col.label}`}
                    />
                );
            }
            return col.formatter ? col.formatter(value) : value;
        },
    }));

    if (editableData.some(item => item.id !== -1 || item.id === -1)) {
        tableColumns.push({
            name: 'Actions',
            actions: [
                {
                    name: editingIndex === null ? 'Edit' : 'Save',
                    description: editingIndex === null ? 'Edit this row' : 'Save changes',
                    icon: (item) =>
                        editingIndex === editableData.indexOf(item) ? 'check' : 'pencil',
                    type: 'icon',
                    onClick: (item) => {
                        const rowIndex = editableData.indexOf(item);
                        editingIndex === rowIndex ? handleSave(rowIndex) : handleEdit(rowIndex);
                    },
                },
                {
                    name: 'Delete',
                    description: 'Delete this row',
                    icon: 'trash',
                    type: 'icon',
                    color: 'danger',
                    onClick: (item) => handleDelete(item, editableData.indexOf(item)),
                },
            ],
        } as EuiBasicTableColumn<Item>);
    }


    const dataWithTotal = [
        ...editableData,
        { id: -1, type: 'Total', typeLabel: 'Total', monthly: totalMonthly, annual: totalAnnual } as MappedCashflow,
    ];

    return (
        <>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiFlexGroup className="ai-flex-content">
                        <EuiFlexItem style={{ justifyContent: 'flex-end' }}>
                            <div className="ai-content-title">
                                <EuiFlexGroup style={{ gap: 0 }}>
                                    <EuiFlexItem grow={false}>
                                        <h1>{title}</h1>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false}>
                                        <EuiButtonIcon
                                            onClick={() => toggleFlyout(title === 'Income' ? 'income-goals' : 'expense-tracking')}
                                            iconType="questionInCircle"
                                            aria-label="Help"
                                            className="help-icon"
                                        />
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </div>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ alignSelf: 'flex-end' }}>
                    <StyledSpacer size="24px" />
                    <EuiButton onClick={onAdd} iconType="plusInCircle" color="text" style={{ border: '1px solid' }}>
                        {addLabel}
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
            <StyledSpacer size="24px" />
            <EuiBasicTable
                className='table-border'
                items={dataWithTotal}
                columns={tableColumns}
                rowHeader="firstName"
            />
        </>
    );
};

export default IncomeExpenseTable;