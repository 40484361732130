import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Global, css } from "@emotion/react";
import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSuperSelect,
} from "@elastic/eui";

import { LabelStyle } from "src/components/Global/StyledComponents";

import {
  savePlan,
  updateAllocations,
  estimateCurrentPlanTaxes,
} from "src/store/planBuild/actions";
import {
  getCurrentPlan,
  getMy401kEligibleIncome,
  getSpouse401kEligibleIncome,
  getMyMaxTradIRADollarContribution,
  getMyMaxRothIRADollarContribution,
  getSpouseMaxTradIRADollarContribution,
  getSpouseMaxRothIRADollarContribution,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { TAX_DEFERRED_INVESTMENT_TYPES, Plan } from "src/interfaces";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import { ASSET_TYPES, SidebarContent } from "src/interfaces";

import { useStyles } from "./styles";
import { CURATED_PLAN_BUILD_STEPS } from "./common";
import PercentBreakdown from "src/components/PercentBreakdown";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const catStyles = css`
  .close-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const roth_ira_blowout: SidebarContent[] = [
  {
    header: "Recommendation For ROTH IRA",
    body: [
      "If you are eligible to contribute to a Roth IRA, we highly recommend contributing $50 monthly.",
      "Diversifiying your retirement income and using different account types will help reduce the risk to that income. For instance, if the government changes the tax treatment of assets held in a specific type of account negatively, assets held in a different type of account won't be affeced, all else equal.",
    ],
  },
  {
    header: "What Is A Roth IRA?",
    body: [
      "Roth IRAs are tax-advantaged accounts with special features for withdrawals in retirement:",
      "1) Contributions you make each year do not reduce how much you pay in taxes in the tax year the contribution is made.",
      "2) If you withdraw the money after age 59 1/2, you pay no taxes and no penalties.",
      "3) Early withdrawals are subject to taxes and penalties. However, there are special withdrawals that may not incur taxes, such as using Roth IRA money for a down payment on a house.",
    ],
  },
];

const AddOrEditIRA: PlanViewComponent = ({
  initialValues,
  onClose,
  onSave,
  render,
  type,
  curatedIndex,
}) => {
  const isCuratedFlow =
    curatedIndex === CURATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT;
  const isEditFlow = !!initialValues;
  const styles = useStyles();
  const dispatch = useDispatch();
  const plan: Plan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const curatedWho = "applicant";
  const addWho = isMarried ? "" : "applicant";
  const addEditWho = isEditFlow ? initialValues.who : addWho;
  const initialWho = isCuratedFlow ? curatedWho : addEditWho;
  const initialFormValues: any = {
    who: initialWho,
  };
  if (initialValues) {
    initialFormValues.who = initialValues.who;
    const itemIndex = isMarried && initialValues.who === "spouse" ? 1 : 0;
    const soloAllocation = plan.allocations[0].solo[itemIndex];
    initialFormValues.contribution =
      soloAllocation[type as "ira_value" | "roth_ira_value"];
  }
  const [formValues, setFormValues] = useState<any>(initialFormValues);
  let maxSelector = getMyMaxTradIRADollarContribution;

  if (formValues.who === "spouse") {
    if (type === "ira_value") {
      maxSelector = getSpouseMaxTradIRADollarContribution;
    } else {
      maxSelector = getSpouseMaxRothIRADollarContribution;
    }
  } else if (type === "roth_ira_value") {
    maxSelector = getMyMaxRothIRADollarContribution;
  }
  const maxDollars = useSelector(maxSelector);
  const selectedIncome =
    formValues.who === "spouse"
      ? getSpouse401kEligibleIncome
      : getMy401kEligibleIncome;
  const eligibleIncome = useSelector(selectedIncome);
  const setFormWho = (value: string) => {
    const newFormValues = { ...formValues, who: value };
    setFormValues(newFormValues);
  };

  const help = type === "roth_ira_value" ? roth_ira_blowout : undefined;
  const accountType =
    type === "roth_ira_value" ? "Roth IRA" : "Traditional IRA";

  const save = () => {
    const whoIndex = isMarried && formValues.who === "spouse" ? 1 : 0;
    const soloAllocations = plan.allocations[0].solo;
    const newSoloAllocations = [...soloAllocations];
    const annualContribution = formValues.contribution;
    newSoloAllocations[whoIndex] = {
      ...soloAllocations[whoIndex],
      [type]: annualContribution,
    };
    if (initialValues && formValues.who !== initialValues.who) {
      const otherIndex = whoIndex ? 0 : 1;
      newSoloAllocations[otherIndex] = {
        ...newSoloAllocations[otherIndex],
        [type]: 0,
      };
    }
    let combinedContribution =
      newSoloAllocations[0][type as "ira_value" | "roth_ira_value"] || 0;
    if (isMarried) {
      combinedContribution +=
        newSoloAllocations[1][type as "ira_value" | "roth_ira_value"] || 0;
    }
    const newAllocations = {
      ...plan.allocations[0],
      solo: newSoloAllocations,
      [type]: combinedContribution,
    };
    dispatch(updateAllocations(newAllocations));
    if (type in TAX_DEFERRED_INVESTMENT_TYPES) {
      dispatch(estimateCurrentPlanTaxes());
    }
    dispatch(savePlan(null));
    onSave();
  };

  const nextDisabled = formValues.contribution > maxDollars;

  return render({
    component: (
      <>
        <Global styles={catStyles} />
        <EuiFlexGroup className="ai-flex-content">
          <EuiFlexItem>
            <div className="ai-content-title">
              <EuiFlexGroup style={{ gap: 0 }}>
                <EuiFlexItem grow={false}>
                  <h1>{accountType}</h1>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    // onClick={() => toggleFlyout("life-goals")}
                    iconType="questionInCircle"
                    aria-label="Help"
                    className="help-icon"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiFlexItem>
          {!curatedIndex && (
            <EuiFlexItem grow={false} style={{ alignItems: 'flex-end' }}>
              <EuiButtonIcon
                color="text"
                iconType="cross"
                aria-label="Close"
                onClick={onClose}
                className="close-icon"
              />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>

        {formValues.who === "" && (
          <>
            <StyledSpacer size="32px" />
            <EuiFormRow
              label={
                <LabelStyle>
                  Whose contribution is this?
                </LabelStyle>
              }
            >
              <EuiSuperSelect
                options={[
                  { value: "applicant", inputDisplay: "Applicant" },
                  { value: "spouse", inputDisplay: "Spouse" },
                ]}
                valueOfSelected={formValues.who}
                onChange={setFormWho}
              />
            </EuiFormRow>
          </>
        )}
        {formValues.who !== "" && (
          <>
            <StyledSpacer size="32px" />
            <PercentBreakdown
              max={maxDollars}
              income={eligibleIncome}
              onChange={setFormValues}
              values={formValues}
              label={ASSET_TYPES[type]}
              unlimited
              incomeType="Eligible"
            />
          </>
        )}
      </>
    ),
    nextDisabled,
    nextLabel: "Save",
    nextTooltip: nextDisabled
      ? "You must enter an amount that does not exceed the maximum contribution."
      : undefined,
    onNext: save,
  });
};

export default AddOrEditIRA;
