import { map } from "lodash";

import {
  RepaymentPlan,
  REPAYMENT_PLANS,
  SPECIAL_REPAYMENTS,
  Who,
} from "./common";

const now = new Date();
const nowString = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`;
const nowStringMonthOnly = `${now.getFullYear()}-${now.getMonth()}`;

export const LIFE_EVENTS: any = {
  marriage: {
    emptyValue: {
      eventtype: "marriage",
      date: nowString,
      down: "",
      enable_fedloan: false,
      enable_privloan: false,
      spouseFedLoanBalance: "",
      spouseFedLoanPlan: "standard",
      spousePrivLoanBalance: "",
      spouseSalary: "",
      filing_jointly: true,
    },
    icon: "fb-favorite",
    buttonLabel: "Get Married",
    typeLabel: "Get Married",
    fields: [
      {
        field: "date",
        gridWidth: 12,
        label: "When do you plan on getting married?",
        width: "50%",
        type: "monthYear",
      },
      {
        field: "spouseSalary",
        label: "Future spouse's total annual income?",
        type: "dollar",
      },
      {
        field: "down",
        label: "Anticipated wedding costs?",
        type: "dollar",
      },
      {
        field: "enable_fedloan",
        label: "Does your spouse have federal student loans?",
        type: "select",
        select: [
          {
            label: "Yes",
            value: "y",
          },
          {
            label: "No",
            value: "n",
          },
        ],
      },
      {
        field: "enable_privloan",
        label: "Does your spouse have private student loans?",
        type: "select",
        select: [
          {
            label: "Yes",
            value: "y",
          },
          {
            label: "No",
            value: "n",
          },
        ],
      },
      {
        field: "spouseFedLoanPlan",
        label: "Federal student loan repayment plan",
        type: "select",
        select: map(REPAYMENT_PLANS, (label, value) => ({ label, value })),
        enabled: "enable_fedloan",
      },
      {
        field: "spouseFedLoanBalance",
        label: "Federal student loan balance",
        type: "dollar",
        enabled: "enable_fedloan",
      },
      {
        field: "spousePrivLoanBalance",
        label: "Private student loan balance",
        type: "dollar",
        enabled: "enable_privloan",
      },
      {
        field: "filing_jointly",
        label: "Expected tax filing status",
        type: "select",
        select: [
          {
            label: "Married filing jointly",
            value: true,
          },
          {
            label: "Married filing separately",
            value: false,
          },
        ],
        enabled: "spouseFedLoanPlan",
        enabled_values: SPECIAL_REPAYMENTS,
      },
    ],
  },
  house: {
    emptyValue: {
      eventtype: "house",
      date: nowString,
      down: "",
      enable_rent: "n",
      rent: "",
      cost: "",
    },
    icon: "fb-home",
    buttonLabel: "Buy a Home",
    typeLabel: "Buy a Home",
    fields: [
      {
        field: "date",
        label: "When do you plan buying a home?",
        type: "monthYear",
      },
      {
        field: "cost",
        label: "What is the estimated purchase price of the home?",
        type: "dollar",
      },
      {
        field: "down",
        label: "What do you expect to cover in cash (the down payment)?",
        type: "dollar",
      },
      {
        field: "enable_rent",
        label: "Will you sell your existing home or rent it for income?",
        type: "select",
        select: [
          {
            label: "Sell",
            value: "n",
          },
          {
            label: "Rent",
            value: "y",
          },
        ],
        enabled: "profile_homeowner",
      },
      {
        field: "rent",
        label: "What is the monthly income you will be collecting?",
        type: "dollar",
        enabled: "enable_rent",
      },
    ],
  },
  moveout: {
    emptyValue: {
      eventtype: "moveout",
      date: nowString,
      grocery: "",
      water: "",
      cable: "",
    },
    icon: "fb-user-subtract",
    buttonLabel: "Move Out w/ Parent/Relative",
    typeLabel: "Move Out of Parent/Relative's Home",
    fields: [
      {
        field: "date",
        label: "When do you plan to move out of your existing residence?",
        type: "monthYear",
      },
      {
        field: "rent",
        label: "What will your new monthly rent be?",
        type: "dollar",
      },
      {
        field: "water",
        label:
          "What do you expect your monthly utilities (water/gas/electric) to be?",
        type: "dollar",
      },
      {
        field: "cable",
        label:
          "What do you expect your monthly cable/television/streaming bill to be?",
        type: "dollar",
      },
      {
        field: "grocery",
        label: "What do you expect your monthly groceries to cost?",
        type: "dollar",
      },
    ],
  },
  movein: {
    emptyValue: {
      eventtype: "movein",
      date: nowString,
      rent: "",
    },
    icon: "fb-user-multiple",
    buttonLabel: "Move in w/ Parent/Relative",
    typeLabel: "Move in With Parent/Relative",
    fields: [
      {
        field: "date",
        label: "When do you plan to move in with your relative?",
        type: "monthYear",
      },
      {
        field: "rent",
        label:
          "If you are contributing to rent, what will be your monthly rent expense?",
        type: "dollar",
      },
    ],
  },
  property: {
    emptyValue: {
      eventtype: "property",
      date: nowString,
      cost: "",
      down: "",
    },
    icon: "fb-password",
    buttonLabel: "Buy Investment Property",
    typeLabel: "Buy Investment Property",
    fields: [
      {
        field: "date",
        label: "When do you plan on buying the property?",
        type: "monthYear",
      },
      {
        field: "cost",
        label: "What is the estimated purchase price of the property?",
        type: "dollar",
      },
      {
        field: "rent",
        label: "What is the monthly rental income you will be collecting?",
        type: "dollar",
      },
      {
        field: "down",
        label: "What do you expect to cover in cash (the down payment)?",
        type: "dollar",
      },
    ],
  },
  child: {
    emptyValue: {
      eventtype: "child",
      date: nowString,
      enable_reduce: "y",
      reduce: null,
      salary: "",
    },
    icon: "fb-pedestrian-child",
    buttonLabel: "Have a Child",
    typeLabel: "Have a Child",
    fields: [
      {
        field: "date",
        label: "When do you plan on having a child?",
        type: "monthYear",
      },
      {
        field: "enable_reduce",
        label: "Do you or your spouse plan on reducing your hours?",
        type: "select",
        select: [
          {
            label: "Yes",
            value: "y",
          },
          {
            label: "No",
            value: "n",
          },
        ],
      },
      {
        field: "reduce",
        label: "Who is going to reduce their work hours?",
        type: "select",
        select: [
          {
            label: "Me",
            value: "applicant",
          },
          {
            label: "My spouse",
            value: "spouse",
          },
        ],
        enabled: "enable_reduce",
      },
      {
        field: "salary",
        label: "What will be their new total annual income?",
        type: "dollar",
        enabled: "enable_reduce",
        whoText: "reduce",
      },
    ],
  },
  vehicle: {
    emptyValue: {
      eventtype: "vehicle",
      date: nowString,
      down: "",
      cost: "",
      type: "add",
    },
    icon: "fb-car",
    buttonLabel: "Purchase a Vehicle",
    typeLabel: "Purchase a Vehicle",
    fields: [
      {
        field: "type",
        label: "Are you adding a new vehicle or replacing an old one?",
        type: "select",
        select: [
          {
            label: "Adding",
            value: "add",
          },
          {
            label: "Replacing",
            value: "replace",
          },
        ],
      },
      {
        field: "date",
        label: "When do you plan on buying a vehicle?",
        type: "monthYear",
      },
      {
        field: "cost",
        label:
          "What is the estimated cost of the vehicle you are thinking of buying?",
        type: "dollar",
      },
      {
        field: "down",
        label: "What do you expect to cover in cash (the down payment)?",
        type: "dollar",
      },
    ],
  },
  move: {
    emptyValue: {
      eventtype: "move",
      date: nowString,
      zip: "90210",
    },
    icon: "fb-model-alt",
    buttonLabel: "Move",
    typeLabel: "Move to a Different Area",
    fields: [
      {
        field: "date",
        label: "When do you plan on moving?",
        type: "monthYear",
      },
      {
        field: "zip",
        label: "What ZIP code do you plan on moving to?",
        type: "string",
      },
    ],
  },
  reducejob: {
    emptyValue: {
      eventtype: "reducejob",
      date: nowString,
      who: "applicant",
      salary: "",
    },
    icon: "fb-analytics-alt",
    buttonLabel: "Reduce Work",
    typeLabel: "Reduce Work to Part-time/Stay At Home",
    fields: [
      {
        field: "who",
        label: "Who is going to reduce their work hours?",
        type: "select",
        select: [
          {
            label: "Me",
            value: "applicant",
          },
          {
            label: "My spouse",
            value: "spouse",
          },
        ],
        enabled: "profile_married",
      },
      {
        field: "date",
        label: "When does your spouse plan on reducing their work hours?",
        type: "monthYear",
        whoText: true,
      },
      {
        field: "salary",
        label: "How much will their new total annual income be?",
        type: "dollar",
        whoText: true,
      },
    ],
  },
  partjob: {
    emptyValue: {
      eventtype: "partjob",
      date: nowString,
      who: "applicant",
      salary: "",
    },
    icon: "fb-battery-half",
    buttonLabel: "Begin Part-time Work",
    typeLabel: "Begin Part-time Work",
    fields: [
      {
        field: "who",
        label: "Who is going to change their work hours?",
        type: "select",
        select: [
          {
            label: "Me",
            value: "applicant",
          },
          {
            label: "My spouse",
            value: "spouse",
          },
        ],
        enabled: "profile_married",
      },
      {
        field: "date",
        label: "When does your spouse plan on increasing their work hours?",
        type: "monthYear",
        whoText: true,
      },
      {
        field: "salary",
        label:
          "How much annual income do they expect from their part-time job?",
        type: "dollar",
        whoText: true,
      },
    ],
  },
  enlargejob: {
    emptyValue: {
      eventtype: "enlargejob",
      date: nowString,
      who: "applicant",
      salary: "",
    },
    icon: "fb-battery-full",
    buttonLabel: "Begin Full-time Work",
    typeLabel: "Begin Full-time Work",
    fields: [
      {
        field: "who",
        label: "Who is going to increase their work hours?",
        type: "select",
        select: [
          {
            label: "Me",
            value: "applicant",
          },
          {
            label: "My spouse",
            value: "spouse",
          },
        ],
        enabled: "profile_married",
      },
      {
        field: "date",
        label: "When does your spouse plan on increasing their work hours?",
        type: "monthYear",
        whoText: true,
      },
      {
        field: "salary",
        label: "How much will their new total annual income be?",
        type: "dollar",
        whoText: true,
      },
    ],
  },
  inherit: {
    emptyValue: {
      eventtype: "inherit",
      date: nowString,
      amount: "",
    },
    icon: "fb-piggy-bank",
    buttonLabel: "Receive Inheritance",
    typeLabel: "Receive Inheritance",
    goalLabel: "Receive inheritance",
    fields: [
      {
        field: "date",
        label: "When do you plan on receiving the Inheritance?",
        type: "monthYear",
      },
      {
        field: "amount",
        label: "What is the estimated amount of the inheritance?",
        type: "dollar",
      },
    ],
  },
  vacation: {
    emptyValue: {
      eventtype: "vacation",
      date: nowStringMonthOnly,
      down: 0,
      recurring: true,
    },
    icon: "fb-retire",
    buttonLabel: "Go on Vacation",
    typeLabel: "Go on Vacation",
    fields: [
      {
        field: "date",
        label: "When would you like to save this amount by?",
        type: "monthYear",
      },
      {
        field: "down",
        label: "What is the estimated cost of the vacation?",
        type: "dollar",
      },
      {
        field: "recurring",
        label: "Do you want to save this amount for a vacation each year?",
        type: "select",
        select: [
          {
            label: "No",
            value: "once",
          },
          {
            label: "Yes",
            value: "annually",
          },
        ],
      },
    ],
  },
};

export const GRADUATED_LIFE_EVENTS: any = {
  marriage: {
    emptyValue: {
      eventtype: "marriage",
      date: nowString,
      down: "",
      enable_fedloan: false,
      enable_privloan: false,
      spouseFedLoanBalance: "",
      spouseFedLoanPlan: "standard",
      spousePrivLoanBalance: "",
      spouseSalary: "",
      filing_jointly: true,
    },
    icon: "fb-marriage",
    buttonLabel: "Getting Married",
    savingGoalLabel: "Save for wedding",
    goalLabel: "Have wedding",
    typeLabel: "Getting Married",
    help: [
      {
        header: "Marriage Details Are Important!",
        body: [
          "Marriage significantly impacts your financial plan. It affects your plan in two primary ways:",
          "1) You now have a short-term savings goal for wedding costs.  We see a lot of young couples get in credit card trouble because of their wedding. Don't let this happen to you.",
          "2) Your spouse's income and student loan debt levels can greatly influence what you do with your student loan repayment strategy.",
        ],
      },
    ],
    fields: [
      {
        field: "date",
        gridWidth: 12,
        label: "When do you plan on getting married?",
        info:
          "Setting your wedding date & anticipated costs adds a short-term savings goal to your financial plan automatically.",
        width: "50%",
        type: "monthYear",
      },
      {
        field: "spouseSalary",
        label: "What is your future spouse’s total annual income?",
        type: "dollar",
      },
      {
        field: "down",
        label: "What are your anticipated wedding costs?",
        helpText: "Only include the cost that you have to pay for your wedding.",
        type: "dollar",
      },
      {
        field: "enable_fedloan",
        label: "Does your spouse have federal student loans?",
        type: "radio",
        select: [
          {
            label: "Yes",
            value: "y",
          },
          {
            label: "No",
            value: "n",
          },
        ],
      },
      {
        field: "enable_privloan",
        label: "Does your spouse have private student loans?",
        type: "radio",
        select: [
          {
            label: "Yes",
            value: "y",
          },
          {
            label: "No",
            value: "n",
          },
        ],
      },
      {
        field: "spouseFedLoanPlan",
        label: "Federal student loan repayment plan",
        type: "select",
        select: map(REPAYMENT_PLANS, (label, value) => ({ label, value })),
        enabled: "enable_fedloan",
      },
      {
        field: "spouseFedLoanBalance",
        label: "What is their federal student loan balance?",
        type: "dollar",
        enabled: "enable_fedloan",
      },
      {
        field: "spousePrivLoanBalance",
        label: "What is their private student loan balance?",
        type: "dollar",
        enabled: "enable_privloan",
      },
      {
        field: "filing_jointly",
        label: "Expected tax filing status",
        type: "select",
        select: [
          {
            label: "Married filing jointly",
            value: true,
          },
          {
            label: "Married filing separately",
            value: false,
          },
        ],
        enabled: "spouseFedLoanPlan",
        enabled_values: SPECIAL_REPAYMENTS,
      },
    ],
  },
  house: {
    emptyValue: {
      eventtype: "house",
      date: nowString,
      down: "",
      enable_rent: "n",
      rent: "",
      cost: "",
    },
    help: [
      {
        header: "Homes & Your FitBUX Score",
        body: [
          "Buying a home impacts your financial profile because you are increasing your overall debt, i.e. your financial risk increases.  However, this risk maybe offset in the near term due to how much you save in rent.  Also, it may be worth the extra risk in the short-run in order to have no housing payment when you retire.",
          "Be sure to look at your FitBUX Score when you simulate your plan to analyze the short-term and long-term impact of buying a house.",
        ],
      },
      {
        header: "Compare Rent Vs. Buy",
        body: [
          "Did you know you can compare renting a house vs buying a house using our financial plan builder?",
          "Build one plan whereby you are buying a house.  Then build a second plan whereby you are renting.",
          "Then easily compare the short-term vs long-term impact of renting vs buying by simply comparing the difference in FitBUX Scores!",
        ],
      },
    ],
    icon: "fb-house",
    buttonLabel: "Buying a Home",
    savingGoalLabel: "Save for house down payment",
    payoffGoalLabel: "Pay off future house",
    goalLabel: "Buy a home",
    typeLabel: "Buying a Home",
    fields: [
      {
        field: "date",
        label: "When do you plan buying a home?",
        info:
          "Setting a buy date & down payment amount establishes a short-term savings goal in your financial plan automatically.",
        type: "monthYear",
      },
      {
        field: "cost",
        label: "What is the estimated purchase price of the home?",
        type: "dollar",
      },
      {
        field: "down",
        label: "What do you expect to cover in cash (the down payment)?",
        type: "dollar",
      },
      {
        field: "enable_rent",
        label: "Will you sell your existing home or rent it for income?",
        type: "select",
        select: [
          {
            label: "Sell",
            value: "n",
          },
          {
            label: "Rent",
            value: "y",
          },
        ],
        enabled: "profile_homeowner",
      },
      {
        field: "rent",
        label: "What is the monthly income you will be collecting?",
        type: "dollar",
        enabled: "enable_rent",
      },
    ],
  },
  moveout: {
    emptyValue: {
      eventtype: "moveout",
      date: nowString,
      grocery: "",
      water: "",
      cable: "",
    },
    icon: "fb-house-leave",
    buttonLabel: "Moving Out of Parent/Relative",
    typeLabel: "Moving Out of Parent/Relative's Home",
    goalLabel: "Move out of parent/relative's home",
    help: [
      {
        header: "Compare Your Options",
        body: [
          "Did you know you can use our technology to compare items such as:",
          "1) the difference between moving out in one year vs. two",
          "2) comparing two potential rentals.",
          "To do so, create one plan and then copy and edit the second plan.  For example, in plan 1 enter that you are moving out in one year. In plan 2 enter that you are moving out in two years.  From there you can see the long-run impact on your savings as well as paying off debt.",
        ],
      },
    ],
    fields: [
      {
        field: "date",
        label: "When do you plan to move out?",
        type: "monthYear",
      },
      {
        field: "rent",
        label: "What do you expect your new monthly rent to be?",
        type: "dollar",
      },
      {
        field: "water",
        label:
          "What do you expect your monthly utilities (water/gas/electric) to be?",
        type: "dollar",
      },
      {
        field: "cable",
        label: "How much do you expect to spend on cable/TV/streaming monthly?",
        type: "dollar",
      },
      {
        field: "grocery",
        label: "How much do you expect to spend on groceries monthly?",
        type: "dollar",
      },
    ],
  },
  movein: {
    emptyValue: {
      eventtype: "movein",
      date: nowString,
      rent: "",
    },
    icon: "fb-house-return",
    buttonLabel: "Moving In w/ Parent/Relative",
    typeLabel: "Moving In w/ Parent/Relative",
    goalLabel: "Move in with parent/relative",
    help: [
      {
        header: "Compare Your Options",
        body: [
          "Did you know you can use our technology to compare items such as moving in with your parents vs. continuing to rent.",
          "To do so, create your first plan and then copy and edit the first plan to create your second plan. For example, in “Plan 1”, enter that you are moving in with your parents. Then add a “Move Out” life event a few years later.",
          "In your second plan, do not include the “Move In” or “Move Out” life events and assume that you are still renting or living in your existing place.",
          "From there you can see the long-run impact on your savings as well as paying off debt.",
        ],
      },
    ],
    fields: [
      {
        field: "date",
        label: "When do you plan to move in with your relative?",
        type: "monthYear",
      },
      {
        field: "rent",
        label:
          'How much do you expect to pay for "rent" monthly, if applicable?',
        type: "dollar",
      },
    ],
  },
  property: {
    emptyValue: {
      eventtype: "property",
      date: nowString,
      cost: "",
    },
    help: [
      {
        header: "Endless Simulations!",
        body: [
          "You can use this life event to run a number of simulations and analyze the impacts on your future finances.",
          "For example, you can:",
          "1) Compare investing in properties vs investing in the stock market,",
          "2) Set savings goals for a down payment for an investment property,",
          "3) Simulate buying multiple investment properties, and",
          "4) Simulate that you'll live in the property but also collect monthly rent!",
        ],
      },
    ],
    icon: "fb-house-buying",
    buttonLabel: "Buying an Investment Property",
    goalLabel: "Buy investment property",
    savingGoalLabel: "Save for investment property down payment",
    payoffGoalLabel: "Pay off future investment property",
    typeLabel: "Buying an Investment Property",
    fields: [
      {
        field: "date",
        label: "When do you plan on buying the property?",
        type: "monthYear",
      },
      {
        field: "cost",
        label: "What's the estimated purchase price?",
        type: "dollar",
      },
      {
        field: "down",
        label: "What do you expect to cover in cash (the down payment)?",
        type: "dollar",
      },
      {
        field: "rent",
        label: "What's the expected gross monthly rental income?",
        type: "dollar",
      },
    ],
  },
  child: {
    emptyValue: {
      eventtype: "child",
      date: nowString,
      enable_reduce: "n",
      reduce: null,
      salary: "",
    },
    help: [
      {
        header: "Family Planning Made Easy!",
        body: [
          "You can use this life event for multiple purposes.  Our technology automatically adjust living expenses, investment contributions, and debt payment as your family grows.",
          "You can also compare the overall impact of staying at home vs continuing to work once your children are born.  Simply, build two plans. One showing you are going to reduce your work, the other whereby you stay working. Then compare the results!",
        ],
      },
    ],
    icon: "fb-baby",
    buttonLabel: "Having a Child",
    typeLabel: "Children",
    goalLabel: "Have a child",
    fields: [
      {
        field: "date",
        label: "When do you plan on having a child?",
        type: "monthYear",
      },
      {
        field: "enable_reduce",
        label: "Do you or your spouse plan on reducing your work hours?",
        type: "select",
        select: [
          {
            label: "Yes",
            value: "y",
          },
          {
            label: "No",
            value: "n",
          },
        ],
      },
      {
        field: "reduce",
        label: "Who is going to reduce their work hours?",
        type: "select",
        select: [
          {
            label: "Me",
            value: "applicant",
          },
          {
            label: "My spouse",
            value: "spouse",
          },
        ],
        enabled: "enable_reduce",
      },
      {
        field: "salary",
        label: "What will be their new total annual income?",
        type: "dollar",
        enabled: "enable_reduce",
        whoText: "reduce",
      },
    ],
  },
  vehicle: {
    emptyValue: {
      eventtype: "vehicle",
      date: nowString,
      down: "",
      cost: "",
      type: "add",
    },
    help: [
      {
        header: "A Car Loan Tip For You To Use",
        body: [
          "Many people purchase a car using a five year car loan.  However, using a seven year loan will give you a lower required payment.  Question is: Should you use a longer term loan?  The answer is: It depends.",
          "The advantage of the longer term loan and lower required payment allows you to use your money elsewhere in your financial plan such as paying off higher interest rate debt (i.e. student loans, credit cards, etc.)",
          "The disadvantage is that the longer term loan will have a higher interest rate.",
          "Therefore, you have to look at the difference in interest rates and see which loan will allow you to complement your life instead of dictate it.",
          "If you are ready to buy a car and need help, you can use our technology or reach out to a FitBUX Coach to assist you.",
        ],
      },
    ],
    icon: "fb-vehicle",
    buttonLabel: "Purchasing a Vehicle",
    savingGoalLabel: "Save for vehicle down payment",
    payoffGoalLabel: "Pay off future vehicle",
    typeLabel: "Purchasing a Vehicle",
    goalLabel: "Purchase a vehicle",
    fields: [
      {
        field: "type",
        label: "Are you adding or replacing a vehicle?",
        type: "select",
        info:
          "If you are replacing a car and have two cars on your profile, we assume you'll be replacing the one that has the lowest value.",
        select: [
          {
            label: "Adding",
            value: "add",
          },
          {
            label: "Replacing",
            value: "replace",
          },
        ],
      },
      {
        field: "date",
        label: "When do you plan on buying a vehicle?",
        type: "monthYear",
      },
      {
        field: "cost",
        label:
          "What is the estimated cost of the vehicle you are thinking of buying?",
        type: "dollar",
      },
      {
        field: "down",
        label: "What's the estimated down payment?",
        type: "dollar",
      },
    ],
  },
  move: {
    emptyValue: {
      eventtype: "move",
      date: nowString,
      zip: "90210",
    },
    help: [
      {
        header: "Moving's Huge Impact",
        body: [
          "Living in different locations will greatly influence your income, your day-to-day living expenses, and other life events such as buying a house since home affordability varies in each location.",
          "Also, a potential future move may have an immediate impact on your home-buying decision. For example, if you plan on moving in five years, you most likely would not want to buy a home where you currently live because the value of this home may drop significantly in that short timeline.",
        ],
      },
      {
        header: "Thinking Of Moving?",
        body: [
          "You can use this life event if you are thinking of moving and compare the long term impact.",
          "For example, if you live in Austin and have a job offer in San Jose, CA, you can build a plan that has you staying in Texas and a second one with you moving to San Jose.",
          "Our technology will automatically adjust your expenses so you can make an apples-to-apples comparison on the long-term impact of living in each location.",
        ],
      },
    ],
    icon: "fb-moving",
    buttonLabel: "Moving",
    typeLabel: "Moving To A Different Area",
    goalLabel: "Move to a different area",
    fields: [
      {
        field: "date",
        label: "When do you plan on moving?",
        type: "monthYear",
      },
      {
        field: "zip",
        label: "What ZIP code do you plan on moving to?",
        type: "string",
        helpText:
          "Your ZIP code can influence your income and your cost of living. When you add a moving life event to your plan, we will automatically adjust these items for you.",
      },
    ],
  },
  reducejob: {
    emptyValue: {
      eventtype: "reducejob",
      date: nowString,
      who: "applicant",
      salary: "",
    },
    help: [
      {
        header: "To Work Or Not To Work?",
        body: [
          "Thinking about reducing your hours or not working at all?  This life event allows you to see the impact it will have on your finances. FitBUX will automatically reduce items such as day care expenses to reflect you being at home instead of working.",
        ],
      },
    ],
    icon: "fb-reduce-work",
    buttonLabel: "Expected Decrease in Income",
    typeLabel: "Reducing Work To Part-Time/Staying At Home",
    goalLabel: "Expected decrease in income",
    fields: [
      {
        field: "who",
        label: "Who is going to reduce their work hours?",
        type: "select",
        select: [
          {
            label: "Me",
            value: "applicant",
          },
          {
            label: "My spouse",
            value: "spouse",
          },
        ],
        enabled: "profile_married",
      },
      {
        field: "date",
        label: "When does your spouse plan on reducing their work hours?",
        type: "monthYear",
        whoText: true,
      },
      {
        field: "salary",
        label: "What do you expect your new total income to be?",
        type: "dollar",
        whoText: true,
      },
    ],
  },
  partjob: {
    emptyValue: {
      eventtype: "partjob",
      date: nowString,
      who: "applicant",
      salary: "",
    },
    help: [
      {
        header: "Does The Extra $$$ Impact You?",
        body: [
          "Add this life event for two reasons:",
          "1) You are going to start a part-time job and you want to allocate the money towards your goals.  You are going to start a part-time job and you want to allocate the money towards your goals. If you do this, be sure to add another life event in the future if you plan on dropping your income again (i.e., you only plan on working an extra job for a few years).",
          "2) You want to see if working extra shifts is worth it in the long-run vs not working extra shifts.  To do this, build two plans.  One working part-time, the other not.",
        ],
      },
    ],
    icon: "fb-part-time",
    buttonLabel: "Beginning Part-time Work",
    typeLabel: "Beginning Part-time Work",
    goalLabel: "Begin part-time work",
    fields: [
      {
        field: "who",
        label: "Who is going to change their work hours?",
        type: "select",
        select: [
          {
            label: "Me",
            value: "applicant",
          },
          {
            label: "My spouse",
            value: "spouse",
          },
        ],
        enabled: "profile_married",
      },
      {
        field: "date",
        label: "When does your spouse plan on increasing their work hours?",
        type: "monthYear",
        whoText: true,
      },
      {
        field: "salary",
        label:
          "How much annual income do they expect from their part-time job?",
        type: "dollar",
        info:
          "This will be added as a supplemental income source within your plan. If you are going from full-time work to part-time, choose the life event 'Reduce Work' instead.",
        whoText: true,
      },
    ],
  },
  enlargejob: {
    emptyValue: {
      eventtype: "enlargejob",
      date: nowString,
      who: "applicant",
      salary: "",
    },
    icon: "fb-full-time",
    buttonLabel: "Expected Increase in Income",
    typeLabel: "Beginning Full-time Work",
    goalLabel: "Expected increase in income",
    fields: [
      {
        field: "who",
        label: "Who is going to increase their work hours?",
        type: "select",
        select: [
          {
            label: "Me",
            value: "applicant",
          },
          {
            label: "My spouse",
            value: "spouse",
          },
        ],
        enabled: "profile_married",
      },
      {
        field: "date",
        label: "When does your spouse plan on increasing their work hours?",
        type: "monthYear",
        whoText: true,
      },
      {
        field: "salary",
        label: "How much will their new total annual income be?",
        type: "dollar",
        whoText: true,
      },
    ],
  },
  inherit: {
    emptyValue: {
      eventtype: "inherit",
      date: nowString,
      amount: "",
    },
    icon: "fb-inheritance",
    buttonLabel: "Receiving an Inheritance",
    typeLabel: "Receiving an Inheritance",
    goalLabel: "Receive inheritance",
    fields: [
      {
        field: "date",
        label: "When do you plan on receiving the Inheritance?",
        type: "monthYear",
      },
      {
        field: "amount",
        label: "What is the estimated amount of the inheritance?",
        type: "dollar",
      },
    ],
  },
  savings: {
    emptyValue: {
      eventtype: "savings",
      name: "",
      date: nowString,
      down: "",
      spending: "expense",
    },
    // disabled: true,
    icon: "fb-other-savings-goal",
    buttonLabel: "Saving for Other Goal",
    typeLabel: "Saving for Other Goal",
    goalLabel: "Saving for other goal",
    fields: [
      {
        field: "name",
        label: "What are you saving for (Name of your goal)?",
        type: "string",
      },
      {
        field: "date",
        label: "When would you like to save this amount by?",
        type: "monthYear",
      },
      {
        field: "down",
        label: "How much are you planning to save?",
        type: "dollar",
      },
      {
        field: "spending",
        label:
          "On the above date, will you be spending the money or acquiring an asset?",
        type: "select",
        info:
          '"Spending" means you will be saving  up now for a future expenditure, such as saving for fertility treatments or a vacation. “Acquiring an asset” means you are saving now to acquire an asset in the future, such as saving to purchase an engagement ring.  This selection will affect your financial projections when simulating your plan.',
        select: [
          {
            label: "Spending",
            value: "expense",
          },
          {
            label: "Acquiring an asset",
            value: "asset",
          },
        ],
      },
    ],
  },
  retirement: {
    emptyValue: {
      // eventtype: "inherit",
      // date: nowString,
      // amount: "",
    },
    disabled: true,
    toolTip:
      "Investing for Retirement goals will be included in your financial plan as part of Step 3 'Money For Future Goals.'",
    icon: "fb-retire",
    buttonLabel: "Investing for Retirement",
    typeLabel: "Investing for Retirement",
    goalLabel: "Investing for Retirement",
    fields: [],
  },
  debtRepayment: {
    emptyValue: {
      // eventtype: "inherit",
      // date: nowString,
      // amount: "",
    },
    disabled: true,
    toolTip:
      "Debt repayment goals will be included in your financial plan as part of Step 3 'Money For Future Goals.'",
    icon: "fb-debt-repay",
    buttonLabel: "Repaying Debt",
    typeLabel: "Repaying Debt",
    fields: [],
  },
  saveEmergencyFund: {
    emptyValue: {
      // eventtype: "inherit",
      // date: nowString,
      // amount: "",
    },
    disabled: true,
    toolTip:
      "Saving for an emergency fund is included in your financial plan as part of Step 5 'Additional Questions.'",
    icon: "fb-piggy-bank",
    buttonLabel: "Saving for an Emergency Fund",
    typeLabel: "Saving for an Emergency Fund",
    fields: [],
  },
  vacation: {
    emptyValue: {
      eventtype: "vacation",
      date: nowStringMonthOnly,
      down: 0,
      recurring: true,
    },
    icon: "fb-retire",
    buttonLabel: "Go on Vacation",
    savingGoalLabel: "Save for vacation",
    goalLabel: "Go on vacation",
    typeLabel: "Go on Vacation",
    fields: [
      {
        field: "date",
        label: "When would you like to save this amount by?",
        type: "monthYear",
      },
      {
        field: "down",
        label: "What is the estimated cost of the vacation?",
        type: "dollar",
      },
      {
        field: "recurring",
        label: "Do you want to save this amount for a vacation each year?",
        type: "select",
        select: [
          {
            label: "No",
            value: "once",
          },
          {
            label: "Yes",
            value: "annually",
          },
        ],
      },
    ],
  },
};

export const RETIREMENT_DEFINITION = {
  icon: "fb-activity",
  typeLabel: "Retirement",
  fields: [
    {
      field: "age",
      label: "At what age does your spouse plan to retire?",
      type: "age",
      whoText: "who",
    },
  ],
};

export const GRADUATION_DEFINITION = {
  icon: "fb-user-education",
  typeLabel: "Graduation",
  fields: [
    {
      field: "date",
      label: "When does your spouse expect to graduate?",
      type: "monthYear",
      whoText: "who",
    },
  ],
};

export const ALL_LIFE_EVENTS = {
  ...LIFE_EVENTS,
  retire: RETIREMENT_DEFINITION,
  graduate: GRADUATION_DEFINITION,
};

export const ALL_GRADUATED_LIFE_EVENTS = {
  ...GRADUATED_LIFE_EVENTS,
  retire: RETIREMENT_DEFINITION,
  graduate: GRADUATION_DEFINITION,
};

export const ShortTermGoals = {
  marriage: "Save for a wedding",
  house: "Save to purchase a house",
  vehicle: "Save to purchase an investment property",
  property: "Save to purchase a vehicle",
  savings: "Save to achieve your other savings goal",
};

export const GOALS = {
  graduate: "Graduate",
  child: "Have a child",
  house: "Buy a house",
  marriage: "Get married",
  mortgage: "Pay off mortgage",
  student_loan: "Pay off student loan",
  idr: "IDR Forgiveness",
  credit_card: "Pay off credit card",
  all: "Pay off all debts",
  vehicle: "Buy a car",
  property: "Buy investment property",
  move: "Move cities",
  movein: "Move in with family",
  moveout: "Move out of family",
  job: "Change job",
  enlargejob: "Change job",
  jobupdate: "Change job",
  inherit: "Inheritance",
  emptynest: "Children move out",
  auto_loan: "Pay off auto loan",
  emergencyfund: "Save for emergency fund",
  "savings-house": "Save for down payment on home",
  "savings-vehicle": "Save for down payment on car",
  "savings-property": "Save down pmt on inv. prop",
  "savings-marriage": "Save for wedding expenses",
  "savings-other": "Save for investment",
  repayment: "Begin student loan repayment",
  fed_loan_fund: "Federal loan disbursement",
  priv_loan_fund: "Private loan disbursement",
  parent_loan_fund: "Parent Plus loan disbursement",
  funding_fund: "New loan opened",
  savings: "Other savings goal",
};

export type LifeEventType = keyof typeof ALL_LIFE_EVENTS;
export type JobType = "reducejob" | "partjob" | "enlargejob";

export interface LifeEvent {
  id?: number;
  eventtype: LifeEventType | JobType;
  date: string;
}

export interface Graduation extends LifeEvent {
  profession: string;
  salary: number;
  who: string;
}

export interface Retirement extends LifeEvent {
  eventtype: "retire";
  who: string;
}

export interface Marriage extends LifeEvent {
  eventtype: "marriage";
  down: number;
  spouseFedLoanBalance: number;
  spouseFedLoanPlan: RepaymentPlan;
  spousePrivLoanBalance: number;
  spouseSalary: number;
}

export interface Renting extends LifeEvent {
  eventtype: "movein" | "house";
  rent: number;
}

export interface Property extends LifeEvent {
  eventtype: "property" | "house" | "vehicle";
  cost: number;
}

export interface House extends Renting, Property {
  eventtype: "house";
  down: number;
}

export interface MoveOut extends LifeEvent {
  eventtype: "moveout";
  grocery: number;
  water: number;
  cable: number;
}

export interface Child extends LifeEvent {
  eventtype: "child";
  reduce: Who;
  salary: number;
}

export interface Auto extends Property {
  eventtype: "vehicle";
  down: number;
  add: "add" | "replace";
}

export interface Move extends LifeEvent {
  eventtype: "move";
  zip: string;
}

export interface JobChange extends LifeEvent {
  eventtype: JobType;
  who: Who;
  salary: number;
}

export interface Inheritance extends LifeEvent {
  eventtype: "inherit";
  amount: number;
}
