import React, { useState } from "react";
import { MappedCashflow } from "src/interfaces";
import { formatWholeDollars } from "src/utils";
import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from "@elastic/eui";
import {
  StyledEuiButton,
  StyledSpacer,
} from "src/components/Global/StyledComponents";
import { Global, css } from "@emotion/react";
import { useFlyout } from "src/components/Global/FlyoutContext";
import AddManualAccountForm from "src/pages/ProfileBuilder/Views/AssetsAndDebts/AddManualAccountForm";
import { MappedAccount } from "src/interfaces";

const BudgetCardStyles = css`
  .euiTableFooter {
    font-weight: 600;
    background-color: #f1f4fa;
  }
`;

interface BudgetCardProps {
  buttonLabel?: string;
  actual?: { [typeId: string]: number };
  budget: MappedCashflow[];
  editing?: boolean;
  onAdd: () => void;
  onSave: (
    changes: { [stringId: string]: number },
    deletedIds: Set<number>,
    newValues: { [type: string]: number }
  ) => void;
  annual?: boolean;
  showTax?: boolean;
  taxBudget?: number;
  openTransactionsDialog?: (key: string) => void;
  addOwner?: boolean;
  dateRange: string;
}

const BudgetCard = ({
  buttonLabel,
  budget,
  actual,
  onAdd,
  annual,
  dateRange,
}: BudgetCardProps) => {
  const { openCustomFlyout } = useFlyout();
  const [deletedIds, setDeletedIds] = useState<Set<number>>(new Set());

  const handleEditClick = (account: MappedAccount) => {
    openCustomFlyout({
      title: "Edit account",
      component: AddManualAccountForm,
      selected: account,
    });
  };

  const handleDeleteClick = (id: number) => {
    setDeletedIds((prev) => new Set(prev).add(id));
  };

  const columns = [
    {
      field: "typeLabel",
      name: "Type",
      width: "40%",
      sortable: false,
    },
    {
      field: "actual",
      name: "Actual",
      width: "20%",
      render: (actual: number | null) =>
        actual !== null ? formatWholeDollars(actual) : "N/A",
      sortable: false,
    },
    {
      field: "budgeted",
      name: "Budgeted",
      width: "20%",
      render: (budgeted: number | null) =>
        budgeted !== null ? formatWholeDollars(budgeted) : "N/A",
      sortable: false,
    },
    {
      name: "Actions",
      width: "20%",
      actions: [
        {
          name: "Edit",
          description: "Edit this item",
          type: "icon",
          icon: "pencil",
          onClick: (account: any) => handleEditClick(account),
        },
        {
          name: "Delete",
          description: "Delete this item",
          type: "icon",
          icon: "trash",
          onClick: (item: any) => handleDeleteClick(item.id),
        },
      ],
      field: "",
      sortable: false,
    },
  ];

  const items = budget
    .filter((item) => !deletedIds.has(item.id))
    .map((item) => ({
      ...item,
      actual: actual?.[item.type as string] ?? 0,
      budgeted: annual ? item.annual ?? 0 : item.monthly ?? 0,
    }));

  const totalActual = items.reduce((sum, item) => sum + (item.actual || 0), 0);
  const totalBudgeted = items.reduce(
    (sum, item) => sum + (item.budgeted || 0),
    0
  );

  const footerItems = [
    {
      typeLabel: "Total",
      actual: totalActual,
      budgeted: totalBudgeted,
    },
  ];

  return (
    <div>
      <Global styles={BudgetCardStyles} />
      <StyledSpacer size="16px" />
      <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
        <EuiFlexItem grow={false}>
          <h3>Date Range: {dateRange}</h3>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <StyledEuiButton
            style={{ color: "#343741" }}
            fill
            color="text"
            iconType="plus"
            onClick={onAdd}
          >
            {buttonLabel}
          </StyledEuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiBasicTable
        className="table-border"
        items={[...items, ...footerItems]}
        columns={columns}
        rowProps={(item) => ({
          className: item.typeLabel === "Total" ? "euiTableFooter" : "",
        })}
        cellProps={(item, column) => {
          const isFieldColumn = (column as any).field !== undefined;
          const fieldName = isFieldColumn ? (column as any).field : "";
          return {
            textAlign: ["actual", "budgeted"].includes(fieldName)
              ? "right"
              : "left",
          };
        }}
      />
    </div>
  );
};

export default BudgetCard;
