import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  EuiButtonIcon,
  EuiCallOut,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiRadioGroup,
  EuiText,
  EuiSuperSelect,
} from "@elastic/eui";

import { MAX_401K_ANNUAL_CONTRIBUTION } from "src/constants";
import {
  savePlan,
  updateCurrentPlan,
  estimateCurrentPlanTaxes,
} from "src/store/planBuild/actions";
import { EMPTY_RETIREMENT } from "src/store/planBuild/constants";
import {
  currentPlanIncomeTotal,
  getCurrentPlan,
  getMy401kEligibleIncome,
  getSpouse401kEligibleIncome,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { Plan } from "src/interfaces/plan.interface";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import { SidebarContent } from "src/interfaces";
import {
  formatAnnually,
  formatMonthly,
  formatPercent,
  formatWholeDollars,
  PercentTextField,
} from "src/utils";
import { useStyles } from "./styles";
import { CURATED_PLAN_BUILD_STEPS } from "./common";
import { LabelAsterisk, LabelStyle, StyledSpacer } from "src/components/Global/StyledComponents";
import { YES_NO_OPTIONS, generateOptions } from "src/constants/formOptions";
import { Global, css } from "@emotion/react";

const catStyles = css`
  .close-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

type AccountTypes = "401k_value" | "roth_401k_value";

const employerBlowout: SidebarContent[] = [
  {
    header: "Retirement Savings Matching",
    body: [
      "FitBUX highly recommends taking advantage of your employer retirement match, if available.",
      "Employer matching is a contribution amount your employer makes to your retirement.",
      "For example, if you make $70,000 per year and your employer has a 3% match, if you contribute $2,100 per year to your retirement, your employer will also contribute $2,100.",
      "In short, your match is 'free' money.  That is why we recommend contributing at least the minimum amount to your retirement to trigger any company match available to you.",
    ],
  },
  {
    header: "Eligible Accounts",
    body: [
      "Below is a list of accounts and which type of employees qualify for them:",
      "1) 401k: Employees of for profit companies",
      "2) 403b: Employees of non-profit companies",
      "3) 457b: Employees of state and local governments",
      "4) TSP: Employees of the Federal government",
    ],
  },
  {
    header: "Should I do a Roth 401k?",
    body: [
      "We recommend that  you save both in a 401k and Roth 401k, if offered as they each provide different benefits. ",
    ],
  },
];

const AddOrEdit401k: PlanViewComponent = ({
  initialValues,
  onClose,
  onSave,
  render,
  // type,
  curatedIndex,
}) => {
  const curatedFlow =
    curatedIndex === CURATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT;
  const dispatch = useDispatch();
  const styles = useStyles();
  const plan: Plan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const myEligibleIncome = useSelector(getMy401kEligibleIncome);
  const spouseEligibleIncome = useSelector(getSpouse401kEligibleIncome);
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const initialFormValues: any = {
    additional_match: "n",
    additional_limit: "",
    additional_percent: 0,
    match_limit: "",
    match_percent: 0,
    trad_contribution: "",
    who: !isMarried || curatedFlow ? "applicant" : "",
    has_401k: curatedFlow ? "n" : "y",
    has_roth_401k: "n",
    roth_401k_contribution: "",
    auto_question: "n",
    auto_match_percent: "",
  };

  if (initialValues?.who && isMarried) {
    initialFormValues.who = initialValues.who;
  }
  const itemIndex = isMarried && initialValues?.who === "spouse" ? 1 : 0;
  const retirement = plan?.retirement?.find(
    (r) => r.who === initialValues?.who
  ) || { ...EMPTY_RETIREMENT };
  const soloAllocation = plan?.allocations[0].solo[itemIndex];
  initialFormValues.has_401k = retirement.has_401k;
  initialFormValues.trad_contribution = soloAllocation["401k_value"];
  initialFormValues.roth_401k_contribution = soloAllocation.roth_401k_value;
  initialFormValues.roth_401k = retirement.has_roth_401k;
  initialFormValues.match_limit = retirement.match_limit;
  initialFormValues.match_percent = retirement.match_percent;
  initialFormValues.additional_match = retirement.additional_limit ? "y" : "n";
  initialFormValues.additional_limit = retirement.additional_limit;
  initialFormValues.additional_percent = retirement.additional_percent;
  initialFormValues.auto_question = retirement.nonelective_limit ? "y" : "n";
  initialFormValues.auto_match_percent = retirement.nonelective_limit;
  const [formValues, setFormValues] = useState<any>(initialFormValues);

  // TODO checking for income and then closing the form is not the right way, fix this
  useEffect(() => {
    const eligibleIncome =
      initialFormValues.who === "spouse"
        ? spouseEligibleIncome
        : myEligibleIncome;
    if (!eligibleIncome && curatedFlow) {
      onClose();
    }
  }, [myEligibleIncome, spouseEligibleIncome]);

  const eligibleIncome =
    formValues.who === "spouse" ? spouseEligibleIncome : myEligibleIncome;

  const totalMaxPercent = (MAX_401K_ANNUAL_CONTRIBUTION / eligibleIncome) * 100;
  const maxPercent = totalMaxPercent - (formValues.roth_401k_contribution || 0);
  const rothMaxPercent = totalMaxPercent - (formValues.trad_contribution || 0);

  const setFormValue = (e: React.ChangeEvent<any> | string, fieldName?: string) => {
    let field: string;
    let value: any;

    if (typeof e === 'string') {
      field = fieldName || 'has_401k';
      value = e.replace(`${field}_`, "");
    } else {
      field = e.target.name;
      value = e.target.value;
    }

    const newFormValues = { ...formValues, [field]: value };

    // If has_401k is updated, also update employer_offered
    if (field === 'has_401k') {
      newFormValues.employer_offered = value;
    }

    if (field === "who") {
      const itemIndex = value === "spouse" ? 1 : 0;
      const retirement = plan.retirement[itemIndex];
      newFormValues.trad_contribution = undefined;
      newFormValues.match_limit = retirement.match_limit;
      newFormValues.match_percent = retirement.match_percent;
      if (retirement.additional_limit) {
        newFormValues.additional_match = "y";
        newFormValues.additional_limit = retirement.additional_limit;
        newFormValues.additional_percent = retirement.additional_percent;
      }
    }

    setFormValues(newFormValues);
  };

  const spouses = formValues.who === "spouse" ? "spouse's " : "";

  const dollarContribution =
    ((formValues.trad_contribution + formValues.roth_401k_contribution) / 100) *
    (formValues.who === "spouse" ? spouseEligibleIncome : myEligibleIncome);
  const totalContribution = (dollarContribution / totalIncome || 0) * 100;

  const save = () => {
    const newRetirement = [...plan.retirement];
    const whoIndex = formValues.who === "applicant" ? 0 : 1;
    const retirementItem = {
      ...EMPTY_RETIREMENT,
      has_401k: formValues.has_401k,
      match_limit: formValues.match_limit,
      match_percent: formValues.match_percent,
      has_roth_401k: formValues.has_roth_401k,
      who: formValues.who,
    };
    if (formValues.additional_match === "y") {
      retirementItem.additional_limit = +formValues.additional_limit || 0;
      retirementItem.additional_percent = +formValues.additional_percent || 0;
    }
    if (formValues.auto_question === "y") {
      retirementItem.nonelective_limit = +formValues.auto_match_percent || 0;
      retirementItem.nonelective_traditional_limit =
        +formValues.auto_match_percent || 0;
    }
    retirementItem.traditional_limit =
      formValues.match_limit * formValues.match_percent +
      (formValues.additional_limit - formValues.match_limit) *
      formValues.additional_percent;
    newRetirement[whoIndex] = retirementItem;
    const soloAllocations = plan.allocations[0].solo;
    const newSoloAllocations = [...soloAllocations];
    newSoloAllocations[whoIndex] = {
      ...soloAllocations[whoIndex],
      ["401k_value"]: formValues.trad_contribution || 0,
      roth_401k_value: formValues.roth_401k_contribution || 0,
    };
    if (initialValues && formValues.who !== initialValues.who) {
      const otherIndex = whoIndex ? 0 : 1;
      newSoloAllocations[otherIndex] = {
        ...newSoloAllocations[otherIndex],
        ["401k_value"]: 0,
      };
    }
    const newAllocations = [...plan.allocations];
    let combinedContribution =
      ((newSoloAllocations[0]["401k_value" as AccountTypes] || 0) / 100) *
      myEligibleIncome;
    let rothCombinedContribution =
      ((newSoloAllocations[0]["roth_401k_value" as AccountTypes] || 0) / 100) *
      myEligibleIncome;
    if (isMarried) {
      combinedContribution +=
        ((newSoloAllocations[1]["401k_value" as AccountTypes] || 0) / 100) *
        spouseEligibleIncome;
      rothCombinedContribution +=
        ((newSoloAllocations[1]["roth_401k_value" as AccountTypes] || 0) /
          100) *
        spouseEligibleIncome;
    }
    newAllocations[0] = {
      ...newAllocations[0],
      solo: newSoloAllocations,
      ["401k_value"]: (combinedContribution / totalIncome) * 100,
      roth_401k_value: (rothCombinedContribution / totalIncome) * 100,
    };
    const newPlan = {
      allocations: newAllocations,
      retirement: newRetirement,
    };
    // dont save if employer offer select field is changed to no
    // this only effects the curated flow
    if (formValues.employer_offered === "y") {
      dispatch(updateCurrentPlan(newPlan));
      // Traditional 401k has been changed, check the taxes
      dispatch(estimateCurrentPlanTaxes());
    }
    dispatch(savePlan(null));
    onSave();
  };

  const setFormWho = (e: React.ChangeEvent<any>) => {
    const who = e.target.value;
    const newFormValues = { ...formValues, who };
    setFormValues(newFormValues);
  };

  return render({
    component: (
      <>
        <Global styles={catStyles} />
        <EuiFlexGroup className="ai-flex-content">
          <EuiFlexItem>
            <div className="ai-content-title">
              <EuiFlexGroup style={{ gap: 0 }}>
                <EuiFlexItem grow={false}>
                  <h1>Employer Retirement</h1>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    // onClick={() => toggleFlyout("life-goals")}
                    iconType="questionInCircle"
                    aria-label="Help"
                    className="help-icon"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiFlexItem>
          {!curatedIndex && (
            <EuiFlexItem grow={false} style={{ alignItems: 'flex-end' }}>
              <EuiButtonIcon
                color="text"
                iconType="cross"
                aria-label="Close"
                onClick={onClose}
                className="close-icon"
              />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
        {formValues.who && curatedFlow && (
          <>
            <StyledSpacer size="32px" />
            <EuiFormRow
              label={
                <LabelStyle>
                  Does your employer offer and do you want to contribute to
                  your 401k/403b/457b/TSP?
                </LabelStyle>
              }
            >
              <EuiRadioGroup
                options={generateOptions('has_401k', YES_NO_OPTIONS)}
                aria-label="has_401k"
                name="has_401k"
                onChange={(id) => setFormValue(id, 'has_401k')}
                idSelected={`has_401k_${formValues.has_401k}`}
              />
            </EuiFormRow>
          </>
        )}

        {!formValues.who && (
          <>
            <StyledSpacer size="32px" />
            <EuiFormRow
              label={
                <LabelStyle>
                  Whose contribution is this?
                </LabelStyle>
              }
            >
              <EuiSuperSelect
                options={[
                  { value: "applicant", inputDisplay: "Mine" },
                  { value: "spouse", inputDisplay: "My spouse's" },
                ]}
                valueOfSelected={formValues.who}
                onChange={(value) => setFormValue(value, "who")}
              />
            </EuiFormRow>
          </>
        )}

        {formValues.employer_offered !== "n" && (
          <>
            {!!formValues.who && (
              <>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <LabelStyle>
                      My {spouses} eligible income for retirement
                    </LabelStyle>
                  }
                >
                  <EuiText>
                    <h3>
                      {formatWholeDollars(
                        formValues.who === "spouse"
                          ? spouseEligibleIncome
                          : myEligibleIncome
                      )}
                    </h3>
                  </EuiText>
                </EuiFormRow>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <LabelStyle>
                      What {spouses}percentage of your income do you contribute each month?<LabelAsterisk />
                    </LabelStyle>
                  }
                  helpText={`Max: ${(Math.floor(maxPercent * 100) / 100).toFixed(2)}%`}
                >
                  <PercentTextField
                    variant="outlined"
                    fullWidth
                    name="trad_contribution"
                    value={formValues.trad_contribution}
                    max={maxPercent}
                    onChange={setFormValue}
                    eui
                  />
                </EuiFormRow>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <LabelStyle>
                      What percentage of your {spouses}contributions does your {spouses}employer match?<LabelAsterisk />
                    </LabelStyle>
                  }
                  helpText="Most employers match 100%. You can edit if needed."
                >
                  <PercentTextField
                    variant="outlined"
                    fullWidth
                    name="match_percent"
                    value={formValues.match_percent}
                    placeholder="100%"
                    onChange={setFormValue}
                    eui
                  />
                </EuiFormRow>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <LabelStyle>
                      What is the maximum percentage of your {spouses}salary that your {spouses}employer will match?<LabelAsterisk />
                    </LabelStyle>
                  }
                >
                  <PercentTextField
                    variant="outlined"
                    fullWidth
                    name="match_limit"
                    value={formValues.match_limit}
                    placeholder="6%"
                    onChange={setFormValue}
                    eui
                  />
                </EuiFormRow>
                <StyledSpacer size="16px" />
                <EuiCallOut
                  title="Get the full employer match"
                  iconType="iInCircle"
                  style={{ maxWidth: "600px" }}
                >
                  <p>
                    Employer contributions to your 401k is free money that can increase your retirement savings. Try to contribute enough to get the full match.
                  </p>
                </EuiCallOut>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <LabelStyle>
                      Does your {spouses}employer offer an additional match?
                    </LabelStyle>
                  }
                >
                  <EuiRadioGroup
                    options={generateOptions('additional_match', YES_NO_OPTIONS)}
                    aria-label="additional_match"
                    name="additional_match"
                    onChange={(id) => setFormValue(id, 'additional_match')}
                    idSelected={`additional_match_${formValues.additional_match}`}
                  />
                </EuiFormRow>
                {formValues.additional_match === "y" && (
                  <>
                    <StyledSpacer size="32px" />
                    <EuiFormRow
                      label={
                        <LabelStyle>
                          What additional percentage of your {spouses}contributions does your employer match<LabelAsterisk />
                        </LabelStyle>
                      }
                      helpText="Most employers match 50%. You can edit if needed."
                    >
                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="additional_limit"
                        value={formValues.additional_limit}
                        placeholder="50%"
                        onChange={setFormValue}
                        eui
                      />
                    </EuiFormRow>
                    <StyledSpacer size="32px" />
                    <EuiFormRow
                      label={
                        <LabelStyle>
                          What is the maximum additional percentage of your salary that your employer will match?<LabelAsterisk />
                        </LabelStyle>
                      }
                    >
                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="additional_percent"
                        value={formValues.additional_percent}
                        placeholder="8%"
                        onChange={setFormValue}
                        eui
                      />
                    </EuiFormRow>
                  </>
                )}
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <LabelStyle>
                      Does your employer offer and do you want to contribute to a Roth 401k/403b/457b/TSP?
                    </LabelStyle>
                  }
                >
                  <EuiRadioGroup
                    options={generateOptions('has_roth_401k', YES_NO_OPTIONS)}
                    aria-label="has_roth_401k"
                    name="has_roth_401k"
                    onChange={(id) => setFormValue(id, 'has_roth_401k')}
                    idSelected={`has_roth_401k_${formValues.has_roth_401k}`}
                  />
                </EuiFormRow>
                {formValues.has_roth_401k === "y" && (
                  <>
                    <StyledSpacer size="32px" />
                    <EuiFormRow
                      label={
                        <LabelStyle>
                          What percentage of your income do you contribute each month?<LabelAsterisk />
                        </LabelStyle>
                      }
                      helpText={`Max: ${(Math.floor(rothMaxPercent * 100) / 100).toFixed(2)}%`}
                    >
                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="auto_question"
                        value={formValues["auto_question"]}
                        max={rothMaxPercent}
                        onChange={setFormValue}
                        eui
                      />
                    </EuiFormRow>
                  </>
                )}
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <LabelStyle>
                      Does your employer offer contributions to your retirement without you having to contribute?
                    </LabelStyle>
                  }
                >
                  <EuiRadioGroup
                    options={generateOptions('auto_question', YES_NO_OPTIONS)}
                    aria-label="auto_question"
                    name="auto_question"
                    onChange={(id) => setFormValue(id, 'auto_question')}
                    idSelected={`auto_question_${formValues.auto_question}`}
                  />
                </EuiFormRow>
                {formValues.auto_question === "y" && (
                  <>
                    <StyledSpacer size="32px" />
                    <EuiFormRow
                      label={
                        <LabelStyle>
                          What percentage of your employer match do you want to put in your 401k/403b/457b/TSP?<LabelAsterisk />
                        </LabelStyle>
                      }
                      helpText={`Max: 6%`}
                    >
                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="auto_match_percent"
                        value={formValues["auto_match_percent"]}
                        onChange={setFormValue}
                        eui
                      />
                    </EuiFormRow>
                  </>
                )}
                <StyledSpacer size="32px" />
                <EuiPanel paddingSize="l" className="billing-summary" style={{ maxWidth: '100%' }}>
                  <EuiFlexGroup justifyContent="spaceBetween" alignItems="baseline">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        &nbsp;
                      </EuiText>
                      <EuiText>
                        <strong>Retirement Contribution Summary</strong>
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiText><strong>% of Total Income</strong></EuiText>
                      <EuiText>
                        {formatPercent(totalContribution)}
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiText><strong>Monthly</strong></EuiText>
                      <EuiText>
                        {formatMonthly(Math.round(dollarContribution / 12))}
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiText><strong>Annually</strong></EuiText>
                      <EuiText>
                        {formatAnnually(dollarContribution)}
                      </EuiText>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
                <StyledSpacer size="16px" />
              </>
            )}
          </>
        )
        }
      </>
    ),
    nextLabel: "Save",
    onNext: save,
  });
};

export default AddOrEdit401k;
