import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { map, pickBy, range } from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import colors from "src/theme/colors";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Link,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSuperSelect,
  EuiFieldText,
  EuiFormRow,
  EuiRadioGroup,
} from "@elastic/eui";

import { StyledSpacer } from "src/components/Global/StyledComponents";
import { useFlyout } from "src/components/Global/FlyoutContext";

import { Autocomplete } from "@material-ui/lab";

import Icon from "src/components/Icon";
import { MONTHS, YEARS_FROM_NOW } from "src/constants";
import {
  ALL_GRADUATED_LIFE_EVENTS,
  Graduation,
  LifeEvent,
  LifeEventType,
  GRADUATED_LIFE_EVENTS,
  PlanViewComponent,
  Retirement,
} from "src/interfaces";
import {
  addLifeEvent,
  editLifeEvent,
  removeLifeEvent,
  savePlan,
  setBuildStep,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  getCurrentPlan,
  getPlanIsDirty,
  isCurrentPlanImplemented,
} from "src/store/planBuild/selector";
import {
  updateEducation,
  UpdateEducationPayload,
} from "src/store/profileBuild/actions";
import {
  getProfile,
  getSpouseInSchool,
  getSpouseProfile,
  getUserInSchool,
} from "src/store/profileBuild/selector";
import { getHasPlan, getIsMarried } from "src/store/system/selector";
import { getIsHomeowner } from "src/store/account/selector";
import LifeEventCategories from "./GraduatedLifeEventCategories";
import NoGraduationEdit from "./NoGraduationEdit";
import CenterContainer from "../../Components/CenterContainer";
import Button from "src/components/Button";
import { DollarTextField, formatWholeDollars } from "src/utils";
import { disableSidebar } from "src/store/dashboard/actions";
import { lifeEventsCategoriesBlowout } from "./blowoutContent";
import ToolTipIcon from "src/components/ToolTipIcon";
import LifeEventsTable from "./LifeEventsTable";
dayjs.extend(relativeTime);
import styled from "@emotion/styled";
import { Style } from "@material-ui/icons";

const StyledDualSelect = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  @media (max-width: 576px) {
    gap: 12px;
  }
`;
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface SelectItem {
  label: string;
  value: any;
}

const GraduatedLifeEvents: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const plan = useSelector(getCurrentPlan);
  const planIsImplemented = useSelector(isCurrentPlanImplemented);
  const isHomeowner = useSelector(getIsHomeowner);
  const isMarried = useSelector(getIsMarried);
  const spouseInSchool = useSelector(getSpouseInSchool);
  const userInSchool = useSelector(getUserInSchool);
  const myProfile = useSelector(getProfile);
  const hasPlan = useSelector(getHasPlan);
  const spouseProfile = useSelector(getSpouseProfile);
  const dirty = useSelector(getPlanIsDirty);
  const [showAddEditForm, setShowAddEditForm] = useState(false);
  const [showNoGraduationEdit, setShowNoGraduationEdit] = useState<any>(null);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [updateFields, setUpdateFields] = useState<any>(null);
  const isCuratedFlow = !plan.progress;
  const { toggleFlyout } = useFlyout();
  const [showCategories, setShowCategories] = useState(isCuratedFlow);

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  // add an index to the life events
  /* 
    Removal logic uses an index to remove data from the global store
    We added sorting logic that will need to change the order of the displayed events 
    Before we change the order we store the original index 
    This way the original index is passed to onto the removal instead of the sorted index
  */
  const modifiedLifeEvents = plan.lifeevents.map((lifeEvent, rawIndex) => ({
    ...lifeEvent,
    eventtype: String(lifeEvent.eventtype),
    rawIndex,
  }));


  const sortedLifeEvents = [...modifiedLifeEvents].sort(
    (a, b) => Date.parse(a.date) - Date.parse(b.date)
  );

  // const [modalEnabled, setModalEnabled] = useState(false);
  // const [newBuild, setNewBuild] = useState(true);

  const now = new Date();
  let monthIndex = +now.toISOString().slice(5, 7);
  const nowYear = now.getFullYear();
  const nowMonth = now.getMonth() + 1;

  let year: number = nowYear;
  if (editingIndex >= 0 && updateFields) {
    const monthString = updateFields.date.slice(5, 7);
    monthIndex = +monthString;
    year = +updateFields.date.slice(0, 4);
  }
  const [selectedMonth, selectMonth] = useState(monthIndex);
  const [selectedYear, selectYear] = useState(year);

  const setCategory = (type: LifeEventType) => {
    if (GRADUATED_LIFE_EVENTS[type].emptyValue) {
      setUpdateFields({
        ...GRADUATED_LIFE_EVENTS[type].emptyValue,
        id: Date.now(),
      });
    } else {
      setUpdateFields(null);
    }
    setShowCategories(false);
    setShowAddEditForm(true);
  };

  const openNoGraduationEdit = () => {
    setShowAddEditForm(true);
    setShowNoGraduationEdit(true);
  };
  const closeNoGraduationEdit = () => {
    setShowAddEditForm(false);
    setShowNoGraduationEdit(false);
  };

  const updateGraduationDate = () => {
    const isSpouse = updateFields.who === "spouse";
    const profile = isSpouse ? spouseProfile : myProfile;
    const existingEvent = plan.lifeevents[editingIndex];
    if (!existingEvent || existingEvent.eventtype !== "graduate") {
      return;
    }
    const existingYear = +existingEvent.date.slice(0, 4);
    const existingMonth = +existingEvent.date.slice(5, 7);
    const educationUpdate: UpdateEducationPayload = {
      update: {},
      who: updateFields.who,
    };
    if (
      profile.phd_grad_year === existingYear &&
      profile.phd_grad_month === existingMonth
    ) {
      educationUpdate.update.phd_grad_year = selectedYear;
      educationUpdate.update.phd_grad_month = selectedMonth;
    }
    if (
      profile.date_last_school_year === existingYear &&
      profile.date_last_school_month === existingMonth
    ) {
      educationUpdate.update.date_last_school_year = selectedYear;
      educationUpdate.update.date_last_school_month = selectedMonth;
    }
    if (
      profile.undergrad_grad_year === existingYear &&
      profile.undergrad_grad_month === existingMonth
    ) {
      educationUpdate.update.undergrad_grad_year = selectedYear;
      educationUpdate.update.undergrad_grad_month = selectedMonth;
    }
    if (
      profile.aa_grad_year === existingYear &&
      profile.aa_grad_month === existingMonth
    ) {
      educationUpdate.update.aa_grad_year = selectedYear;
      educationUpdate.update.aa_grad_month = selectedMonth;
    }
    dispatch(updateEducation(educationUpdate));
  };

  const save = () => {
    let eventToSave: any = {
      ...pickBy(updateFields, (value: any, key: string) => key !== "age" && !key.startsWith("enable")),
      typeLabel: updateFields.typeLabel ?? "",
      dateLabel: `${selectedMonth}-${selectedYear}`,  // Use the selected month and year to initialize
      savingsLabel: updateFields.down || updateFields.amount ? `Savings Goal: ${formatWholeDollars(updateFields.down ?? updateFields.amount ?? 0)}` : ""
    };
    if (updateFields.eventtype === "retire") {
      const profile = updateFields.who === "spouse" ? spouseProfile : myProfile;
      const dobYear = profile.dob_year;
      const retirementYear = dobYear + updateFields.age;
      eventToSave.date = `${retirementYear}-06`;
    } else {
      let monthString = "" + selectedMonth;
      if (monthString.length < 2) {
        monthString = "0" + monthString;
      }
      eventToSave.date = `${selectedYear}-${monthString}`;
      if (updateFields.eventtype === "child") {
        if (updateFields.enable_reduce !== "y") {
          eventToSave = {
            ...eventToSave,
            eventtype: "child",
            date: eventToSave.date,
            reduce: null,
            salary: null,
          };
        }
      }
      if (
        (updateFields.eventtype === "graduate" && !hasPlan) ||
        planIsImplemented
      ) {
        updateGraduationDate();
      }
    }
    const eventDefinition = ALL_GRADUATED_LIFE_EVENTS[updateFields.eventtype];
    eventDefinition.fields.forEach((item: any) => {
      if (item.type === "dollar" && !eventToSave[item.field]) {
        eventToSave[item.field] = 0;
      }
    });
    if (!showCategories) {
      if (editingIndex >= 0) {
        dispatch(editLifeEvent({ index: editingIndex, update: eventToSave }));
      } else {
        dispatch(addLifeEvent(eventToSave));
      }
      dispatch(savePlan(PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW));
    }
    setShowAddEditForm(false);
    setEditingIndex(-1);
  };

  const handleSelectMonth = (e: any) => selectMonth(e.target.value);
  const handleSelectYear = (e: any, newYear: number | null) => {
    if (newYear) {
      selectYear(newYear);
    }
  };

  const setFormValue = (field: string, value: any) => {
    setUpdateFields((current: any) => {
      const update = { ...current, [field]: value };
      if (field === "enable_reduce" && value === "y") {
        update.reduce = "applicant";
        update.salary = 0;
      }
      return update;
    });
  };


  // lifevents in this component are sorted to display
  const removeItem = (index: number) => dispatch(removeLifeEvent(index));

  const setEnableFields = (item: any) => {
    const result: any = { ...item };
    switch (item.eventtype) {
      case "house": {
        if (item.rent) {
          result.enable_rent = "y";
        } else {
          result.enable_rent = "n";
        }
        break;
      }
      case "child": {
        if (item.reduce) {
          result.enable_reduce = "y";
        } else {
          result.enable_reduce = "n";
        }
        break;
      }
      case "marriage": {
        const hasFedLoan = item.spouseFedLoanBalance;
        const hasPrivLoan = item.spousePrivLoanBalance;
        const value = hasFedLoan || hasPrivLoan ? "y" : "n";
        result.enable_privloan = value;
        result.enable_fedloan = value;
        break;
      }
      default: {
        break;
      }
    }
    return result;
  };

  const openEdit = (item: LifeEvent, index: number) => {
    if (item.eventtype === "graduate" && planIsImplemented) {
      return openNoGraduationEdit();
    }
    const dateString = item.date;
    const year = dateString.slice(0, 4);
    const month = dateString.slice(5, 7);
    selectMonth(+month);
    selectYear(+year);
    setEditingIndex(index);
    const update = setEnableFields(item);
    setUpdateFields(update);
    setShowAddEditForm(true);
  };

  const onNext = () => {
    if (showCategories) {
      setShowCategories(false);
    } else if (showAddEditForm) {
      save();
      // TODO: Once interstitial video is ready comment in the following lines
      // } else if (newBuild) {
      //   setModalEnabled(true);
      //   setNewBuild(false);
    } else {
      const nextStep = PLAN_BUILD_STEPS.INCOME_AND_EXPENSES;
      if (dirty) {
        dispatch(savePlan(nextStep));
      } else {
        dispatch(setBuildStep(nextStep));
      }
    }
  };

  const renderRetirementAgeItems = () => {
    const profile = updateFields.who === "spouse" ? spouseProfile : myProfile;
    const dobYear = profile.dob_year;
    const currentAge = new Date().getFullYear() - dobYear;
    const ages = range(currentAge, 121);
    return ages.map((age: number) => (
      <MenuItem key={age} value={age}>
        {age}
      </MenuItem>
    ));
  };

  const selectedItemDefinition = updateFields
    ? ALL_GRADUATED_LIFE_EVENTS[updateFields.eventtype]
    : null;
  let title =
    showAddEditForm && selectedItemDefinition
      ? selectedItemDefinition.typeLabel
      : "Life Events/Goals";
  if (showNoGraduationEdit) {
    title = "Graduation Date";
  }

  const dateInThePast =
    year < nowYear || (selectedYear === nowYear && selectedMonth < nowMonth);

  const categoryHelp =
    selectedItemDefinition && selectedItemDefinition.help
      ? selectedItemDefinition.help
      : undefined;
  const help = showCategories ? lifeEventsCategoriesBlowout : categoryHelp;

  useEffect(() => {
    dispatch(disableSidebar());
  }, [showCategories]);

  return render({
    component: (
      <>
        <EuiFlexGroup className="ai-flex-content">
          <EuiFlexItem>
            <div className="ai-content-title">
              <EuiFlexGroup style={{ gap: 0 }}>
                <EuiFlexItem grow={false}>
                  <h1>Life Events/Goals {selectedCategory ? GRADUATED_LIFE_EVENTS[selectedCategory].label : ""}</h1>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    onClick={() => toggleFlyout("life-goals")}
                    iconType="questionInCircle"
                    aria-label="Help"
                    className="help-icon"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="16px" />
        <EuiText className="ai-secondary-text">
          <p>
            It's important to know what your goals are so you can plan for how to achieve them. Add any relevant goals and potential life events to your plan.
          </p>
        </EuiText>
        {showCategories && (
          <>
            <StyledSpacer size="24px" />
            <LifeEventCategories onSelect={setCategory} />
          </>
        )}
        {showAddEditForm && !showNoGraduationEdit && (
          <>
            {selectedItemDefinition &&
              selectedItemDefinition.fields.length > 0 && (
                <>
                  {map(selectedItemDefinition.fields, (item) => {
                    if (item.enabled) {
                      if (
                        (item.enabled === "profile_married" &&
                          !isMarried) ||
                        (item.enabled === "profile_homeowner" &&
                          !isHomeowner) ||
                        (item.enabled_values &&
                          item.enabled_values.indexOf(
                            updateFields[item.enabled]
                          ) < 0) ||
                        (!item.enabled.startsWith("profile") &&
                          updateFields[item.enabled] !== "y")
                      ) {
                        return null;
                      }
                    }
                    let label = item.label;
                    if (item.whoText) {
                      if (updateFields[item.whoText] !== "spouse") {
                        label = label
                          .replace("their", "your")
                          .replace("they", "you")
                          .replace("does your spouse", "do you");
                      }
                    }

                    let retirementAge = updateFields[item.field] || 0;
                    if (updateFields.eventtype === "retire") {
                      const dobYear =
                        item.who !== "applicant"
                          ? myProfile.dob_year
                          : spouseProfile.dob_year;
                      const retirementYears = sortedLifeEvents.filter(
                        (event) =>
                          event.eventtype === "retire" &&
                          event["who" as keyof typeof event] ===
                          updateFields.who
                      );
                      if (retirementYears && !retirementAge) {
                        const retireYear = +retirementYears[0].date.slice(
                          0,
                          4
                        );
                        retirementAge = retireYear - dobYear;
                        setUpdateFields({
                          ...updateFields,
                          age: retirementAge,
                        });
                      }
                    }

                    return (
                      <StyledDualSelect key={item.field}>
                        <StyledDiv>
                          {item.type === "monthYear" && (
                            <>
                              <StyledSpacer size="32px" />
                              <EuiFormRow
                                label={
                                  <>
                                    <strong>{label}</strong>
                                    <ToolTipIcon
                                      placement="right"
                                      title={item.info}
                                    />
                                  </>
                                }
                              >
                                <EuiFlexGroup gutterSize="s">
                                  <EuiFlexItem grow={1}>
                                    <EuiSuperSelect
                                      options={MONTHS.map((month, index) => ({
                                        value: (index + 1).toString(),
                                        inputDisplay: month,
                                      }))}
                                      valueOfSelected={selectedMonth.toString()}
                                      onChange={(value) => handleSelectMonth({ target: { value: parseInt(value) } })}
                                      fullWidth
                                      aria-label="Select Month"
                                    />
                                  </EuiFlexItem>
                                  <EuiFlexItem grow={1}>
                                    <EuiSuperSelect
                                      options={YEARS_FROM_NOW.map((year) => ({
                                        value: year.toString(),
                                        inputDisplay: String(year),
                                      }))}
                                      valueOfSelected={selectedYear.toString()}
                                      onChange={(value) => handleSelectYear(null, parseInt(value))}
                                      fullWidth
                                      aria-label="Select Year"
                                    />
                                  </EuiFlexItem>
                                </EuiFlexGroup>
                              </EuiFormRow>
                            </>
                          )}
                          {item.type === "age" && (
                            <>
                              <StyledSpacer size="32px" />
                              <EuiFormRow
                                label={
                                  <strong>{label}</strong>
                                }
                                labelAppend={
                                  item.info && (
                                    <ToolTipIcon
                                      placement="right"
                                      title={item.info}
                                    />
                                  )
                                }
                              >
                                <EuiSuperSelect
                                  options={renderRetirementAgeItems().map((age) => ({
                                    value: age.props.value.toString(), // Convert to string
                                    inputDisplay: age.props.children,
                                  }))}
                                  valueOfSelected={updateFields[item.field]?.toString()} // Convert to string if defined
                                  onChange={(value) => setFormValue(item.field, parseInt(value))} // Directly pass field and value
                                  fullWidth
                                  aria-label="Select Age"
                                />
                              </EuiFormRow>
                            </>
                          )}
                          {item.type === "select" && (
                            <>
                              <StyledSpacer size="32px" />
                              <EuiFormRow
                                label={
                                  <>
                                    <strong>{label}</strong>
                                    <ToolTipIcon
                                      placement="right"
                                      title={item.info}
                                    />
                                  </>
                                }
                              >
                                <EuiSuperSelect
                                  options={(item.select || []).map((selectItem: SelectItem) => ({
                                    value: selectItem.value.toString(),
                                    inputDisplay: selectItem.label,
                                  }))}
                                  valueOfSelected={updateFields[item.field]?.toString()}
                                  onChange={(value) => setFormValue(item.field, value)}
                                  fullWidth
                                  aria-label={`Select ${label}`}
                                />
                              </EuiFormRow>
                            </>
                          )}
                          {item.type === "radio" && (
                            <>
                              <StyledSpacer size="32px" />
                              <EuiFormRow
                                label={
                                  <>
                                    <strong>{label}</strong>
                                    {item.info && (
                                      <ToolTipIcon
                                        placement="right"
                                        title={item.info}
                                      />
                                    )}
                                  </>
                                }
                              >
                                <EuiRadioGroup
                                  options={(item.select || []).map((selectItem: SelectItem) => ({
                                    id: selectItem.value.toString(),
                                    label: selectItem.label,
                                  }))}
                                  idSelected={updateFields[item.field]?.toString()}
                                  onChange={(id) => setFormValue(item.field, id)}
                                  name={label}
                                />
                              </EuiFormRow>
                            </>
                          )}
                          {item.type === "dollar" && (
                            <>
                              <StyledSpacer size="32px" />
                              <EuiFormRow
                                label={
                                  <>
                                    <strong>{label}</strong>
                                    <ToolTipIcon
                                      placement="right"
                                      title={item.info}
                                    />
                                  </>
                                }
                                helpText={item.helpText}
                              >
                                <DollarTextField
                                  name={item.field}
                                  value={updateFields[item.field]}
                                  placeholder="0"
                                  onChange={(e: any) => setFormValue(item.field, e.target.value)}
                                  fullWidth
                                  eui
                                />
                              </EuiFormRow>
                            </>
                          )}
                          {item.type === "string" && (
                            <>
                              <StyledSpacer size="32px" />
                              <EuiFormRow
                                helpText={item.helpText}
                                label={
                                  <>
                                    <strong>{label}</strong>
                                    <ToolTipIcon
                                      placement="right"
                                      title={item.info}
                                    />
                                  </>
                                }
                              >
                                <EuiFieldText
                                  name={item.field}
                                  value={updateFields[item.field]}
                                  placeholder=""
                                  onChange={(e) => setFormValue(e.target.name, e.target.value)}
                                  fullWidth
                                />
                              </EuiFormRow>
                            </>
                          )}
                        </StyledDiv>
                      </StyledDualSelect>
                    );
                  })}
                </>
              )}
          </>
        )}

        {showAddEditForm && showNoGraduationEdit && (
          <NoGraduationEdit goBack={closeNoGraduationEdit} />
        )}
        {!showAddEditForm && !showCategories && (
          <LifeEventsTable
            sortedLifeEvents={sortedLifeEvents}
            openEdit={openEdit}
            removeItem={removeItem}
            setShowCategories={setShowCategories}
            isMarried={isMarried}
            spouseProfile={spouseProfile}
            myProfile={myProfile}
            spouseInSchool={spouseInSchool}
            userInSchool={userInSchool}
          />
        )}
        {/* TODO: Add interstitial video in below block */}
        {/* <Modal
          className="overflow-y-hidden"
          title="Saucy"
          size="md"
          isOpen={modalEnabled}
          onClose={()=>{
            setModalEnabled(false);
            onNext();
          }}
        >
          <Box className="flex justify-center">
            <iframe
              width="853"
              height="480"
              src={`https://www.youtube.com/embed/${"dQw4w9WgXcQ?autoplay=1&enablejsapi=1"}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </Box>
        </Modal> */}
      </>
    ),
    nextDisabled: dateInThePast,
    nextTooltip: dateInThePast ? "Please enter a future date." : undefined,
    nextLabel: showAddEditForm ? "Save" : "Next Section",
    onNext,
  });
};

export default GraduatedLifeEvents;

// const useStyles = makeStyles({
//   item: {
//     display: "flex",
//     // alignItems: "stretch",
//     justifyContent: "space-between",
//     flexBasis: "0 auto 0",
//     padding: "10px 15px",
//     margin: "10px 0px",
//     border: `1px solid ${colors.blueGray6}`,
//   },
//   editableArea: {
//     cursor: "pointer",
//     width: "calc(100% - 45px)",
//   },
//   retireEditArea: {
//     cursor: "pointer",
//     width: "100%",
//   },
//   removeArea: {
//     width: "45px",
//   },
//   editRow: {
//     display: "flex",
//     // justifyContent: "space-between",
//     // maxWidth: 120,
//     alignItems: "center",
//     // color: colors.blueGray4,
//     "& p": {
//       color: colors.textDisabled,
//     },
//     "& span": {
//       color: colors.textDisabled,
//     },
//     color: "textDisabled",
//     flexDirection: "row",
//   },
//   btnAddItem: {
//     width: "100%",
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     marginTop: 30,
//   },
//   btnCancel: {
//     width: "calc(100%)",
//     marginBottom: "20px",
//     // position: "absolute",
//     // bottom: 20,
//     // left: 0,
//   },
//   autoText: {
//     height: 45,
//     "&>div": {
//       padding: "6px !important",
//     },
//     "&>legend": {
//       visibility: "visible",
//     },
//   },
//   textBody: {
//     fontSize: "14px",
//   },
//   addedGoals: {
//     display: "grid",
//   },
//   goalDetails: {
//     flexDirection: "column",
//   },
// });
