import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from "@material-ui/core";

import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiRadioGroup,
} from "@elastic/eui";

import { YES_NO_OPTIONS, generateOptions } from "src/constants/formOptions";

import CenterContainer from "../../Components/CenterContainer";
import Button from "src/components/Button";
import Radio from "src/components/Radio";
import { savePlan, updateAllocations } from "src/store/planBuild/actions";
import {
  getCurrentPlan,
  getMy401kEligibleIncome,
  getSpouse401kEligibleIncome,
  getMyMaxTradIRADollarContribution,
  getMyMaxRothIRADollarContribution,
  getSpouseMaxRothIRADollarContribution,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { Plan } from "src/interfaces/plan.interface";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import { SidebarContent } from "src/interfaces";

import { useStyles } from "./styles";
import PercentBreakdown from "src/components/PercentBreakdown";
import { LabelStyle, StyledSpacer } from "src/components/Global/StyledComponents";


// TODO: this is duplicated from AddEditIRA, clean it up

const roth_ira_blowout: SidebarContent[] = [
  {
    header: "Recommendation For ROTH IRA",
    body: [
      "If you are eligible to contribute to a Roth IRA, we highly recommend contributing $50 monthly.",
      "Diversifiying your retirement income and using different account types will help reduce the risk to that income. For instance, if the government changes the tax treatment of assets held in a specific type of account negatively, assets held in a different type of account won't be affeced, all else equal.",
    ],
  },
  {
    header: "What Is A Roth IRA?",
    body: [
      "Roth IRAs are tax-advantaged accounts with special features for withdrawals in retirement:",
      "1) Contributions you make each year do not reduce how much you pay in taxes in the tax year the contribution is made.",
      "2) If you withdraw the money after age 59 1/2, you pay no taxes and no penalties.",
      "3) Early withdrawals are subject to taxes and penalties. However, there are special withdrawals that may not incur taxes, such as using Roth IRA money for a down payment on a house.",
    ],
  },
];

const RothIRA: PlanViewComponent = ({
  initialValues,
  onClose,
  onSave,
  render,
  curatedIndex,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const type = "roth_ira_value";
  const plan: Plan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const myEligibleIncome = useSelector(getMy401kEligibleIncome);
  const spouseEligibleIncome = useSelector(getSpouse401kEligibleIncome);
  const initialFormValues: any = {
    who: "applicant",
  };
  if (initialValues) {
    initialFormValues.who = initialValues.who;
    const itemIndex = isMarried && initialValues.who === "spouse" ? 1 : 0;
    const soloAllocation = plan.allocations[0].solo[itemIndex];
    initialFormValues.contribution =
      soloAllocation[type as "ira_value" | "roth_ira_value"];
  }
  const [formValues, setFormValues] = useState<any>(initialFormValues);
  const [prompt, setPrompt] = useState("n");
  let maxSelector = getMyMaxTradIRADollarContribution;
  if (formValues.who === "spouse") {
    maxSelector = getSpouseMaxRothIRADollarContribution;
  } else if (type === "roth_ira_value") {
    maxSelector = getMyMaxRothIRADollarContribution;
  }
  const maxDollars = useSelector(maxSelector);

  const help = roth_ira_blowout;
  const eligibleIncome =
    formValues.who === "spouse" ? spouseEligibleIncome : myEligibleIncome;

  const save = () => {
    if (prompt === "y") {
      const whoIndex = isMarried && formValues.who === "spouse" ? 1 : 0;
      const soloAllocations = plan.allocations[0].solo;
      const newSoloAllocations = [...soloAllocations];
      newSoloAllocations[whoIndex] = {
        ...soloAllocations[whoIndex],
        [type]: formValues.contribution || 0,
      };
      if (initialValues && formValues.who !== initialValues.who) {
        const otherIndex = whoIndex ? 0 : 1;
        newSoloAllocations[otherIndex] = {
          ...newSoloAllocations[otherIndex],
          [type]: 0,
        };
      }
      let combinedContribution =
        newSoloAllocations[0][type as "ira_value" | "roth_ira_value"] || 0;
      if (isMarried) {
        combinedContribution +=
          newSoloAllocations[1][type as "ira_value" | "roth_ira_value"] || 0;
      }
      const newAllocations = {
        ...plan.allocations[0],
        solo: newSoloAllocations,
        [type]: combinedContribution,
      };
      dispatch(updateAllocations(newAllocations));
    }
    dispatch(savePlan(null));
    onSave();
  };

  const handlePrompt = (id: string) => {
    const value = id.replace("prompt_", "");
    setPrompt(value);
  };

  const nextDisabled = formValues.contribution > maxDollars;

  return render({
    component: (
      <>
        <EuiFlexGroup className="ai-flex-content">
          <EuiFlexItem>
            <div className="ai-content-title">
              <EuiFlexGroup style={{ gap: 0 }}>
                <EuiFlexItem grow={false}>
                  <h1>Roth and Traditional IRA</h1>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    // onClick={() => toggleFlyout("life-goals")}
                    iconType="questionInCircle"
                    aria-label="Help"
                    className="help-icon"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="32px" />
        <EuiFormRow
          label={
            <LabelStyle>
              Will you be contributing to a Roth IRA?
            </LabelStyle>
          }
        >
          <EuiRadioGroup
            aria-label="prompt"
            name="prompt"
            onChange={handlePrompt}
            idSelected={`prompt_${prompt}`}
            options={generateOptions('prompt', YES_NO_OPTIONS)}
          />
        </EuiFormRow>
        <StyledSpacer size="32px" />
        {prompt === "y" && (
          <PercentBreakdown
            max={maxDollars}
            income={eligibleIncome}
            onChange={setFormValues}
            values={formValues}
            label="Roth IRA"
            unlimited
            incomeType="Eligible"
          />
        )}
      </>
    ),
    nextDisabled,
    nextLabel: "Save",
    nextTooltip: nextDisabled
      ? "You must enter an amount that does not exceed the maximum contribution."
      : undefined,
    onNext: save,
  });
};

export default RothIRA;
