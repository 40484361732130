import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { formatAnnually, formatMonthly, formatPercent } from "src/utils";

import {
  addCashflow,
  editCashflow,
  removeCashflow,
  replaceCashflow,
} from "src/store/cashflow/actions";
import { getRiskCashflows } from "src/store/cashflow/selector";
import {
  addRisk,
  editRisk,
  removeRisk,
  savePlan,
  setBuildStep,
  setPlanDirty,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  currentPlanIncomeTotal,
  currentPlanRiskManagementTotal,
  getCurrentPlanCashflows,
  getPlanIsDirty,
  isCurrentPlanImplemented,
  livePlanRiskManagementTotal,
} from "src/store/planBuild/selector";
import {
  RISK_MANAGEMENT_TYPES,
  IncomeExpenses,
  MappedCashflow,
  PlanViewComponent,
} from "src/interfaces";
import RiskManagementTable from "./RiskManagementTable";
import { riskBlowout } from "./blowoutContent";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import {
  EuiText,
} from "@elastic/eui";

const MainForm: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const { risks: planRisks }: IncomeExpenses = useSelector(
    getCurrentPlanCashflows
  );
  const cashflows: MappedCashflow[] = useSelector(getRiskCashflows);
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const dirty = useSelector(getPlanIsDirty);
  const planTotal = useSelector(currentPlanRiskManagementTotal);
  const liveTotal = useSelector(livePlanRiskManagementTotal);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
  const [newItem, setNewItem] = useState<any>(null);

  const total = currentPlanIsImplemented ? liveTotal : planTotal;

  const displayItems = useMemo(() => {
    if (!currentPlanIsImplemented) {
      return planRisks;
    }
    const items = cashflows.map((item) => ({
      ...item,
      percent: ((item.annual || 0) / totalIncome) * 100,
      typeLabel: RISK_MANAGEMENT_TYPES[item.type],
    }));
    if (newItem) {
      items.push(newItem);
    }
    return items;
  }, [cashflows, currentPlanIsImplemented, newItem, planRisks]);

  const saveItem = (
    item: MappedCashflow,
    updateFields: Partial<MappedCashflow>,
    index: number
  ) => {
    if (!currentPlanIsImplemented) {
      const updatePayload = { ...item, ...updateFields };
      const update = {
        payment: Math.abs(updatePayload.annual || 0),
        type: updatePayload.type,
      };
      dispatch(editRisk({ index, update }));
      dispatch(savePlan(null));
    } else {
      if (item.id >= 0) {
        if (updateFields.type && item.type !== updateFields.type) {
          const replacePayload: any = {
            id: item.id,
            newItem: {
              type: updateFields.type,
              amount: Math.abs(updateFields.annual || 0),
              who: "applicant",
            },
          };
          return dispatch(replaceCashflow(replacePayload));
        }
        const payload = {
          id: item.id,
          amount: Math.abs(updateFields.annual || 0),
        };
        return dispatch(editCashflow(payload));
      }
      const cashflow: any = {
        type: newItem.type,
        amount: Math.abs(updateFields.annual || 0),
      };
      if (updateFields.whose === "spouse") {
        cashflow.who = "spouse";
      } else {
        cashflow.who = "applicant";
      }
      const payload = {
        cashflow,
        temporaryId: item.id,
      };
      // refreshScore();
      dispatch(addCashflow(payload));
      setNewItem(null);
    }
  };

  const onNext = () => {
    if (!currentPlanIsImplemented && dirty) {
      dispatch(savePlan(PLAN_BUILD_STEPS.SAVINGS));
    } else {
      dispatch(setBuildStep(PLAN_BUILD_STEPS.SAVINGS));
    }
  };

  const availableItems = RISK_MANAGEMENT_TYPES;

  const addItem = (label: string, key: string) => {
    if (currentPlanIsImplemented) {
      setNewItem({
        type: key,
        annual: 0,
        monthly: 0,
        percent: 0,
        payment: 0,
        typeLabel: RISK_MANAGEMENT_TYPES[key],
      });
    } else {
      dispatch(
        addRisk({
          type: key,
          payment: 0,
        })
      );
    }
  };

  const removeItem = (item: MappedCashflow, index: number) => {
    if (!currentPlanIsImplemented) {
      dispatch(removeRisk(index));
    } else if (index === cashflows.length) {
      setNewItem(null);
    } else {
      dispatch(removeCashflow(item.id));
    }
  };

  return render({
    component: (
      <>
        <div className="ai-flex-content">
          <div className="ai-content-title">
            <h1>Risk Management</h1>
          </div>
        </div>
        <StyledSpacer size="16px" />
        <EuiText className="ai-secondary-text">
          <p>
            "Manage your risk and your return will follow" is a concept we stress at FitBUX. Every financial plan has inherent risk. Life events such as medical emergencies and losing your job can disrupt your plan. That is why you may need certain types of insurance to protect your financial plan.
          </p>
          <p>
            Purchasing insurance is one way to reduce the overall risk to your plan. You must decide:
            <ol>
              <li>The probability of the event you are insuring. For instance, how likely are you to become disabled?</li>
              <li>How much you're willing to pay to protect against that risk.</li>
            </ol>
          </p>
        </EuiText>
        <StyledSpacer size="32px" />
        <RiskManagementTable
          title=""
          columns={[
            { label: 'Type', field: 'type' },
            { label: '% of Total Income', field: 'percent', formatter: formatPercent },
            { label: 'Monthly', field: 'monthly', type: 'number', formatter: formatMonthly },
            { label: 'Annual', field: 'annual', type: 'number', formatter: formatAnnually },
          ]}
          data={displayItems}
          onSave={saveItem}
          onDelete={removeItem}
          addLabel="Add Insurance & Premium Payments"
          onAdd={() => addItem('', '')}
          totalIncome={totalIncome}
        />
      </>
    ),
    onNext
  });
};

export default MainForm;
