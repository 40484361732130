import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cloneDeep, map, pick } from "lodash";

import {
  Box,
  Grid,
  makeStyles,
  Typography,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSuperSelect
} from "@elastic/eui";

import { profileBuilderStyles } from "src/theme";

import CenterContainer from "../../Components/CenterContainer";
import Button from "src/components/Button";
import {
  getMyFedLoanPayments,
  getSpouseFedLoanPayments,
} from "src/store/account/selector";
import {
  getLiabilities,
  savePlan,
  updateCurrentPlan,
} from "src/store/planBuild/actions";
import {
  currentPlanIncomeTotal,
  getCurrentPlan,
  getLiabilities as selectLiabilities,
  getRawIncomes,
  getStudentLoanData,
  isCurrentPlanImplemented,
} from "src/store/planBuild/selector";
import { updateDebts } from "src/store/profileBuild/actions";
import {
  getProfile,
  getProfileRepayment,
  getSpouseProfile,
  getSpouseProfileRepayment,
} from "src/store/profileBuild/selector";
import { getIsCurrentStudent, getIsMarried } from "src/store/system/selector";
import {
  DEBT_TYPES,
  Plan,
  PlanViewComponent,
  REPAYMENT_PLANS,
  SidebarContent,
  SPECIAL_REPAYMENTS,
} from "src/interfaces";
import { CURATED_PLAN_BUILD_STEPS } from "./common";
import PercentBreakdown from "src/components/PercentBreakdown";
import { StyledSpacer } from "src/components/Global/StyledComponents";

import { Global, css } from "@emotion/react";

const catStyles = css`
  .close-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const useStyles = makeStyles(profileBuilderStyles);

const blowout: SidebarContent[] = [
  {
    header: "There are 2 primary repayment strategies:",
    body: [
      "1) You can elect to pay off your loans.",
      'You can either pay off your loan(s) "as is" using the Standard 10 year plan or extend your loans to reduce your required monthly payment by using the Extended Fixed plan. The latter provides more flexibility, but increases the total interest paid over time if you take longer than 10 years to payback your loan(s).',
      "2) Loan forgiveness strategies (IBR, PAYE, and REPAYE/SAVE).",
      "If you wish to pursue Public Service Loan Forgiveness (PSLF), you must select one of the income-driven plans above as PSLF is only eligible with certain repayment plans.",
    ],
  },
];

// type indicates entry from edit flow
// curated index indicates entry from
const AddOrEditStudentLoan: PlanViewComponent = ({
  initialValues,
  onClose,
  onSave,
  render,
  curatedIndex,
}) => {
  const editFlow = !!initialValues;
  const curatedFlow = curatedIndex === CURATED_PLAN_BUILD_STEPS.STUDENT_LOAN;
  const styles = useStyles();
  const dispatch = useDispatch();
  const plan: Plan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const incomes = useSelector(getRawIncomes);
  const studentloan = useSelector(getStudentLoanData);
  const myProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const profileRepayment = useSelector(getProfileRepayment);
  const spouseProfileRepayment = useSelector(getSpouseProfileRepayment);
  const liabilities = useSelector(selectLiabilities);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
  const myFedLoanPayments = useSelector(getMyFedLoanPayments);
  const spouseFedLoanPayments = useSelector(getSpouseFedLoanPayments);
  const myStudentLoanLiability = currentPlanIsImplemented
    ? myFedLoanPayments * 12
    : liabilities.min.solo[0].fed_loan || 0;
  const spouseStudentLoanLiability = currentPlanIsImplemented
    ? spouseFedLoanPayments * 12
    : liabilities.min.solo[1].fed_loan || 0;
  const myStudentLoanEntry = plan.studentloan[0];
  const spouseStudentLoanEntry = plan.studentloan[1] || {};
  let initialWho = curatedFlow ? "applicant" : isMarried ? "" : "applicant";
  initialWho = editFlow ? initialValues.who : initialWho;
  let min =
    initialWho === "spouse"
      ? spouseStudentLoanLiability
      : myStudentLoanLiability;
  const myAllocation = plan.allocations[0].solo[0]?.fed_loan || 0;
  const spouseAllocation = plan.allocations[0].solo[1]?.fed_loan || 0;
  const initialAllocation =
    initialWho === "spouse" ? spouseAllocation : myAllocation;
  const studentLoanEntry =
    initialWho === "spouse" ? spouseStudentLoanEntry : myStudentLoanEntry;
  const initialFormValues = {
    contribution: Math.max(initialAllocation, min),
    repayplan: studentLoanEntry?.repayplan || profileRepayment || "std_plan",
    filing_jointly: plan.profile.filing_jointly ? "y" : "n",
    idroption: studentLoanEntry?.idroption || "standard",
    strategy: studentLoanEntry?.strategy || "high_interest_rate",
    who: initialWho,
  };
  if (currentPlanIsImplemented) {
    if (initialFormValues.who === "spouse") {
      if (spouseProfileRepayment) {
        initialFormValues.repayplan = spouseProfileRepayment;
      }
    } else {
      if (profileRepayment) {
        initialFormValues.repayplan = profileRepayment;
      }
    }
  }
  const [dirty, setDirty] = useState(false);
  const [formValues, setFormValues] = useState<any>(initialFormValues);
  min =
    formValues.who === "spouse"
      ? spouseStudentLoanLiability
      : myStudentLoanLiability;

  const inschool = !!plan.education;

  useEffect(() => {
    // Initialize the min payments with the current values on the form
    estimateMinPayments("repayplan", formValues);
  }, []);

  useEffect(() => {
    return () => {
      if (dirty) {
        estimateMinPayments("repayplan", initialFormValues);
      }
    };
  }, [dirty]);

  const save = () => {
    if (currentPlanIsImplemented && !isCurrentStudent) {
      const profile = formValues.who === "spouse" ? spouseProfile : myProfile;
      if (formValues.repayplan !== profile.fed_repayment_plan) {
        dispatch(
          updateDebts({
            who: formValues.who,
            update: { fed_repayment_plan: formValues.repayplan },
          })
        );
      }
    }
    const soloAllocations = [...plan.allocations[0].solo];
    const whoIndex = formValues.who === "applicant" ? 0 : 1;
    soloAllocations[whoIndex] = {
      ...soloAllocations[whoIndex],
      fed_loan: formValues.contribution,
    };
    const planAllocations = [...plan.allocations];
    planAllocations[0] = { ...planAllocations[0] };
    planAllocations[0].solo = soloAllocations;
    planAllocations[0].fed_loan =
      (soloAllocations[0]?.fed_loan || 0) + (soloAllocations[1]?.fed_loan || 0);
    const updatedStudentloan: any = [...plan.studentloan];
    updatedStudentloan[whoIndex] = {
      ...pick(studentloan[whoIndex], [
        "perkinscancel",
        "contribution",
        "repayplan",
        "idroption",
        "strategy",
        "who",
        "start",
      ]),
      ...pick(formValues, ["repayplan", "idroption", "strategy", "who"]),
    };
    const planProfile = { ...plan.profile };
    planProfile.filing_jointly = formValues.filing_jointly === "y";
    dispatch(
      updateCurrentPlan({
        allocations: planAllocations,
        studentloan: updatedStudentloan,
        profile: planProfile,
      })
    );
    dispatch(savePlan(null));
    onSave();
  };

  const setFormWho = (value: string) => {
    const newFormValues = { ...formValues, who: value };
    setFormValues(newFormValues);
    setDirty(true);
  };

  const setFormValue = (value: string) => {
    const field = "repayplan";
    const newFormValues = { ...formValues, [field]: value };
    setFormValues(newFormValues);
    setDirty(true);
    estimateMinPayments(field, newFormValues);
  };

  const estimateMinPayments = (field: string, newFormValues: any) => {
    if (
      field === "repayplan" ||
      field === "idroption" ||
      field === "strategy" ||
      field === "filing_jointly"
    ) {
      const studentLoanDetails = [
        cloneDeep(studentloan[0]),
        cloneDeep(studentloan[1]),
      ];
      const selectedDetails =
        formValues.who === "spouse"
          ? studentLoanDetails[1]
          : studentLoanDetails[0];
      selectedDetails.repayplan = newFormValues.repayplan;
      selectedDetails.idroption = newFormValues.idroption;
      selectedDetails.strategy = newFormValues.strategy;
      dispatch(
        getLiabilities({
          filing_jointly: newFormValues.filing_jointly === "y",
          inschool,
          incomes: incomes || [],
          studentloan: studentLoanDetails,
          allocations: plan.allocations[0],
        })
      );
    }
  };

  const handleWho = (value: string) => {
    const newFormValues = { ...formValues, who: value };

    if (value === "spouse") {
      newFormValues.idroption = spouseStudentLoanEntry.idroption;
      newFormValues.strategy = spouseStudentLoanEntry.strategy;
      newFormValues.repayplan = spouseProfileRepayment;
      newFormValues.contribution = plan.allocations[0].solo[1]?.fed_loan || 0;
    } else {
      newFormValues.idroption = myStudentLoanEntry.idroption;
      newFormValues.strategy = myStudentLoanEntry.strategy;
      newFormValues.repayplan = profileRepayment;
      newFormValues.contribution = plan.allocations[0].solo[0]?.fed_loan || 0;
    }

    setDirty(true);
    setFormValues(newFormValues);
  };

  const nextDisabled = (formValues.contribution || 0) < min;

  return render({
    component: (
      <>
        <Global styles={catStyles} />
        <EuiFlexGroup className="ai-flex-content">
          <EuiFlexItem>
            <div className="ai-content-title">
              <EuiFlexGroup style={{ gap: 0 }}>
                <EuiFlexItem grow={false}>
                  <h1>Federal Student Loan</h1>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    // onClick={() => toggleFlyout("life-goals")}
                    iconType="questionInCircle"
                    aria-label="Help"
                    className="help-icon"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiFlexItem>
          {!curatedIndex && (
            <EuiFlexItem grow={false} style={{ alignItems: 'flex-end' }}>
              <EuiButtonIcon
                color="text"
                iconType="cross"
                aria-label="Close"
                onClick={onClose}
                className="close-icon"
              />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
        {editFlow && formValues.who === "" && (
          <>
            <StyledSpacer size="32px" />
            <EuiFormRow
              label="Whose loan is this?"
            >
              <EuiSuperSelect
                options={[
                  { value: "applicant", inputDisplay: "Mine" },
                  { value: "spouse", inputDisplay: "My spouse's" },
                ]}
                valueOfSelected={initialValues.who}
                onChange={handleWho}
                hasDividers
              />
            </EuiFormRow>
          </>
        )}
        {formValues.who === "" && (
          <>
            <StyledSpacer size="32px" />
            <EuiFormRow
              label="Whose contribution is this?"
            >
              <EuiSuperSelect
                options={[
                  { value: "applicant", inputDisplay: "Mine" },
                  { value: "spouse", inputDisplay: "My spouse's" },
                ]}
                valueOfSelected={formValues.who}
                onChange={setFormWho}
                hasDividers
              />
            </EuiFormRow>
          </>
        )}
        {!!formValues.who && (
          <>
            <StyledSpacer size="32px" />
            <EuiFormRow
              label={
                <span>
                  Choose your{" "}
                  {formValues.who === "spouse" &&
                    curatedIndex !== CURATED_PLAN_BUILD_STEPS.STUDENT_LOAN
                    ? "spouse's "
                    : ""}
                  student loan repayment plan:
                </span>
              }
            >
              <EuiSuperSelect
                options={map(REPAYMENT_PLANS, (label: string, key: string) => ({
                  value: key,
                  inputDisplay: label,
                }))}
                valueOfSelected={formValues.repayplan}
                onChange={setFormValue}
                hasDividers
              />
            </EuiFormRow>
            {SPECIAL_REPAYMENTS.indexOf(formValues.repayplan) >= 0 && (
              <>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label="Select one:"
                >
                  <EuiSuperSelect
                    options={[
                      { value: "standard", inputDisplay: "Pursuing forgiveness and facing tax liability" },
                      { value: "pslf", inputDisplay: "Pursuing PSLF" },
                      { value: "payoff", inputDisplay: "Will eventually pay off my loans" },
                    ]}
                    valueOfSelected={formValues.idroption}
                    onChange={(value) => setFormValues({ ...formValues, idroption: value })}
                    hasDividers
                  />
                </EuiFormRow>
                {isMarried && (
                  <>
                    <StyledSpacer size="32px" />
                    <EuiFormRow
                      label="How do you plan on filing your taxes?"
                    >
                      <EuiSuperSelect
                        options={[
                          { value: "y", inputDisplay: "Jointly" },
                          { value: "n", inputDisplay: "Separately" },
                        ]}
                        valueOfSelected={formValues.filing_jointly}
                        onChange={(value) =>
                          setFormValues({ ...formValues, filing_jointly: value })
                        }
                        hasDividers
                      />
                    </EuiFormRow>
                  </>
                )}
              </>
            )}
            <StyledSpacer size="32px" />
            <PercentBreakdown
              min={min}
              income={totalIncome}
              onChange={setFormValues}
              values={formValues}
              label={DEBT_TYPES["fed_loan"]}
              isDebt
            />
          </>
        )}
      </>
    ),
    nextDisabled,
    nextLabel: "Save",
    nextTooltip: nextDisabled
      ? "You must enter at least the minimum contribution."
      : undefined,
    onNext: save,
  });
};

export default AddOrEditStudentLoan;
