import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Typography } from "@material-ui/core";

import { EuiButtonIcon, EuiFormRow, EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import { StyledSpacer } from "src/components/Global/StyledComponents";

import { useStyles } from "../styles";

import Button from "src/components/Button";
import { PlanViewComponent, DEBT_TYPES } from "src/interfaces";
import { savePlan, updateAllocations } from "src/store/planBuild/actions";
import {
  currentPlanIncomeTotal,
  getCurrentPlan,
  getLiabilities,
} from "src/store/planBuild/selector";

import CenterContainer from "../../../Components/CenterContainer";
import PercentBreakdown from "src/components/PercentBreakdown";

const PersonalLoan: PlanViewComponent = ({
  onClose,
  onSave,
  render,
  curatedIndex,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const type = "personal_loan";

  const minLiabilities = useSelector(getLiabilities).min;
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const plan = useSelector(getCurrentPlan);
  const annualLiability = minLiabilities[type];
  const percent = (annualLiability / totalIncome) * 100;
  const [formState, setFormState] = useState<any>({
    type,
    percent,
    contribution: annualLiability,
  });

  const save = () => {
    if (formState.contribution) {
      const newAllocations = {
        ...plan.allocations[0],
        [type]: formState.contribution,
      };
      dispatch(updateAllocations(newAllocations));
      dispatch(savePlan(null));
    }
    onSave();
  };

  const min = annualLiability || 0;
  const nextDisabled = formState.contribution < min;

  return render({
    component: (
      <>
        <EuiFlexGroup className="ai-flex-content">
          <EuiFlexItem>
            <div className="ai-content-title">
              <EuiFlexGroup style={{ gap: 0 }}>
                <EuiFlexItem grow={false}>
                  <h1>Personal Loan</h1>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="32px" />
        <EuiText>
          What % of your income would you like to use towards this payment?
        </EuiText>
        <StyledSpacer size="16px" />
        <PercentBreakdown
          min={min}
          income={totalIncome}
          onChange={setFormState}
          values={formState}
          label={DEBT_TYPES["personal_loan"]}
          isDebt
        />
      </>
    ),
    nextLabel: "Save",
    onNext: save,
    nextDisabled,
  });
};

export default PersonalLoan;
