import React from "react";

interface FlyoutInfoProps {
  infoType:
  | "human-capital"
  | "fitbux-score"
  | "children"
  | "percentage-income"
  | "children-ai"
  | "hsa-ai"
  | "add-manual-account"
  | "income-details"
  | "net-wealth"
  | "file-separately"
  | "asset-summary"
  | "debt-summary"
  | "life-goals"
  | "income-goals"
  | "expense-tracking"
  | string;
  componentType:
  | "header"
  | "body"
  | "contentAction"
  | "submitAction"
  | "cancelAction";
}

function FlyoutInfo({ infoType, componentType }: FlyoutInfoProps) {
  let header = "";
  let body: React.ReactNode = "";
  let contentAction: string | undefined;
  let submitAction: string | undefined;
  let cancelAction: string | undefined;

  switch (infoType) {
    case "human-capital":
      header = "Human Capital";
      body = (
        <>
          <p>
            <iframe
              width="100%"
              height="200"
              src="https://www.youtube.com/embed/iWgkM8Wqx_M"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </p>
          <p>
            Human capital is a combination of our future income and the risk to
            that income. It's important because it dictates everything we do
            financially. This includes how we pay down student loans, whether to
            rent or buy a house, how much insurance we need, how to allocate
            investments, among other decisions.
          </p>
          <p>
            Think about a technology business. Their largest assets are
            intangibles (i.e., their code, their technology, copyrights,
            patents, etc.). In the long run, businesses realize the value of
            these things in the form of revenue.
          </p>
          <p>
            Human capital is your intangible asset. However, instead of
            technology, copyrights, and patents, you have education, skills, and
            behavioral traits that have been developed over time. Also, instead
            of monetizing your human capital over time via revenue, you do so in
            the form of income; in other words, you recognize the value of your
            human capital over time in the form of income.
          </p>
          <p>
            Analyzing human capital is one way you can determine trade-offs
            between choices. For example,{" "}
            <a
              href="https://www.fitbux.com/articles/physical-therapist-salary/"
              target="_blank"
            >
              should you get an advanced degree such as your DPT or not
            </a>
            . You can also see what professions have the best financial outlook
            for yourself based on the expected increase in human capital value.
          </p>
        </>
      );
      submitAction = "Done";
      break;
    case "fitbux-score":
      header = "FitBUX Score";
      body = (
        <>
          <p>
            The FitBUX Score is a revolutionary tool in the realm of financial
            planning, designed to make the complex process of managing and
            comparing financial options straightforward and user-friendly. In
            the traditional financial landscape, understanding and comparing
            different financial plans can be a daunting task, often mired in
            pages of dense data and confusing metrics. The FitBUX Score
            simplifies this by distilling your financial information, including
            assets, debts, human capital, and behavioral patterns, into one
            comprehensive, easy-to-understand number. This innovative approach
            allows you to effortlessly compare different financial scenarios and
            make informed decisions without getting lost in the details.
          </p>
          <p>
            With the FitBUX Score, financial planning becomes a breeze. Whether
            you're evaluating loan repayment options, considering investment
            strategies, or planning for future financial goals, this tool
            provides a clear, concise benchmark for comparison. It's like having
            a financial GPS that guides you through the myriad of choices,
            highlighting the impact of each decision on your overall financial
            health. The FitBUX Score empowers you to navigate the financial
            landscape with confidence, providing a straightforward way to assess
            and compare various financial paths. Embrace a smarter, simpler way
            to plan your finances with the FitBUX Score, and take control of
            your financial future with ease and clarity.
          </p>
        </>
      );
      submitAction = "Done";
      break;
    case "children":
      header = "Children";
      body = (
        <>
          <p>
            Children add cost and uncertainty to your financial plan. Therefore,
            you have less cash flow and increased risk. Thus, children reduce
            your FitBUX Score.
          </p>
          <p>
            You can potentially decrease the risk by having the appropriate
            types of insurances (life and disability) as well as manipulating
            your debt payments to increase your budget flexibility. Be sure to
            ask us about these topics when you schedule a call.
          </p>
        </>
      );
      submitAction = "Done";
      break;
    case "children-ai":
      header = "Family Planning Made Easy!";
      body = (
        <>
          <p>
            You can use this life event for multiple purposes. Our technology
            automatically adjust living expenses, investment contributions, and
            debt payment as your family grows.
          </p>
          <p>
            You can also compare the overall impact of staying at home vs
            continuing to work once your children are born. Simply, build two
            plans. One showing you are going to reduce your work, the other
            whereby you stay working. Then compare the results!
          </p>
        </>
      );
      submitAction = "Done";
      break;
    case "percentage-income":
      header = "Thinking In Percentages";
      body = (
        <>
          <p>
            Analyzing your financial plan using percentages is much easier and
            more informative than looking at absolute dollar amounts.
          </p>
          <p>
            <ol>
              <li>
                It makes it easy to visualize the most significant and impactful
                items in your finances. It also makes it easy to make
                adjustments.
              </li>
              &nbsp;
              <li>
                It get rids of the guesswork. For example, if you get a pay
                raise, you don't have to guess the amount you should now
                dedicate to a goal (all else equal).
              </li>
            </ol>
          </p>
          <p>
            You simply have to look at what percentage of your income is
            supposed to go where according to your plan and adjust the dollar
            amount accordingly.
          </p>
        </>
      );
      submitAction = "Done";
      break;
    case "hsa-ai":
      header = "Recommendation For HSA";
      body = (
        <>
          <p>
            If you choose a high deductible health insurance plan through your
            employer and they offer an HSA, we highly recommend contributing at
            least $50 per month to the HSA. Doing so will help with retirement
            income diversification.
          </p>
          <p>
            Retirement income diversification is when you hold your assets in as
            many different types of accounts as you can. Therefore, at
            retirement, if the government changes how they tax one type of an
            account, you have others that won't be affected.
          </p>
          <h4>What is a Health Savings Account?</h4>
          <p>
            Health Savings Accounts (HSA for short) are tax advantaged accounts
            with special features for withdrawals related to health care costs.
            Below is a summary of features:
          </p>
          <p>
            <ol>
              <li>
                Contributions you make each year reduce how much you pay in
                taxes in the year the contribution was made.
              </li>
              &nbsp;
              <li>
                You invest the money and you don't pay taxes each year on
                investment gains.
              </li>
              &nbsp;
              <li>
                If you have a medical expense, you can take the money out
                anytime you want and not pay penalties or taxes on it.
              </li>
              &nbsp;
              <li>
                After age 65, you can withdraw the money for any reason without
                penalty. However, if you take the money out for non-medical
                reasons, you'll owe income tax on it.
              </li>
            </ol>
          </p>
        </>
      );
      submitAction = "Done";
      break;
    case "income-details":
      header = "Your Income & Deductions";
      body = (
        <>
          <p>
            To track your financial plan correctly, you need to know how your
            overall income is allocated using percentages.
          </p>
          <p>
            Your primary goal should be getting 50% of you Total Income
            allocated to money for your future self (in order to grow your
            assets and/or pay off debts)!
          </p>
          <p>
            Total Income should be broken out into salary and any other sources
            of income, such as bonuses or commission. This is important because
            salary and commissions have different risks which are reflected in
            your FitBUX Score and our recommendations. Also, income such as
            stipends are taxed differently. Total Income is the same as Gross
            Income.
          </p>
          <p>
            Deductions should be any item taken directly out of your paycheck
            before the money hits your bank account.
          </p>
        </>
      );
      submitAction = "Back";
      break;
    case "life-goals":
      header = "Setting Goals";
      body = (
        <>
          <p>
            Once you've decided your destination, it's key to identify how to best get there.
          </p>
          <ol>
            <li>
              List your short-term and long-term goals.
            </li>
            &nbsp;
            <li>
              Prioritize 2-3 goals and go after them hard, one at a time. This is key to remain focused.
            </li>
            &nbsp;
            <li>
              Short-term goals take precedence over long-term goals. Chances are, you won't hit your long-term goals if you don't hit your short-term goals first.
            </li>
          </ol>
          <p>
            <u>Comparing Options</u><br />
            We provide an easy way to simulate different plans. Simply select your goals on this screen, build, and save a plan.
            You can then build a second plan, choose different goals, and compare the outcomes between the two plans.
          </p>
        </>
      );
      submitAction = "Back";
      break;
    case "income-goals":
      header = "Income Should Be Your Focus!";
      body = (
        <>
          <p>
            Many people stress about cutting costs. In fact, they spend hours, days, and weeks researching items that may only save them a few dollars a month.
          </p>
          <p>
            Although cutting costs is good, spending too much time on it is not. Instead, that time should be focused on figuring out how to grow your income.
          </p>
          <p>
            Remember, there is a limit to how much you can cut expenses. However, income can grow infinitely!
          </p>
        </>
      );
      submitAction = "Done";
      break;
    case "expense-tracking":
      header = "A Trick To Cut Expenses";
      body = (
        <>
          <p>
            One big mistake people make is trying to cut all their expenses at once, i.e. they try to change their lifestyles overnight. This is why so many people fail financially and stop pursuing a financial plan after six months.
          </p>
          <p>
            Instead, you should only pick one item on your day-to-day expenses. For 40 days, do not spend money on that item. For example, do not watch a movie or Netflix for 40 days.
          </p>
          <p>
            Most of the time, after the 40 days, you'll realize you can live without the item and won't go back to spending money on it or you'll spend less on it.
          </p>
          <p>
            Do this exercise over a year and you'll reduce spending on 8-10 items! That will make a drastic impact on not only your financials, but also your life.
          </p>
        </>
      );
      submitAction = "Done";
      break;
    case "file-separately":
      header = "Should You File Taxes Jointly Or Separately?";
      body = (
        <iframe
          width="100%"
          height="200"
          src="https://www.youtube.com/embed/sfnQEtKq5ZY"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
      submitAction = "Close";
      break;
    case "net-wealth":
      header = "Net Wealth";
      body = (
        <>
          <p>Text</p>
        </>
      );
      submitAction = "Done";
      break;
    case "asset-summary":
      header = "Understanding Financial Assets";
      body = (
        <>
          <p>Text</p>
        </>
      );
      submitAction = "Done";
      break;
    case "debt-summary":
      header = "Understanding Debt";
      body = (
        <>
          <p>Text</p>
        </>
      );
      submitAction = "Done";
      break;
    default:
      header = "Default Header";
      body = "Default Information";
  }

  if (componentType === "header") {
    return header;
  } else if (componentType === "body") {
    return body;
  } else if (componentType === "contentAction") {
    return contentAction || "";
  } else if (componentType === "submitAction") {
    return submitAction || "";
  } else if (componentType === "cancelAction") {
    return cancelAction || "";
  } else {
    return null;
  }
}

export default FlyoutInfo;
