import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cloneDeep, map, pick } from "lodash";

import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSuperSelect,
} from "@elastic/eui";

import { StyledSpacer } from "src/components/Global/StyledComponents";

import { profileBuilderStyles } from "src/theme";

import CenterContainer from "../../../Components/CenterContainer";
import Button from "src/components/Button";
import { getLiabilities, updateCurrentPlan } from "src/store/planBuild/actions";
import { getSpouseFedLoanPayments } from "src/store/account/selector";
import {
  currentPlanIncomeTotal,
  getCurrentPlan,
  getLiabilities as selectLiabilities,
  getRawIncomes,
  getStudentLoanData,
} from "src/store/planBuild/selector";
import { getProfileRepayment } from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import {
  DEBT_TYPES,
  Plan,
  PlanViewComponent,
  REPAYMENT_PLANS,
  SidebarContent,
  SPECIAL_REPAYMENTS,
} from "src/interfaces";
import PercentBreakdown from "src/components/PercentBreakdown";

const blowout: SidebarContent[] = [
  {
    header: "There are 2 primary repayment strategies:",
    body: [
      "1) You can elect to pay off your loans.",
      'You can either pay off your loan(s) "as is" using the Standard 10 year plan or extend your loans to reduce your required monthly payment by using the Extended Fixed plan. The latter provides more flexibility, but increases the total interest paid over time if you take longer than 10 years to payback your loan(s).',
      "2) Loan forgiveness strategies (IBR, PAYE, and REPAYE/SAVE).",
      "If you wish to pursue Public Service Loan Forgiveness (PSLF), you must select one of the income-driven plans above as PSLF is only eligible with certain repayment plans.",
    ],
  },
];

const AddOrEditStudentLoanSpouse: PlanViewComponent = ({
  onClose,
  onSave,
  render,
}) => {
  const dispatch = useDispatch();
  const plan: Plan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const incomes = useSelector(getRawIncomes);
  const studentloan = useSelector(getStudentLoanData);
  const profileRepayment = useSelector(getProfileRepayment);
  const liabilities = useSelector(selectLiabilities);
  const spouseFedLoanPayments = useSelector(getSpouseFedLoanPayments);
  const spouseStudentLoanLiability = Math.max(
    spouseFedLoanPayments,
    liabilities.min.solo[1].fed_loan || 0
  );
  const spouseStudentLoanEntry = plan.studentloan[1];
  const initialFormValues = {
    contribution: Math.ceil(spouseStudentLoanLiability),
    repayplan:
      spouseStudentLoanEntry?.repayplan || profileRepayment || "std_plan",
    filing_jointly: plan.profile.filing_jointly ? "y" : "n",
    idroption: spouseStudentLoanEntry?.idroption || "standard",
    strategy: spouseStudentLoanEntry?.strategy || "high_interest_rate",
    who: "spouse",
  };
  const [formValues, setFormValues] = useState<any>(initialFormValues);
  useEffect(() => {
    // Initialize the min payments with the current values on the form
    estimateMinPayments("repayplan", formValues);
  }, [formValues]);

  const inschool = !!plan.education;

  const save = () => {
    const soloAllocations = [...plan.allocations[0].solo];
    const whoIndex = formValues.who === "applicant" ? 0 : 1;
    soloAllocations[whoIndex] = {
      ...soloAllocations[whoIndex],
      fed_loan: formValues.contribution,
    };
    const planAllocations = [...plan.allocations];
    planAllocations[0] = { ...planAllocations[0] };
    planAllocations[0].solo = soloAllocations;
    planAllocations[0].fed_loan =
      (soloAllocations[0]?.fed_loan || 0) + (soloAllocations[1]?.fed_loan || 0);
    const updatedStudentloan: any = [...plan.studentloan];
    updatedStudentloan[whoIndex] = {
      ...pick(studentloan[whoIndex], [
        "perkinscancel",
        "contribution",
        "repayplan",
        "idroption",
        "strategy",
        "who",
        "start",
      ]),
      ...pick(formValues, ["repayplan", "idroption", "strategy", "who"]),
    };
    const planProfile = { ...plan.profile };
    planProfile.filing_jointly = formValues.filing_jointly === "y";
    dispatch(
      updateCurrentPlan({
        allocations: planAllocations,
        studentloan: updatedStudentloan,
        profile: planProfile,
      })
    );
    onSave();
  };

  const setFormValue = (value: string, field: string) => {
    const newFormValues = { ...formValues, [field]: value };
    setFormValues(newFormValues);
    estimateMinPayments(field, newFormValues);
  };

  const estimateMinPayments = (field: string, newFormValues: any) => {
    if (
      field === "repayplan" ||
      field === "idroption" ||
      field === "strategy" ||
      field === "filing_jointly"
    ) {
      const studentLoanDetails = [
        cloneDeep(studentloan[0]),
        cloneDeep(studentloan[1]),
      ];
      const selectedDetails =
        formValues.who === "spouse"
          ? studentLoanDetails[1]
          : studentLoanDetails[0];
      selectedDetails.repayplan = newFormValues.repayplan;
      selectedDetails.idroption = newFormValues.idroption;
      selectedDetails.strategy = newFormValues.strategy;
      dispatch(
        getLiabilities({
          filing_jointly: newFormValues.filing_jointly === "y",
          inschool,
          incomes: incomes || [],
          studentloan: studentLoanDetails,
          allocations: plan.allocations[0],
        })
      );
    }
  };

  const min = spouseStudentLoanLiability;

  const nextDisabled = (formValues.contribution || 0) < min;

  return render({
    component: (
      <>
        <EuiFlexGroup className="ai-flex-content">
          <EuiFlexItem>
            <div className="ai-content-title">
              <EuiFlexGroup style={{ gap: 0 }}>
                <EuiFlexItem grow={false}>
                  <h1>Federal Student Loan</h1>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    // onClick={() => toggleFlyout("life-goals")}
                    iconType="questionInCircle"
                    aria-label="Help"
                    className="help-icon"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="32px" />
        <EuiFormRow
          label="Choose your spouse's student loan repayment plan:"
        >
          <EuiSuperSelect
            options={map(REPAYMENT_PLANS, (label: string, key: string) => ({
              value: key,
              inputDisplay: label,
            }))}
            valueOfSelected={formValues.repayplan}
            onChange={(value) => setFormValue(value, "repayplan")}
            hasDividers
          />
        </EuiFormRow>
        {SPECIAL_REPAYMENTS.indexOf(formValues.repayplan) >= 0 && (
          <>
            <StyledSpacer size="32px" />
            <EuiFormRow label="Select one:">
              <EuiSuperSelect
                options={[
                  {
                    value: "standard",
                    inputDisplay:
                      "Pursuing forgiveness and facing tax liability",
                  },
                  { value: "pslf", inputDisplay: "Pursuing PSLF" },
                  {
                    value: "payoff",
                    inputDisplay: "Will eventually pay off my loans",
                  },
                ]}
                valueOfSelected={formValues.idroption}
                onChange={(value) => setFormValue(value, "idroption")}
                hasDividers
              />
            </EuiFormRow>
            {isMarried && (
              <>
                <StyledSpacer size="32px" />
                <EuiFormRow label="How do you and your spouse plan on filing your taxes?">
                  <EuiSuperSelect
                    options={[
                      { value: "y", inputDisplay: "Jointly" },
                      { value: "n", inputDisplay: "Separately" },
                    ]}
                    valueOfSelected={formValues.filing_jointly}
                    onChange={(value) =>
                      setFormValue(value, "filing_jointly")
                    }
                    hasDividers
                  />
                </EuiFormRow>
              </>
            )}
          </>
        )}
        <StyledSpacer size="32px" />
        <PercentBreakdown
          min={min}
          income={totalIncome}
          onChange={setFormValues}
          values={formValues}
          label={DEBT_TYPES["fed_loan"]}
          isDebt
        />
      </>
    ),
    nextDisabled,
    nextLabel: "Save",
    nextTooltip: nextDisabled
      ? "You must enter at least the minimum contribution."
      : undefined,
    onNext: save,
  });
};

export default AddOrEditStudentLoanSpouse;