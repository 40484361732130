import React from "react";
import { useDispatch, useSelector } from "react-redux";
import colors from "src/theme/colors";
import { additionalQuestionsBlowout } from "./blowoutContent";

import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import {
  EuiText,
  EuiButtonIcon,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiFieldText,
  EuiCallOut,
} from "@elastic/eui";

import { StyledBsAsterisk, StyledSpacer, StyledSpan } from "src/components/Global/StyledComponents";

import xIcon from "src/assets/svgs/x.svg";
import equalsIcon from "src/assets/svgs/equal.svg";

import {
  savePlan,
  setBuildStep,
  updateCurrentPlan,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  getCurrentPlan,
  getEmergencyMonthlyExpenses,
} from "src/store/planBuild/selector";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import { Plan } from "src/interfaces/plan.interface";
import { formatMonthly, formatWholeDollars } from "src/utils";
import CenterContainer from "../../Components/CenterContainer";
import { cloneDeep } from "lodash";
import ToolTipIcon from "src/components/ToolTipIcon";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import { equal } from "assert";

const efStyles = css`
  .months-input {
    max-width: 200px !important;
  }
`;

const StyledEuiIcon = styled(EuiIcon)`
  width: 16px;
  height: 16px;
`;

const EmergencyFund: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const monthlyExpenses = useSelector(getEmergencyMonthlyExpenses);
  const plan: Plan = useSelector(getCurrentPlan);
  const myStudentLoanEntry = plan.studentloan[0];

  const handleSetMonths = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    dispatch(updateCurrentPlan({ emergencymonths: +value }));
    dispatch(savePlan(null));
  };

  const handleChangePriority = (e: React.ChangeEvent<any>) => {
    const planProfile = {
      ...plan.profile,
      priority: e.target.value,
    };
    dispatch(updateCurrentPlan({ profile: planProfile }));
    dispatch(savePlan(null));
  };

  const handleChangePrepay = (e: React.ChangeEvent<any>) => {
    const value = e.target.value;
    const updatedStudentloan = cloneDeep(plan.studentloan);
    updatedStudentloan[0].strategy = value;
    updatedStudentloan[1].strategy = value;
    dispatch(updateCurrentPlan({ studentloan: updatedStudentloan }));
    dispatch(savePlan(null));
  };

  const onNext = () => {
    dispatch(setBuildStep(PLAN_BUILD_STEPS.GOALS));
  };

  // const isMoneyNegative = remainingMoney < 0;

  // const nextTooltip = !isMoneyNegative
  //   ? undefined
  //   : `Vacation / Fun money is negative, which means you are over saving. Go back to steps 1, 2, 3 and edit your plan so vacation / fun money is positive before you continue.`;

  const priority = plan.profile.priority || "debt";
  const months = plan.emergencymonths;

  return render({
    component: (
      <>
        <Global styles={efStyles} />
        <EuiFlexGroup className="ai-flex-content">
          <EuiFlexItem>
            <div className="ai-content-title">
              <EuiFlexGroup style={{ gap: 0 }}>
                <EuiFlexItem grow={false}>
                  <h1>Emergency Fund</h1>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    // onClick={() => toggleFlyout("life-goals")}
                    iconType="questionInCircle"
                    aria-label="Help"
                    className="help-icon"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="16px" />
        <EuiText className="ai-secondary-text">
          <p>
            An emergency fund reflects a cushion of savings that allows one to meet necessary monthly expenses and debt payments. When your monthly expenses and debt payments and totaled up, multiply that by the number of months that makes you comfortable. The result is how much you should have saved in your checking and savings account(s).
          </p>
          <p style={{ fontWeight: 'bold' }}>
            Enter how much of a emergency fund you would like to have. This will add a goal to your financial plan that FitBUX will track for you.
          </p>
        </EuiText>
        <StyledSpacer size="32px" />
        <EuiFlexGroup justifyContent="flexStart" style={{ gap: '2rem' }}>
          <EuiFlexItem grow={false}>
            <EuiFormRow
              label={<StyledSpan>Number of Months<StyledBsAsterisk /></StyledSpan>}
            >
              <EuiFieldText
                id="emergencymonths"
                name="emergencymonths"
                fullWidth
                onChange={handleSetMonths}
                type="number"
                value={plan.emergencymonths}
                className="months-input"
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ justifyContent: 'flex-end' }}>
            <StyledEuiIcon
              type={xIcon}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ justifyContent: 'flex-end' }}>
            <EuiFormRow
              label={<StyledSpan>Monthly expenses</StyledSpan>}
            >
              <EuiText>{formatMonthly(monthlyExpenses)}</EuiText>
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ justifyContent: 'flex-end' }}>
            <StyledEuiIcon
              type={equalsIcon}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ justifyContent: 'flex-end' }}>
            <EuiFormRow
              label={<StyledSpan>Total</StyledSpan>}
            >
              <EuiText>{formatWholeDollars(monthlyExpenses * months)}</EuiText>
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="32px" />
        <EuiCallOut
          title="FitBUX Recommends 3 Months for Emergency Fund"
          iconType="iInCircle"
          className="rm-callout"
        >
          <p>
            FitBUX's AI recommends this number based on a number of factors from your profile. We take into account your profession, the risk to your income, debt levels, and more.
          </p>
        </EuiCallOut>
      </>
    ),
    onNext,
  });
};

export default EmergencyFund;
