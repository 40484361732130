import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fbColors from "src/theme/colors";
import { Box, makeStyles } from "@material-ui/core";

import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem
} from "@elastic/eui";

import Button from "src/components/Button";
import {
  ASSET_ALLOCATIONS,
  DEBT_ALLOCATIONS,
  SPECIAL_ALLOCATIONS,
  TAX_DEFERRED_INVESTMENT_TYPES,
  PlanViewComponent,
  HouseholdMinLiabilities,
} from "src/interfaces";
import { getDebtObligations } from "src/store/account/selector";
import {
  savePlan,
  setPlanDirty,
  updateAllocations,
  estimateCurrentPlanTaxes,
} from "src/store/planBuild/actions";
import {
  currentPlanIncomeTotal,
  getCurrentPlan,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";

import AssetCategories from "./AssetCategories";
import AddEdit401k from "./AddEdit401k";
import AddEditIRA from "./AddEditIRA";
import FedStudentLoan from "./FedStudentLoan";
import FedStudentLoanSpouse from "./CuratedSpouseViews/FedStudentLoanSpouse";
import CenterContainer from "../../Components/CenterContainer";
import ShortTermSavingsGoal from "./ShortTermSavingsGoal";
import Hsa from "./Hsa";
import InvestVSPayDebt from "./InvestVSPayDebt";
import { CURATED_PLAN_BUILD_STEPS } from "./common";
import DebtPrepayment from "./DebtPrepayments";
import PerkinsLoan from "./Debts/PerkinsLoan";
import PrivateLoan from "./Debts/PrivateLoans";
import CreditCardLoan from "./Debts/CreditCardLoan";
import PersonalLoan from "./Debts/PersonalLoan";
import PrimaryMortgage from "./Debts/PrimaryMortgage";
import InvestmentProperty from "./Debts/InvestmentProperty";
import AutoLoan from "./Debts/AutoLoan";
import OtherDebt from "./Debts/OtherDebt";
import RothIRA from "./RothIRA";
import RothIRASpouse from "./CuratedSpouseViews/RothIRASpouse";

import AddOrEdit401kSpouse from "./CuratedSpouseViews/AddEdit401kSpouse";
import { HSALimit, HSALimitMarried } from "src/constants";
import PercentBreakdown from "src/components/PercentBreakdown";
import { Global, css } from "@emotion/react";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const catStyles = css`
  .close-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const curatedSteps = {
  [CURATED_PLAN_BUILD_STEPS.SHORT_TERM_GOAL]: ShortTermSavingsGoal,
  [CURATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT]: AddEdit401k,
  [CURATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT_SPOUSE]: AddOrEdit401kSpouse,
  [CURATED_PLAN_BUILD_STEPS.HSA]: Hsa,
  [CURATED_PLAN_BUILD_STEPS.ROTH_IRA]: RothIRA,
  [CURATED_PLAN_BUILD_STEPS.ROTH_IRA_SPOUSE]: RothIRASpouse,
  [CURATED_PLAN_BUILD_STEPS.INVEST_OR_PAYOFF]: InvestVSPayDebt,
  [CURATED_PLAN_BUILD_STEPS.STUDENT_LOAN]: FedStudentLoan,
  [CURATED_PLAN_BUILD_STEPS.STUDENT_LOAN_SPOUSE]: FedStudentLoanSpouse,
  [CURATED_PLAN_BUILD_STEPS.HIGH_LOW]: DebtPrepayment,
  // [CURATED_PLAN_BUILD_STEPS.MORTGAGE_DEBT]: MortgageDebt,
  [CURATED_PLAN_BUILD_STEPS.PERKINS_LOANS]: PerkinsLoan,
  [CURATED_PLAN_BUILD_STEPS.PRIVATE_LOANS]: PrivateLoan,
  [CURATED_PLAN_BUILD_STEPS.CREDIT_CARD_LOANS]: CreditCardLoan,
  [CURATED_PLAN_BUILD_STEPS.PERSONAL_LOANS]: PersonalLoan,
  [CURATED_PLAN_BUILD_STEPS.PRIMARY_MORTGAGE]: PrimaryMortgage,
  [CURATED_PLAN_BUILD_STEPS.INVESTMENT_PROPERTY_MORTGAGE]: InvestmentProperty,
  [CURATED_PLAN_BUILD_STEPS.AUTO_DEBT]: AutoLoan,
  [CURATED_PLAN_BUILD_STEPS.OTHER_DEBT]: OtherDebt,
};

const AddAssetOrDebt: PlanViewComponent = ({
  editingSpecialType,
  isDebt,
  onClose,
  curatedIndex,
  render,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const isMarried = useSelector(getIsMarried);
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const plan = useSelector(getCurrentPlan);
  const debtObligations = useSelector(getDebtObligations);
  const types = isDebt ? DEBT_ALLOCATIONS : ASSET_ALLOCATIONS;
  const [formState, _setFormState] = useState<any>({
    type: "cash_value",
    percent: "",
  });
  const [showCategories, setShowCategories] = useState(!editingSpecialType);
  const [addingSpecialType, setAddingSpecialType] = useState<any>(
    editingSpecialType
  );
  const setFormState = (value: any) => {
    dispatch(setPlanDirty(true));
    _setFormState(value);
  };
  const allocationType: any =
    types[formState.type as keyof typeof types] || ASSET_ALLOCATIONS.cash_value;
  const typeLabel: string = allocationType.label;
  const unlimitedType = typeLabel.includes("ira");

  const save = () => {
    if (!showCategories && !addingSpecialType) {
      if (formState.contribution) {
        const newAllocations = {
          ...plan.allocations[0],
          [formState.type]: formState.contribution,
        };
        dispatch(updateAllocations(newAllocations));
        if (formState.type in TAX_DEFERRED_INVESTMENT_TYPES) {
          dispatch(estimateCurrentPlanTaxes());
        }
        dispatch(savePlan(null));
      }
    }
    onClose();
  };

  const selectCategory = (category: any) => {
    setShowCategories(false);
    const specialAllocation = [
      ...SPECIAL_ALLOCATIONS,
      "cash_value",
      "hsa_value",
    ];
    if (specialAllocation.indexOf(category.key) >= 0) {
      setAddingSpecialType(category);
    }
    setFormState({ type: category.key, percent: "" });
  };

  const renderSpecialType = () => {
    let SubComponent = null;
    switch (addingSpecialType.key || addingSpecialType.type) {
      case "fed_loan":
        SubComponent = FedStudentLoan;
        break;
      case "roth_401k_value":
      case "401k_value":
        SubComponent = AddEdit401k;
        break;
      case "cash_value":
        SubComponent = ShortTermSavingsGoal;
        break;
      case "hsa_value":
        SubComponent = Hsa;
        break;
      case "roth_ira_value":
      case "ira_value":
      default:
        SubComponent = AddEditIRA;
    }
    return (
      <SubComponent
        initialValues={editingSpecialType}
        onClose={closeSpecialType}
        onSave={save}
        render={render}
        type={addingSpecialType.key || addingSpecialType.type}
      />
    );
  };

  const renderCuratedType = () => {
    const SubComponent =
      curatedSteps[curatedIndex as keyof typeof curatedSteps];
    let type =
      curatedIndex === CURATED_PLAN_BUILD_STEPS.ROTH_IRA ||
        curatedIndex === CURATED_PLAN_BUILD_STEPS.ROTH_IRA_SPOUSE
        ? "ira_value"
        : "";
    type =
      curatedIndex === CURATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT ||
        curatedIndex === CURATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT_SPOUSE
        ? "401k_value"
        : "";
    return (
      <SubComponent
        // initialValues={editingSpecialType}
        onClose={onClose}
        onSave={save}
        render={render}
        curatedIndex={curatedIndex}
        type={type}
      />
    );
  };

  const renderCategories = () => (
    <AssetCategories isDebt={isDebt} onSelect={selectCategory} />
  );

  const closeSpecialType = () => {
    if (editingSpecialType) {
      onClose();
    } else {
      setAddingSpecialType(null);
      setShowCategories(true);
    }
  };

  if (addingSpecialType && (addingSpecialType.type || addingSpecialType.key)) {
    return renderSpecialType();
  } else if (
    curatedIndex &&
    curatedIndex <= CURATED_PLAN_BUILD_STEPS.OTHER_DEBT
  ) {
    return renderCuratedType();
  }

  let max: number | undefined = undefined;
  if (formState.type === "hsa_value") {
    const maxDollars = isMarried ? HSALimitMarried : HSALimit;
    max = maxDollars;
  }
  let min: number | undefined = undefined;
  if (
    typeof debtObligations[formState.type as keyof HouseholdMinLiabilities] ===
    "number"
  ) {
    min = debtObligations[formState.type as keyof HouseholdMinLiabilities] * 12;
  }

  const nextDisabled =
    !showCategories &&
    (formState.contribution < (min || 0) ||
      (!!max && formState.contribution > max));

  return render({
    component: showCategories ? (
      renderCategories()
    ) : (
      <>
        <Global styles={catStyles} />
        <EuiFlexGroup className="ai-flex-content">
          <EuiFlexItem>
            <div className="ai-content-title">
              <EuiFlexGroup style={{ gap: 0 }}>
                <EuiFlexItem grow={false}>
                  <h1>{typeLabel}</h1>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiFlexItem>
          {!curatedIndex && (
            <EuiFlexItem grow={false} style={{ alignItems: 'flex-end' }}>
              <EuiButtonIcon
                color="text"
                iconType="cross"
                aria-label="Close"
                onClick={onClose}
                className="close-icon"
              />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
        <StyledSpacer size="32px" />
        <PercentBreakdown
          min={min}
          income={totalIncome}
          onChange={setFormState}
          values={formState}
          label={typeLabel}
          isDebt={isDebt}
          unlimited={unlimitedType}
          incomeType={unlimitedType ? "Eligible" : "Total"}
        />
      </>
    ),
    nextDisabled,
    nextLabel: "Save",
    onNext: save,
  });
};

export default AddAssetOrDebt;

const useStyles = makeStyles({
  cell: {
    width: "30%",
    fontSize: 12,
  },
  headerCell: {
    fontWeight: "bolder",
    width: "30%",
    fontSize: 12,
    marginBottom: 15,
    color: fbColors.brandingBlue1,
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  btnCancel: {
    display: "block",
    margin: "20px auto",
    width: "calc(100% - 50px)",
  },
});
