import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container } from "@material-ui/core";
import { EuiFlexGroup, EuiFlexItem, EuiCard, EuiLink } from "@elastic/eui";
import { Global, css } from "@emotion/react";

import { IItem } from "./components/MainCard";
import { ViewComponent } from "src/interfaces/viewComponent.interface";
import {
  ADIDS,
  LANGUAGE_LEVEL_ARRAY,
} from "src/store/oldProfileBuild/constants";
import {
  fetchFields,
  fetchProfessions,
  fetchSchools,
} from "src/store/oldProfileBuild/actions";
import { fetchUser } from "src/store/system/actions";
import { useFlyout } from "src/components/Global/FlyoutContext";
import humanCapitalViews from "src/pages/ProfileBuilder/Views/HumanCapital";
import EducationForm from "src/pages/ProfileBuilder/Views/HumanCapital/EducationForm";
import CareerForm from "src/pages/ProfileBuilder/Views/HumanCapital/MainForm";
import { CareerSection, EducationSection, OtherSection } from "src/interfaces";

import {
  getCareer,
  getEducation,
  getProfileLoading,
  getProfileLoaded,
  getOtherHC,
  getSpouseOtherHC,
  getSpouseCareer,
  getSpouseEducation,
} from "src/store/profileBuild/selector";

import {
  getFields,
  getProfessions,
  getSchools,
} from "src/store/oldProfileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const cardStyles = css`
  .hc-card {
    .hc-info-text {
      font-size: 16px;
      line-height: 24px;
    }
    .hc-secondary-text {
      font-size: 14px;
      line-height: 24px;
      color: #343741;
    }
  }
`;

const EducationFlyoutStyles = css`
  .container-fluid {
    padding-top: 0 !important;
  }
  .css-2pd10y {
    padding-top: 0 !important;
  }
  .row {
    margin-top: 0 !important;
  }
  .col-lg-5 {
    max-width: 100%;
    padding: 0;
    flex: auto;
    .euiButtonEmpty:nth-child(1) {
      display: none;
    }
    .euiTitle {
      display: none;
    }
    .euiText {
      display: none;
    }
    .css-1kq0zrc {
      display: none;
    }
    .euiSpacer {
      display: none;
    }
  }
  .offset-lg-2 {
    margin: 0;
  }
`;

const MainForm: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const schools = useSelector(getSchools);
  const loading = useSelector(getProfileLoading);
  const loaded = useSelector(getProfileLoaded);
  const fields = useSelector(getFields);
  const myEducation = useSelector(getEducation);
  const spouseEducation = useSelector(getSpouseEducation);
  const myCareer = useSelector(getCareer);
  const spouseCareer = useSelector(getSpouseCareer);
  const myOtherHC = useSelector(getOtherHC);
  const spouseOtherHC = useSelector(getSpouseOtherHC);
  const { openCustomFlyout } = useFlyout();
  const isMarried = useSelector(getIsMarried);
  const professions = useSelector(getProfessions);

  useEffect(() => {
    dispatch(fetchSchools());
    dispatch(fetchFields());
    dispatch(fetchProfessions());
  }, [dispatch]);

  useEffect(() => {
    if (!loaded.schools && !loading.schools) {
      dispatch(fetchSchools());
    }
    if (!loaded.fields && !loading.fields) {
      // fetch undergrad fiends
      dispatch(fetchFields());
    }
    if (!loaded.professions && !loading.professions) {
      dispatch(fetchProfessions());
    }
  }, [dispatch]);

  const schoolNameById = (id?: number) => {
    if (schools && schools.length) {
      return schools.find((e: any) => e.id === id)?.name;
    }
  };

  const fieldNameById = (id?: number) => {
    if (fields && fields.length) {
      return fields.find((e: any) => e.id === id)?.name || "";
    }
  };

  // const schoolNameByIndex = (index?: number) => {
  //   if (index !== undefined && schools && schools.length) {
  //     return schools[index]?.name || "";
  //   }
  // }

  const generateEducationList = (edu: EducationSection, isMine: boolean) => {
    const spouseLabel = isMine ? "" : " (Spouse)";
    const initialEducations: Array<IItem> = [];

    if (edu.phd_deg === "y") {
      initialEducations.push({
        leftPrimary: "Ph.D" + spouseLabel,
        leftSecondary: schoolNameById(edu.phd_school),
        right: `${edu.phd_grad_month}/${edu.phd_grad_year}`,
      });
    }

    /* advanced */
    if (edu.adv_deg === "y") {
      initialEducations.push({
        leftPrimary:
          `${
            ADIDS.find((e) => e.id === edu.adid)?.name ||
            "Master's or doctorate degree"
          }` + spouseLabel,
        leftSecondary: schoolNameById(edu.adv_school),
        right: `${edu.date_last_school_month}/${edu.date_last_school_year}`, // TODO: check if it's the right date
      });
    }

    /* advanced second */
    if (edu.adv_second_deg === "y") {
      initialEducations.push({
        leftPrimary: edu.adv_second_deg_name + spouseLabel,
        leftSecondary: edu.adv_second_school,
      });
    }

    /* undergrad */
    if (edu.undergrad === "y") {
      const field = fieldNameById(edu.undergrad_field);
      const fieldString = field ? ` (${field})` : "";
      initialEducations.push({
        leftPrimary: `Bachelor's degree${fieldString}` + spouseLabel,
        leftSecondary: schoolNameById(edu.undergrad_school),
        right: edu?.undergrad_grad_month
          ? `${edu.undergrad_grad_month}/${edu.undergrad_grad_year}`
          : "",
      });
    }

    /* aa */
    if (edu.aa_deg === "y") {
      initialEducations.push({
        leftPrimary: "Associate's degree" + spouseLabel,
        leftSecondary: "", // schoolNameById(edu.aa_school),
        right: `${edu.aa_grad_month}/${edu.aa_grad_year}`,
      });
    }

    return initialEducations;
  };

  const generateCareerList = (ca: CareerSection, isMine: boolean) => {
    const spouseLabel = isMine ? "" : " (Spouse)";
    const initialEducations: Array<IItem> = [];
    const profession = professions.find((p: any) => p.id === ca.profession);
    if (profession) {
      initialEducations.push({
        leftPrimary: `${profession.name}` + (isMine ? "" : "(Spouse)"),
        right: `${ca.years_work_exp} year(s)`,
      });
    }
    if (ca.prior_annual_salary) {
      initialEducations.push({
        leftPrimary: `Income for the fist job post-graduation` + spouseLabel,
        right: `$${ca.prior_annual_salary}`,
      });
    }

    return initialEducations;
  };

  const generateOtherHCList = (hc: OtherSection, isMine: boolean) => {
    const spouseLabel = isMine ? "" : " (Spouse)";
    const initialList: Array<IItem> = [];

    if (hc.lang === "y") {
      initialList.push({
        leftPrimary: "Multilingual" + spouseLabel,
        right: "",
      });
    }

    if (hc.lang === "y" && hc.lang_1) {
      initialList.push({
        leftPrimary: hc.lang_1 + spouseLabel,
        right: LANGUAGE_LEVEL_ARRAY[+(hc.lang_speaking_1 || 0)],
      });
    }

    if (hc.lang === "y" && hc.lang_2) {
      initialList.push({
        leftPrimary: hc.lang_2 + spouseLabel,
        right: LANGUAGE_LEVEL_ARRAY[+(hc.lang_speaking_2 || 0)],
      });
    }

    /* Undergrad & Previous: */
    if (hc.qual_gifted_and_talented === "y") {
      initialList.push({
        leftPrimary: "Gifted and talented" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_intl_bacc === "y") {
      initialList.push({
        leftPrimary: "IB graduate" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_ap_scholar === "y") {
      initialList.push({
        leftPrimary: "AP scholar" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_scholarship === "y") {
      initialList.push({
        leftPrimary: "Merit scholarship" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_full_scholarship === "y") {
      initialList.push({
        leftPrimary: "Full scholarship" + spouseLabel,
        right: "",
      });
    }

    /* Skill Sets: */
    if (hc.qual_electronics === "y") {
      initialList.push({
        leftPrimary: `Electronics/electrical` + spouseLabel,
      });
    }

    if (hc.qual_web === "y") {
      initialList.push({
        leftPrimary: `Web Design` + spouseLabel,
      });
    }

    if (hc.qual_work_prog === "y") {
      initialList.push({
        leftPrimary: `Computer Programming` + spouseLabel,
      });
    }

    if (hc.qual_blog === "y") {
      initialList.push({
        leftPrimary: `Blogging/Vlogging` + spouseLabel,
      });
    }

    if (hc.qual_craft === "y") {
      initialList.push({
        leftPrimary: `Crafts and design` + spouseLabel,
      });
    }

    if (hc.qual_autobody === "y") {
      initialList.push({
        leftPrimary: `Automobile DIY projects` + spouseLabel,
      });
    }

    /* Financial Proactivenss */
    if (hc.delinquency === "y") {
      initialList.push({
        leftPrimary: "Loan delinquency" + spouseLabel,
        right: "",
      });
    }

    /* Extracurricular */
    if (hc.qual_club_sports === "y") {
      initialList.push({
        leftPrimary: "Club sports" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_ncaa_sports === "y") {
      initialList.push({
        leftPrimary: "NCAA sports" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_prof_sports === "y") {
      initialList.push({
        leftPrimary: "Professional sports" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_hobby_sports === "y") {
      initialList.push({
        leftPrimary: "Hobby sports" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_marathons === "y") {
      initialList.push({
        leftPrimary: "Marathon/triathlon" + spouseLabel,
        right: "",
      });
    }

    /* Military and Voluneer */
    if (hc.qual_military === "y") {
      initialList.push({
        leftPrimary: "Military service" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_peace_corps === "y") {
      initialList.push({
        leftPrimary: "Peace Corps" + spouseLabel,
        right: "",
      });
    }
    return initialList;
  };

  if (!render) {
    return <div />;
  }

  const renderEducationCard = (educationList: any, isMine: any) => {
    if (educationList.length === 0) return null;
    const handleEditEducation = (isMine: boolean) => {
      const education = isMine ? myEducation : spouseEducation;

      openCustomFlyout({
        title: isMine ? "Edit My Education" : "Edit Spouse's Education",
        component: (props: any) => (
          <EducationForm
            {...props}
            flyoutEducation={education}
            render={({ component }) => <Container>{component}</Container>}
          />
        ),
      });
    };

    return (
      <>
        <Global styles={EducationFlyoutStyles} />
        <EuiCard title className="hc-card">
          <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
            <EuiFlexItem grow={false} className="hc-desc-text">
              <h1>{isMine ? "My Education" : "Spouse's Education"}</h1>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiLink
                className="hc-info-text"
                onClick={() => handleEditEducation(isMine)}
              >
                Edit
              </EuiLink>
            </EuiFlexItem>
          </EuiFlexGroup>
          <StyledSpacer size="24px" />
          {educationList.map((education: any, index: any) => (
            <EuiFlexGroup
              key={index}
              justifyContent="spaceBetween"
              alignItems="center"
            >
              <EuiFlexItem grow={false} style={{ alignItems: "flex-start" }}>
                <h3 className="hc-info-text">{education.leftPrimary}</h3>
                <StyledSpacer size="8px" />
                <p className="hc-secondary-text">{education.leftSecondary}</p>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <p className="hc-info-text">{education.right}</p>
              </EuiFlexItem>
            </EuiFlexGroup>
          ))}
        </EuiCard>
      </>
    );
  };

  const renderCareerCard = (careerList: any, isMine: any) => {
    if (careerList.length === 0) return null;

    const handleEditCareer = (isMine: boolean) => {
      const career = isMine ? myCareer : spouseCareer;
      
      const simplifiedCareer = {
        profession: career.profession,
        years_work_exp: career.years_work_exp,
      };

      openCustomFlyout({
        title: isMine ? "Edit My Career" : "Edit Career",
        component: (props: any) => (
          <CareerForm
            {...props}
            flyoutCareer={simplifiedCareer}
            render={({ component }) => <Container>{component}</Container>}
          />
        ),
      });
    };


    return (
      <EuiCard title className="hc-card">
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          <EuiFlexItem grow={false} className="hc-desc-text">
            <h1>{isMine ? "My Career" : "Spouse's Career"}</h1>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiLink
              className="hc-info-text"
              onClick={() => handleEditCareer(isMine)}
            >
              Edit
            </EuiLink>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="24px" />
        {careerList.map((career: any, index: any) => (
          <EuiFlexGroup
            key={index}
            justifyContent="spaceBetween"
            alignItems="center"
          >
            <EuiFlexItem grow={false} style={{ alignItems: "flex-start" }}>
              <h3 className="hc-info-text">{career.leftPrimary}</h3>
              <StyledSpacer size="8px" />
              <p className="hc-secondary-text">{career.leftSecondary}</p>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <p className="hc-info-text">{career.right}</p>
            </EuiFlexItem>
          </EuiFlexGroup>
        ))}
      </EuiCard>
    );
  };

  const renderOtherHCCard = (otherHCList: any, isMine: any) => {
    if (otherHCList.length === 0) return null;

    // const handleEditClick = () => {
    //   openCustomFlyout({
    //     component: humanCapitalViews[isMine ? PROFILE_BUILD_STEPS.MY_OTHER_HUMAN_CAPITAL : PROFILE_BUILD_STEPS.SPOUSE_OTHER_HUMAN_CAPITAL],
    //     title: isMine ? "Edit Other Human Capital" : "Edit Other Human Capital",
    //     props: {
    //       isMine,
    //       otherHCList,
    //     },
    //   });
    // };

    return (
      <EuiCard title className="hc-card">
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          <EuiFlexItem grow={false} className="hc-desc-text">
            <h1>
              {isMine
                ? "My Other Human Capital"
                : "Spouse's Other Human Capital"}
            </h1>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiLink>Edit</EuiLink>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="24px" />
        {otherHCList.map((otherHC: any, index: any) => (
          <EuiFlexGroup
            key={index}
            justifyContent="spaceBetween"
            alignItems="center"
          >
            <EuiFlexItem grow={false} style={{ alignItems: "flex-start" }}>
              <h3 className="hc-info-text">{otherHC.leftPrimary}</h3>
              <StyledSpacer size="8px" />
              <p className="hc-secondary-text">{otherHC.leftSecondary}</p>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <p className="hc-info-text">{otherHC.right}</p>
            </EuiFlexItem>
          </EuiFlexGroup>
        ))}
      </EuiCard>
    );
  };

  return render({
    component: (
      <>
        <Global styles={cardStyles} />

        {/* Render User's Education Card */}
        {renderEducationCard(generateEducationList(myEducation, true), true)}

        {isMarried && <StyledSpacer size="24px" />}

        {/* Render Spouse's Education Card */}
        {isMarried &&
          renderEducationCard(
            generateEducationList(spouseEducation, false),
            false
          )}

        <StyledSpacer size="24px" />

        {/* Render User's Career Card */}
        {renderCareerCard(generateCareerList(myCareer, true), true)}

        {isMarried && <StyledSpacer size="24px" />}

        {/* Render Spouse's Career Card */}
        {isMarried &&
          renderCareerCard(generateCareerList(spouseCareer, false), false)}

        <StyledSpacer size="24px" />

        {/* Render User's Other HC Card */}
        {renderOtherHCCard(generateOtherHCList(myOtherHC, true), true)}

        {isMarried && <StyledSpacer size="24px" />}

        {/* Render Spouse's Other HC Card */}
        {isMarried &&
          renderOtherHCCard(generateOtherHCList(spouseOtherHC, false), false)}
      </>
    ),
  });
};

export default MainForm;
