import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionItem } from "src/interfaces";
import { getActionItems } from "src/store/dashboard/selector";
import { hideActionItem } from "src/store/dashboard/actions";
import { getLivePlan } from "src/store/planBuild/selector";
import { getHasPlan, getIsCurrentStudent } from "src/store/system/selector";
import { ACTION_ITEM_IDS } from "src/store/dashboard/actionItems";
import { useCSC } from "../hooks";
import UpdateManualAccountsDialog from "./UpdateManualAccountsDialog";
import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiAccordion,
  EuiButtonIcon,
  EuiImage,
  EuiText,
} from "@elastic/eui";
import {
  StyledEuiButton,
  StyledSpacer,
  StyledEuiButtonEmpty,
} from "src/components/Global/StyledComponents";
import zero_state_archive_items from "src/assets/svgs/action_items_es.svg";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import "src/Cards.css";
import useWindowSize from "src/hooks/useWindowSize";

export const StyledEuiButtonIcon = styled(EuiButtonIcon)`
  background-color: #f1f4fa;
  text-decoration: none;
  border: 1px solid #343741;
  svg {
    width: 24px;
    height: 24px;
  }
`;

const buttonStyles = css`
  .action-btn {
    width: 160px;
  }
  .action_title {
    font-size: 20px;
    line-height: 32px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }
`;

const ActionItems = () => {
  const dispatch = useDispatch();
  const actionItems: ActionItem[] = useSelector(getActionItems);
  const hasPlan = useSelector(getHasPlan);
  const livePlan = useSelector(getLivePlan);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const { recheckItem } = useCSC();
  const { width } = useWindowSize();
  const [accountUpdateDialogVisible, setAccountUpdateDialogVisible] = useState(
    false
  );

  const openAccountUpdateDialog = () => setAccountUpdateDialogVisible(true);

  const actionData = {
    hasPlan,
    isCurrentStudent,
    livePlan,
    openAccountUpdateDialog,
  };

  return (
    <>
      <Global styles={buttonStyles} />
      <EuiFlexGroup direction="column" gutterSize="s" className="card-content">
        <EuiFlexItem>
          <EuiCard
            title="Action Items"
            paddingSize="l"
            description=""
            textAlign="left"
          >
            {actionItems.length === 0 ? (
              <>
                <StyledSpacer size="24px" />
                <div className="no-state-content">
                  <EuiImage
                    className=""
                    src={zero_state_archive_items}
                    alt="checklist"
                    size="original"
                  />
                  <StyledSpacer size="16px" />
                  <EuiText className="zero_state_action">
                    <p>Done for today!</p>
                  </EuiText>
                </div>
              </>
            ) : (
              actionItems.slice(0, 6).map((item) => (
                <React.Fragment key={item.id}>
                  <StyledSpacer size="16px" />
                  <EuiAccordion
                    id={`accordion-${item.id}`}
                    key={item.id}
                    buttonContent={item.title}
                    paddingSize="l"
                    className="accordion-group"
                    extraAction={
                      (width > 767 && width < 992) || width > 1290 ? (
                        <EuiFlexGroup className="extra-action">
                          <EuiFlexItem grow={false}>
                            <StyledEuiButton
                              className="action-btn"
                              title={item.buttonLabel}
                              size="s"
                              color="text"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                if (item.action) {
                                  (item.action as any)(dispatch, actionData);
                                }
                              }}
                            >
                              {item.buttonLabel}
                            </StyledEuiButton>
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <StyledEuiButtonIcon
                              size="s"
                              color="text"
                              iconType="cross"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                dispatch(hideActionItem(item.id));
                              }}
                            />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      ) : null
                    }
                  >
                    <p>{item.message}</p>
                    {(width <= 767 || (width >= 992 && width <= 1290)) && (
                      <>
                        <StyledSpacer size="16px" />
                        <StyledEuiButton
                          className="action-btn"
                          title={item.buttonLabel}
                          color="text"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            if (item.action) {
                              (item.action as any)(dispatch, actionData);
                            }
                          }}
                        >
                          {item.buttonLabel}
                        </StyledEuiButton>
                        <StyledEuiButtonEmpty
                          color="text"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            dispatch(hideActionItem(item.id));
                          }}
                        >
                          Dismiss
                        </StyledEuiButtonEmpty>
                      </>
                    )}
                  </EuiAccordion>
                </React.Fragment>
              ))
            )}
          </EuiCard>
        </EuiFlexItem>
      </EuiFlexGroup>
      <UpdateManualAccountsDialog
        open={accountUpdateDialogVisible}
        onClose={() => {
          setAccountUpdateDialogVisible(false);
          recheckItem(ACTION_ITEM_IDS.ACCOUNT_UPDATE);
        }}
      />
    </>
  );
};

export default ActionItems;
