import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  EuiBasicTable,
  EuiCard,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonIcon,
  EuiBasicTableColumn,
} from "@elastic/eui";
import { Account, ASSET_TYPES } from "src/interfaces";
import { getAssetSummary } from "src/store/account/selector";
import { formatThousands } from "src/utils";
import edit_pencil from "src/assets/svgs/pencil_edit.svg";
import {
  StyledEuiButton,
  StyledSpacer,
} from "src/components/Global/StyledComponents";
import { useFlyout } from "src/components/Global/FlyoutContext";
import AddManualAccountForm from "src/pages/ProfileBuilder/Views/AssetsAndDebts/AddManualAccountForm";
import useWindowSize from "src/hooks/useWindowSize";

interface DetailItem {
  name: string;
  balance: string;
  action: JSX.Element;
}

interface Item {
  id: string;
  title: string;
  total: string;
  details: DetailItem[];
}

const AssetSummary: React.FC = () => {
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const { openCustomFlyout } = useFlyout();
  const summary = useSelector(getAssetSummary);
  const windowSize = useWindowSize();

  const toggleExpandRow = (itemId: string) => {
    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(itemId)) {
        newExpandedRows.delete(itemId);
      } else {
        newExpandedRows.add(itemId);
      }
      return newExpandedRows;
    });
  };

  const headButton = (
    <StyledEuiButton
      className="nw-add-account-button"
      fill
      color="text"
      iconType="plus"
      onClick={() => {
        openCustomFlyout({
          title: "Add manual account", // Reuse the same title
          component: AddManualAccountForm, // Use the same form component
        });
      }}
    >
      Add Account
    </StyledEuiButton>
  );

  const sections = [
    { id: "cash", label: "Cash/Checking/Savings" },
    { id: "otherInvestments", label: "Taxable Investments" },
    { id: "retirement", label: "Retirement" },
    { id: "realEstate", label: "Real Estate" },
    { id: "auto", label: "Auto" },
    { id: "otherAssets", label: "Other Assets" },
  ];

  const items: Item[] = sections
    .map((section) => {
      const details = (summary[`${section.id}Detail`] || [])
        .filter((account: Account) => account.balance && account.balance > 0)
        .map((account: Account) => ({
          name:
            account.name ||
            ASSET_TYPES[account.variable as keyof typeof ASSET_TYPES],
          balance: formatThousands(account.balance || 0),
          action: (
            <EuiButtonIcon
              aria-label="Edit account"
              iconType={edit_pencil}
              onClick={() => {
                openCustomFlyout({
                  title: "Edit account",
                  component: AddManualAccountForm,
                  selected: account,
                });
              }}
            />
          ),
        }));

      return {
        id: section.id,
        title: section.label,
        total: formatThousands(summary[`${section.id}Total`]),
        details,
      };
    })
    .filter((item) => item.total !== "0" && item.details.length > 0);

  const columns: Array<EuiBasicTableColumn<Item>> = [
    {
      width: windowSize.width <= 768 ? "10%" : "40px",
      isExpander: true,
      render: (item: Item) =>
        item.details.length > 0 ? (
          <EuiButtonIcon
            onClick={() => toggleExpandRow(item.id)}
            aria-label={expandedRows.has(item.id) ? "Collapse" : "Expand"}
            iconType={expandedRows.has(item.id) ? "arrowUp" : "arrowDown"}
          />
        ) : null,
    },
    {
      field: "title",
      name: "Type",
      width: windowSize.width <= 768 ? "60%" : "80%",
      render: (title: string) => <EuiText>{title}</EuiText>,
    },
    {
      field: "total",
      name: "Balance",
      width: windowSize.width <= 768 ? "30%" : "20%",
      render: (total: string) => <EuiText>{total}</EuiText>,
    },
  ];

  const renderExpandedRow = (item: Item) => (
    <EuiFlexGroup
      direction="column"
      className="expanded-row"
      style={{ padding: "0", gap: "0" }}
    >
      {item.details.map((detail, index) => (
        <EuiFlexGroup
          key={index}
          justifyContent="spaceBetween"
          alignItems="center"
          style={{
            padding: "8px 40px",
            paddingRight: "65px",
            borderBottom:
              index !== item.details.length - 1 ? "1px solid #c5c5c5" : "none",
          }}
        >
          <EuiFlexItem grow={false}>
            <EuiText>
              {detail.name} {detail.action}
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText>{detail.balance}</EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      ))}
    </EuiFlexGroup>
  );

  const totalRow = {
    id: "total",
    title: "Total",
    total: formatThousands(summary.grandTotal),
    details: [],
  };

  return (
    <>
      <EuiCard title>
        <EuiFlexGroup
          justifyContent="spaceBetween"
          alignItems="center"
          className="flex-nowrap"
        >
          <EuiFlexItem grow={false} className="hc-desc-text">
            <h1>Asset Summary</h1>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{headButton}</EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="16px" />
        <EuiBasicTable
          responsive={false}
          className="table-border"
          items={[...items, totalRow]}
          columns={columns}
          itemId="id"
          rowProps={(item) => ({
            isExpanded: expandedRows.has(item.id),
            "data-test-subj": `row-${item.id}`,
            className: item.id === "total" ? "total-row" : "",
          })}
          itemIdToExpandedRowMap={Object.fromEntries(
            Array.from(expandedRows, (id) => {
              const foundItem = items.find((item) => item.id === id);
              return foundItem ? [id, renderExpandedRow(foundItem)] : [];
            }).filter((entry) => entry.length > 0) // Filter out empty entries
          )}
          isExpandable
          hasActions
        />
      </EuiCard>
    </>
  );
};

export default AssetSummary;
